import { Device } from "framework7";
import { f7ready, f7 } from "framework7-svelte";
import { isCardsPathUrl, Route } from "./routes";

const IS_URI = new RegExp('^(mailto|tel):', 'i');
const IS_ABS_URL = new RegExp('^(?:[a-z]+:)?//', 'i');

// This doesn't work due to circular dependencies.
//const signonManager = getToken(ROOT, JanusSignonManagerToken);
//const i18nManager = getToken(ROOT, JanusInternationalizationManagerToken);

export function isAbsoluteUrl(url: string): boolean {
  //console.log('IS ABS URL', url, IS_ABS_URL.test(url));
  return IS_ABS_URL.test(url) || IS_URI.test(url);
}

export function getBaseDomain(url: string) {
  const urlObj = new URL(url);
  const domainParts = urlObj.hostname.split('.');
  if (domainParts.length < 2) {
    return '';
  }
  const baseDomain = domainParts.slice(-2).join('.');
  return baseDomain;
}

export function isSameBaseDomain(url1: string, url2?: string) {
  if (!url2) {
    url2 = window.location.href;
  }
  return getBaseDomain(url1).toLowerCase() === getBaseDomain(url2).toLowerCase();
}

export function shouldOpenInNewWindow(url: string): boolean {
  // links that aren't abosolute should open in the same window
  if (!isAbsoluteUrl(url)) {
    return false;
  }
  // links that point to the same base domain should open in the same window
  if (CONFIG.openSameDomainInSameWindow && isSameBaseDomain(url)) {
    return false;
  }
  const sameWindowDomainWhitelist = Object.values(CONFIG.sameWindowDomainWhitelist);
  const baseDomainLower = getBaseDomain(url).toLowerCase();
  for (let i = 0; i < sameWindowDomainWhitelist.length; i++) {
    if (baseDomainLower == sameWindowDomainWhitelist[i].toLowerCase()) {
      return false;
    }
  }
  return true;
}

export interface ResolvedUrl {
  href: string;
  external: boolean;
  func: () => void;
  error?: boolean;
}

export interface ResolveUrlOptions {
  modal?: boolean; // open in modal
  language?: string; // change language
}

export function resolveUrl(url: string, currentRoute?: Route, options?: ResolveUrlOptions): ResolvedUrl {
  const err: ResolvedUrl = { href: '#', external: false, func: () => {}, error: true };
  if (!url) {
    //console.log('resolveUrl', 'missing URL', url);
    return err;
  }

  if (IS_URI.test(url)) {
    return { href: url, external: true, func: () => window.open(url, "_blank") };
  }

  // convert relative paths to absolute paths based on the current path
  // TODO: If we introduce a yc://pages/{slug} URL format in the future, we may want to rewrite this
  // to generate one of those URLs instead, and have it resolved below.
  const relToAbsPath = (origUrl: string) => {
    //console.log('relToAbsPath', origUrl);
    let retUrl = origUrl;
    if (!isAbsoluteUrl(origUrl)) {
      // if the page has a slug on the end, remove the slug before resolving the relative URL
      let currentUrl: URL = new URL(window.location.href);
      if (!currentRoute) {
        currentRoute = f7?.view?.main?.router?.currentRoute;
      }
      //console.log('CURRENT ROUTE', currentRoute);
      function cardsPathResolve(path) {
        const toks = path.split('/').filter((part) => part);
        const pageOrModal = (options?.modal)? 'modals' : 'pages';
        const lang = options?.language || yc.lang || toks?.[1] || CONFIG.defaultLanguage;
        const restOfPath = (origUrl === '.')? ((toks.length > 2)? toks.slice(2).join('/') : '') : origUrl;
        //console.log('CARDS PATH RESOLVE', origUrl, restOfPath);
        let newPath = `/${pageOrModal}/${lang}/${restOfPath}`;
        if (origUrl === '.') {
          newPath += currentUrl.search;
          newPath += currentUrl.hash;
        }
        return newPath;
      }
      if (origUrl.startsWith('/')) {
        currentUrl = new URL(origUrl, window.location.origin);
        //console.log('CURRENT URL', currentUrl);
      } else if (currentRoute?.path) {
        let path = currentRoute.path;
        // maybe a reason to introduce yc://pages/{slug}?
        if (currentRoute?.path === '/builder') {
          path = `/pages/${CONFIG.defaultLanguage}/__builder__`;
        } else if (isCardsPathUrl(path)) {
          path = cardsPathResolve(path);
        }
        /*
        if (currentRoute?.name?.endsWith('-with-slug')) {
          path = path.substring(0, path.lastIndexOf('/'));
        }
        if (currentRoute?.name?.startsWith('modal')) {
          path = path.replace('/modals/', '/pages/');
        }
        */
        //console.log('PATH', path);
        currentUrl = new URL(path, window.location.origin);
        //console.log('CURRENT URL', currentUrl);
      } else {
        currentUrl = new URL(cardsPathResolve(''), window.location.origin);
      }
      /*
      const dummyUrl = new URL(retUrl, currentUrl);
      console.log('DUMMY URL', dummyUrl);
      */
      const dummyUrl = currentUrl;
      retUrl = dummyUrl.pathname + dummyUrl.search + dummyUrl.hash;
      //console.log('RET URL', retUrl);
      //console.log('relToAbsPath', origUrl, currentRoute, currentUrl, retUrl);
    }
    return retUrl;
  }
  url = relToAbsPath(url);

  // convert absolute path URLs into yc://router/ URLs
  // note that this doesn't modify URLs that specify scheme
  let obj: URL = null;
  try {
    obj = new URL(url, 'yc://router/');
  } catch (err) {
    console.log('resolveUrl', 'error parsing URL', url, err);
    return err;
  }

  if (!obj?.protocol) {
    console.log('resolveUrl', 'missing protocol after parsing URL', url, obj);
    return err;
  }
  if (obj.protocol.startsWith('yc')) { // handles yc:// and yc-league-team://
    if (!obj?.pathname) {
      console.log('resolveUrl', 'invalid pathname', obj?.pathname);
      return err;
    }
    let toks = obj.pathname.split('/').filter(t => t); // removes empty tokens
    if (!toks) {
      console.log('resolveUrl', 'unable to split pathname', obj.pathname);
      return err;
    }
    // iOS (possibly all Apple browsers too?) consider the first component of the path as the hostname for yc urls
    if (obj.hostname) {
      toks.unshift(obj.hostname);
    }
    if (!toks[0]) {
      console.log('resolveUrl', 'null first component of ycURL path', obj.pathname);
      return err;
    }
    switch (toks[0].toLowerCase()) {
      case 'router':
        let path = toks.slice(1).join('/');
        let href = `/${path}${obj.search}${obj.hash}`;
        return { href, external: false, func: () => f7ready((f7) => f7.views.main.router.navigate(href)) };
      case 'feature':
        if (!toks[1]) {
          console.log('resolveUrl', 'null second component of feature ycURL path', obj.pathname);
          return err;
        }
        let featureId = toks[1].toUpperCase();
        switch (featureId) {
          case 'GT_BOX':
          case 'GT_PRE':
            let gameId = obj.searchParams.get('id');
            let slug = obj.searchParams.get('slug');
            if (!gameId && !slug) {
              console.log('resolveUrl', 'feature/GT_BOX ycURL missing id or slug param', url);
              return err;
            }
            let isPregame = obj.searchParams.get('isPregame') === 'true' || featureId === 'GT_PRE';
            let suffix = ''
            if (slug) {
              suffix = `/${encodeURIComponent(slug)}?isPregame=${isPregame}`
            } else {
              suffix = `?gameId=${encodeURIComponent(gameId)}&isPregame=${isPregame}`
            }
            let href = relToAbsPath(`MatchCenter${suffix}`);
            return { href, external: false, func: () => f7ready((f7) => f7.views.main.router.navigate(href))};
          case 'WEBSITE':
            let webUrl = obj.searchParams.get('url');
            if (!webUrl) {
              console.log('resolveUrl', 'feature/WEBSITE ycURL missing url param', webUrl);
              return err;
            }
            // in a mobile app, open in a webview, but in a browser open normally
            if (Device.cordova) {
              let title = obj.searchParams.get('title');
              if (!title) {
                console.log('resolveUrl', 'WARNING: feature/WEBSITE ycURL missing title param', url);
              }
              let href = `/webview?url=${encodeURIComponent(webUrl)}`;
              if (title) {
                href = `${href}&title=${encodeURIComponent(title)}`;
              }
              return { href, external: false, func: () => f7ready((f7) => f7.views.main.router.navigate(href)) };
            } else {
              return { href: webUrl, external: true, func: () => window.open(webUrl, "_blank") };
            }
          case 'INTERNAL_BROWSER':
            let ibUrl = obj.searchParams.get('url');
            if (!ibUrl) {
              console.log('resolveUrl', 'feature/INTERNAL_BROWSER ycURL missing url param', ibUrl);
              return err;
            }
            if (Device.cordova) {
              return {
                href: `javascript:cordova.InAppBrowser.open('${ibUrl}', '_blank', 'location=no,mediaPlaybackRequiresUserAction=no,allowInlineMediaPlayback=yes')`,
                external: false,
                func: () => cordova.InAppBrowser.open(ibUrl, '_blank', 'location=no,mediaPlaybackRequiresUserAction=no,allowInlineMediaPlayback=yes')
              };
            } else {
              return { href: webUrl, external: true, func: () => window.open(ibUrl, "_blank") };
            }
          default:
            console.log('resolveUrl', 'unrecognized second component of feature ycURL path', obj.pathname);
            return err;
        }
        break;
      case 'action':
        if (!toks[1]) {
          console.log('resolveUrl', 'null second component of action ycURL path', obj.pathname);
          return err;
        }
        switch (toks[1].toUpperCase()) {
          case 'NOOP':
            return err;
          case 'SSO_GATE':
            const gateId = obj.searchParams.get('gateId');
            return err;
            //return { href, external: false, func: () => signonManager.getSdk().showCustomGate(gateId)};
          case 'OPEN_PAGE':
            let pageSlug = obj.searchParams.get('pageSlug');
            const intent = obj.searchParams.get('intent');
            const contentSlug = obj.searchParams.get('contentSlug');
            let slugParam = null;
            if (!pageSlug && intent) {
              // TODO: implement a dynamic lookup
              switch (intent) {
                case 'GAME_BETTING':
                  // not implemented
                  pageSlug = 'home';
                  break;
                case 'GAME_CENTER':
                  pageSlug = 'match-centre';
                  slugParam = 'gameId';
                  break;
                case 'GAME_HIGHLIGHTS':
                  pageSlug = 'match-centre';
                  slugParam = 'gameId';
                  break;
                case 'PLAYER_DETAIL': {
                  //pageSlug = 'player-detail-statistics';
                  const positionCode = obj.searchParams.get('positionCode');
                  switch (positionCode) {
                    case "GK":
                      pageSlug = 'player-detail-statistics-goalkeeper';
                      break;
                    case "D":
                      pageSlug = 'player-detail-statistics-defender';
                      break;
                    case "M":
                      pageSlug = 'player-detail-statistics-midfielder';
                      break;
                    case "F":
                      pageSlug = 'player-detail-statistics-striker';
                      break;
                  }
                  break;
                }
                case 'SEARCH':
                  pageSlug = 'search-results';
                  slugParam = 'query';
                  break;
              }
            }
            if (pageSlug) {
              let href = relToAbsPath(pageSlug);
              if (contentSlug) {
                if (slugParam) {
                  href += `?${encodeURIComponent(slugParam)}=${encodeURIComponent(contentSlug)}`;
                } else {
                  href += `/${encodeURIComponent(contentSlug)}`;
                }
              }
              return { href, external: false, func: () => f7ready((f7) => f7.views.main.router.navigate(href))};
            } else {
              return err;
            }
          case 'EXTERNAL_URL':
            let extUrl = obj.searchParams.get('url');
            if (!extUrl) {
              console.log('resolveUrl', 'action/EXTERNAL_URL ycURL missing url param', extUrl);
              return err;
            }
            if (Device.cordova) {
              return {
                href: `javascript:cordova.InAppBrowser.open('${extUrl}', '_system', 'location=no,hidden=yes')`,
                external: false,
                func: () => cordova.InAppBrowser.open(extUrl, '_system', 'location=no,hidden=yes')
              };
            } else {
              return { href: extUrl, external: true, func: () => window.open(extUrl, "_blank") };
            }
          default:
            console.log('resolveUrl', 'unrecognized second component of action ycURL path', obj.href);
            return err;
        }
        break;
    }
    console.log('resolveUrl', 'ycURL processing did not resolve URL', url);
    return err;
  } else if (obj.protocol.startsWith('http') && Device.cordova) {
    // open http links externally if inside a mobile app
    return {
      href: `javascript:cordova.InAppBrowser.open('${url}', '_blank', 'location=no,mediaPlaybackRequiresUserAction=no,allowInlineMediaPlayback=yes')`,
      external: false,
      func: () => cordova.InAppBrowser.open(url, '_blank', 'location=no,mediaPlaybackRequiresUserAction=no,allowInlineMediaPlayback=yes')
    };
  }
  // external http, https, other
  return { href: url, external: true, func: () => window.open(url, "_blank") };
}
