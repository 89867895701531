import { generateRootObjectSchema, generateArraySchema, generateObjectSchema, generateColorSchema, generateStringSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ButtonParticleSpec, ImageParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("MC-Shooting", generateRootObjectSchema({
    container: generateAtomContainerSchema(),
    overlayPadding: generateStringSchema("Overlay padding", "Specify overlay padding"),
    txtHeading: TextParticleSpec.generateSchema("Heading Text", "Heading text", {
        defaultValues: { size: "2rem", weight: "900", transform: "uppercase" }
    }),
    matchStats: generateArraySchema("Shot Stats", "Select shot stats to compare Home vs Away",
        generateObjectSchema("Shot Stat", "Shot Stat", {
            statName: generateStringSchema("Stat Name", "Stat Name"),
            homeValue: generateStringSchema("Home Value", "Home Value"),
            awayValue: generateStringSchema("Away Value", "Away Value"),
            maxValue: generateStringSchema('Max Value', "Max Value")
        })),
    txtHomeOnTargetShots: TextParticleSpec.generateSchema("Home On Target Shots", "Enter home on-target shots", {
        defaultValues: { size: "6.25rem", weight: "700" }
    }),
    txtAwayOnTargetShots: TextParticleSpec.generateSchema("Away On Target Shots", "Enter away on-target shots", {
        defaultValues: { size: "6.25rem", weight: "700" }
    }),
    txtHomeOffTargetShots: TextParticleSpec.generateSchema("Home Off Target Shots", "Enter home off-target shots", {
        defaultValues: { size: "6.25rem", weight: "700" }
    }),
    txtAwayOffTargetShots: TextParticleSpec.generateSchema("Away Off Target Shots", "Enter home off-target shots", {
        defaultValues: { size: "6.25rem", weight: "700" }
    }),
    txtHomeOnTargetPercent: TextParticleSpec.generateSchema("Home Team On Target Percent", "Home Team On Target Percent", {
        defaultValues: {}, uneditableFields: { text: true }
    }),
    txtHomeOffTargetPercent: TextParticleSpec.generateSchema("Home Team Off Target Percent", "Home Team Off Target Percent", {
        defaultValues: {}, uneditableFields: { text: true }
    }),
    txtAwayOnTargetPercent: TextParticleSpec.generateSchema("Away Team On Target Percent", "Away Team On Target Percent", {
        defaultValues: {}, uneditableFields: { text: true }
    }),
    txtAwayOffTargetPercent: TextParticleSpec.generateSchema("Away Team Off Target Percent", "Away Team Off Target Percent", {
        defaultValues: {}, uneditableFields: { text: true }
    }),
    txtOnTargetHeading: TextParticleSpec.generateSchema("On Target", "On Target", {
        uneditableFields: { text: true }
    }),
    txtOffTargetHeading: TextParticleSpec.generateSchema("Off Target", "Off Target", {
        uneditableFields: { text: true }
    }),
    txtHomeTotalShots: TextParticleSpec.generateSchema("Home Shots Total", "Total shots by Home Team", {
        defaultValues: { size: "2.25rem", weight: "700" },
        uneditableFields: { text: true }
    }),
    txtAwayTotalShots: TextParticleSpec.generateSchema("Away Shots Total", "Total shots by Away Team", {
        defaultValues: { size: "2.25rem", weight: "700" },
        uneditableFields: { text: true }
    }),
    txtPercentBottom: TextParticleSpec.generateSchema("Shots at Bottom Row", "Specify properties for shots at bottom row", {
        defaultValues: { size: "1.5rem", weight: "700" }
    }),
    imgGoalPost: ImageParticleSpec.generateSchema("Background Image", "Upload a background image"),
    imgHomeLogo: ImageParticleSpec.generateSchema("Home Team Logo", "Upload home team logo", {
        defaultValues: { container: { width: "6rem", height: "6rem" }, sizing: "cover" }
    }),
    imgAwayLogo: ImageParticleSpec.generateSchema("Away Team Logo", "Upload away team logo", {
        defaultValues: { container: { width: "6rem", height: "6rem" }, sizing: "cover" }
    }),
    imgFieldImage: ImageParticleSpec.generateSchema("Field Image", "Field Image", {
        defaultValues: { sizing: "cover" }
    }),
    myTeamColor: generateColorSchema("My Team Color", "Pick a color for home team"),
    opponentTeamColor: generateColorSchema("Opponent Team Color", "Pick a color for away team"),
    myTeamFontColor: generateColorSchema("My Team Font Color", "Pick a color for home team", { defaultValue: "#FFF" }),
    opponentTeamFontColor: generateColorSchema("Opponent Team Font Color", "Pick a color for away team", { defaultValue: "#000" }),
    homeTricode: generateStringSchema("Home Tricode", "Home Tricode"),
    awayTricode: generateStringSchema("Home Tricode", "Home Tricode"),
}));