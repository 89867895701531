export let WinBox = null;
export let Jodit = null;
export let JSONEditor = null;

let depsLoadPromise = null;

export async function loadCardsEditorDependencies() {
  if (!depsLoadPromise) {
    depsLoadPromise = Promise.all([
      import('/' + 'static/js/winbox.bundle.min.js'),
      import('/' + 'static/js/jodit.min.js'),
      import('/' + 'static/js/jsoneditor.js'),
    ]).then(([winbox, jodit, jsoneditor]) => {
      try {
        WinBox = window.WinBox;
        Jodit = window.Jodit;
        JSONEditor = window.JSONEditor;
        return { WinBox, Jodit, JSONEditor };
      } catch (e) {
        console.log('loadCardsEditorDependencies error!', e);
      }
    });
  }
  return depsLoadPromise;
}

