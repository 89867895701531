import { generateArraySchema, generateBooleanSchema, generateColorSchema, generateDateTimeSchema, generateDurationSchema, generateNumberSchema, generateObjectSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ButtonParticleSpec, ImageParticleSpec, PageParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Navigation Menu (Style C)", generateRootObjectSchema({
  container: generateAtomContainerSchema({
    defaultValues: { themeMode: 'inverted', background: { visible: true } }
  }),
  flyoutMenuPage: PageParticleSpec.generateSchema("More Menu Page", "The page to embed in the more menu (optional)."),
  flyoutMenuBackgroundColor: generateColorSchema("Flyout Menu Background Color", "The flyout menu background color"),
  menuTitleShowChevron: generateBooleanSchema("Show Chevron", "For menu titles with submenu items, show a chevron to the right of the title."),
  menuTitleShowActiveBackground: generateBooleanSchema("Show Active Background on Hover", "For menu titles, show the active background color on hover."),
  menuTitleActiveBackgroundColor: generateColorSchema("Background Color on Hover", "The hover background color for menu titles."),
  menuTitleShowActiveUnderline: generateBooleanSchema("Show Underline on Hover", "For menu titles, show an underline on hover."),
  menuTitleActiveUnderlineColor: generateColorSchema("Underline Color on Hover", "The hover underline color for menu titles."),
  menuTitleFontFamily: generateStringSchema("Menu Title Font Family", "The font family to use for menu titles."),
  logoButton: ButtonParticleSpec.generateSchema("Logo Button", "The configuration for the logo button on the left side, spanning both rows.", {
    defaultValues: {
      showText: false,
      showImage: true,
      link: 'home',
      image: { sizing: 'contain', container: { height: '5rem', fillWidth: false }, fillWidth: false }
    }
  }),
  promptText: TextParticleSpec.generateSchema("Prompt Text", "The main prompt text.", {
    defaultValues: { size: '1.25rem', weight: '400', wrap: 'nowrap', text: "What are you looking for?" }
  }),
  browseDropdownLabel: TextParticleSpec.generateSchema("Browse Dropdown Label", "The browse dropdown label text.", {
    defaultValues: { size: '1.25rem', weight: '800', wrap: 'nowrap', text: "Browse", useAccentColor: true }
  }),
  browseButtons: generateArraySchema("Browse Buttons", "The browse buttons.",
    ButtonParticleSpec.generateSchema("Browse Button", "A browse button.", {
      defaultValues: {
        showImage: false,
        showText: true,
        text: { size: '1.5rem', weight: '600', wrap: 'nowrap', useAccentColor: false },
        container: { padding: "0.25rem" }
      },
    })
  ),
  linkButtons: generateArraySchema("Link Buttons", "The link buttons.",
    ButtonParticleSpec.generateSchema("Link Button", "A link button.", {
      defaultValues: {
        showImage: false,
        showText: true,
        text: { size: '1.5rem', weight: '600', wrap: 'nowrap', useAccentColor: false },
        container: { padding: "0.25rem" }
      },
    })
  ),
  languageFlagTemplate: ImageParticleSpec.generateSchema("Selected Language Flag", "Styling for the selected language flag image.", {
    defaultValues: { sizing: 'contain', container: { width: '3.5rem', height: '3.5rem' }, fillHeight: false, fillWidth: false },
    uneditableFields: { source: true, alt: true, icon: true },
  }),
  languageLabelTemplate: TextParticleSpec.generateSchema("Selected Language Label", "Styling for the selected language label.", {
    defaultValues: { size: '1.25rem', weight: '800', wrap: 'nowrap', text: "Browse", useAccentColor: true },
    uneditableFields: { text: true }
  }),
  ddlLanguageFlagTemplate: ImageParticleSpec.generateSchema("Language Menu Flags", "Styling for the language flag images within the language menu.", {
    defaultValues: { sizing: 'contain', container: { width: '3.5rem' }, fillHeight: false, fillWidth: false },
    uneditableFields: { source: true, alt: true, icon: true },
  }),
  ddlLanguageLabelTemplate: TextParticleSpec.generateSchema("Language Menu Labels", "Styling for the language label texts within the language dropdown.", {
    defaultValues: { size: '1.5rem', weight: '800', wrap: 'nowrap', text: "Browse", useAccentColor: true, container: { centerContentHorizontally: false, padding: "0 0 0 0.25rem" } },
    uneditableFields: { text: true }
  }),
  signInButton: ButtonParticleSpec.generateSchema("Sign-In Button", "The styling for the sign-in button.", {
    defaultValues: {
      image: { container: { height: '2.5rem', width: '2.5rem' }, source: "https://resources-uk.yinzcam.com/soccer/shared/icon_profile.svg", iconColor: "#FFF", role: "none" },
      text: { size: '1.25rem', weight: '600', wrap: 'nowrap' },
      imagePosition: 'after',
      gap: '0.25rem',
      showImage: true,
      showText: true
    }
  }),
  sponsorButtons: generateArraySchema("Sponsor Buttons", "The top-row sponsor buttons.",
    ButtonParticleSpec.generateSchema("Sponsor Button", "The configuration for the sponsor button on the right side of the top row.", {
      defaultValues: {
        container: { padding: '1rem 0' },
        image: { sizing: 'contain', container: { height: '1.75rem', fillWidth: false }, fillWidth: false },
        showText: false,
        showImage: true,
        gap: '0.5rem'
      },
    })
  ),
  items: generateArraySchema("Items", "The array of top-level navigation-menu items.",
    generateObjectSchema("Item", "A top-level navigation-menu item.", {}, { additionalProperties: true })
  ),
  mobileMenuHeaderLayout: generateBooleanSchema("Mobile Menu Header Layout", "", { defaultValue: false }),
  backButton: ButtonParticleSpec.generateSchema("Back Button", "The configuration for the back button in mobile menu mode.", {
    defaultValues: {
      container: { padding: '1rem 0' },
      image: { sizing: 'contain', container: { height: '1.75rem', fillWidth: false }, fillWidth: false, icon: 'yc_icon_chevron_left' },
      showText: false,
      showImage: true,
      gap: '0.5rem'
    },
  }),
}));
