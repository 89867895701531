import { generateRootObjectSchema, generateArraySchema, generateObjectSchema, generateStringSchema, generateBooleanSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ImageParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

const propsPlayerName = {
    size: "1.25rem",
    weight: "400",
}

const propsGoalMinute = {
    size: "1.25rem",
    weight: "400",
}

const propsTeamIcon = {
    container: {
        width: "12rem",
        height: "12rem",
    }
}

const propsTeamName = {
    size: "1.5rem", weight: "700", lineHeight: "1",
}

const propsLiveMinute = { size: "1.25rem", weight: "700" }

const propsScore = { size: "5rem", weight: "700" };

const propsVenueName = { size: "1.5rem", weight: "400" };

export const specification = new BaseAtomSpec("MC-HeaderAtom", generateRootObjectSchema({
    container: generateAtomContainerSchema(),
    backgroundImage: ImageParticleSpec.generateSchema("Background Image", "Background Image"),
    overlayPadding: generateStringSchema("Overlay Padding", "Specify a padding for overlay"),
    matchDateTime: generateStringSchema("Match Date Time", "Enter match date and time is ISO format"),
    txtMatchDate: TextParticleSpec.generateSchema("Match Date", "The styling for the match date.", {
        defaultValues: { size: "1.25rem", weight: "400", wrap: "nowrap" },
        uneditableFields: { text: true }
    }),
    txtMatchTime: TextParticleSpec.generateSchema("Match Time", "The styling for the match Time.", {
        defaultValues: { size: "4rem", weight: "700", wrap: "nowrap", transform: "uppercase" },
        uneditableFields: { text: true }
    }),
    matchStatus: generateStringSchema("Match Status", "Select a match status", {
        choices: ['pre', 'post', 'live'],
        choiceTitles: ['Pre', 'Post', 'Live'],
        defaultValue: 'pre',
    }),
    txtFullTime: TextParticleSpec.generateSchema("Full Time", "Full time text", {
        defaultValues: { size: "1.25rem", weight: "700", transform: "uppercase" }
    }),
    homePlayerGoals: generateArraySchema("Home Players", "Players of Home Team",
        generateObjectSchema("Player", "Player details", {
            name: TextParticleSpec.generateSchema("Player Name", "Player Name", {
                defaultValues: propsPlayerName
            }),
            goalMinute: TextParticleSpec.generateSchema("Goal Minute", "Goal Minute", {
                defaultValues: propsGoalMinute
            }),
            icon: ImageParticleSpec.generateSchema("Image", "Image", {
                defaultValues: {
                    container: { width: "1rem", height: "1rem" },
                    source: "https://resources-uk.yinzcam.com/soccer/shared/soccer_ball.png", sizing: "contain"
                }
            })
        })
    ),
    awayPlayerGoals: generateArraySchema("Away Players", "Players of Home Team",
        generateObjectSchema("Player", "Player details", {
            name: TextParticleSpec.generateSchema("Player Name", "Player Name", {
                defaultValues: propsPlayerName
            }),
            goalMinute: TextParticleSpec.generateSchema("Goal Minute", "Goal Minute", {
                defaultValues: propsGoalMinute
            }),
            icon: ImageParticleSpec.generateSchema("Image", "Image", {
                defaultValues: {
                    container: { width: "1rem", height: "1rem" },
                    source: "https://resources-uk.yinzcam.com/soccer/shared/soccer_ball.png", sizing: "contain"
                }
            })
        })
    ),
    homeTeamDetails: generateObjectSchema("Home Team", "Home team icon and name", {
        icon: ImageParticleSpec.generateSchema("Team Icon", "Team Icon", {
            defaultValues: propsTeamIcon
        }),
        name: TextParticleSpec.generateSchema("Team Name", "Team Name", {
            defaultValues: propsTeamName
        })
    }),
    awayTeamDetails: generateObjectSchema("Away Team", "Home team icon and name", {
        icon: ImageParticleSpec.generateSchema("Team Icon", "Team Icon", {
            defaultValues: propsTeamIcon
        }),
        name: TextParticleSpec.generateSchema("Team Name", "Team Name", {
            defaultValues: propsTeamName
        })
    }),
    imgLeagueIcon: ImageParticleSpec.generateSchema("League Icon", "Upload Image Icon", {
        defaultValues: { container: { height: "4rem" }, sizing: "cover" }
    }),
    imgLiveIcon: ImageParticleSpec.generateSchema("Live Icon", "Live Icon", {
        defaultValues: { container: { width: "2.25rem", height: "2.25rem" } }
    }),
    txtLive: TextParticleSpec.generateSchema("LIVE", "LIVE", {
        defaultValues: { ...propsLiveMinute, text: "LIVE" }
    }),
    txtMinute: TextParticleSpec.generateSchema("Minutes", "Minutes", { defaultValues: propsLiveMinute }),
    txtHomeScore: TextParticleSpec.generateSchema("Home Score", "Home Score", {
        defaultValues: propsScore
    }),
    txtAwayScore: TextParticleSpec.generateSchema("Away Score", "Away Score", {
        defaultValues: propsScore
    }),
    txtVenueName: TextParticleSpec.generateSchema("Venue Name", "Venue Name", {
        defaultValues: propsVenueName
    }),
    isMatchTimeBoxRequired: generateBooleanSchema("Is Match Time Box Required?", "Is Match Time Box Required?", {
        defaultValue: true
    }
    ),
    setPageMetadata: generateBooleanSchema("Set Page Metadata", "Allow this card to set the metadata on the page, such as the page title.", {
        defaultValue: false
    }),
}));