import * as YinzCam__Stats__Standings from "./Standings/StandingsAtom.svelte";
import * as YinzCam__Stats__LeagueTableAtom from './LeagueTableTwo/LeagueTableTwoAtom.svelte';
import * as YinzCam__Stats__LeagueTableTwoAtom from './LeagueTableTwo/LeagueTableTwoAtom.svelte';
import * as YinzCam__Stats__FixturesStackedAtom from './FixturesCardStacked/FixturesStackedAtom.svelte';
import * as YinzCam__Stats__MCHeaderB from './MCHeaderB/MCHeaderBAtom.svelte';
import * as YinzCam__Stats__FixturesCardBAtom from './FixturesCardB/FixturesCardBAtom.svelte';
import * as YinzCam__Stats__FixturesCardCAtom from './FixturesCardC/FixturesCardCAtom.svelte';
import * as YinzCam__Stats__SwiperFixturesCardCAtom from './SwiperFixturesCardC/SwiperFixturesCardCAtom.svelte';

export const library = {
  YinzCam__Stats__Standings,
  YinzCam__Stats__LeagueTableAtom,
  YinzCam__Stats__LeagueTableTwoAtom,
  YinzCam__Stats__FixturesStackedAtom,
  YinzCam__Stats__MCHeaderB,
  YinzCam__Stats__FixturesCardBAtom,
  YinzCam__Stats__FixturesCardCAtom,
  YinzCam__Stats__SwiperFixturesCardCAtom
};
