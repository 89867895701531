import { generateBooleanSchema, generateColorSchema, generateDateTimeSchema, generateRootObjectSchema, generateStringSchema, generateArraySchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { TextParticleSpec, ImageParticleSpec, ButtonParticleSpec, TextParticle } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Fixtures Card-C", generateRootObjectSchema({
    container: generateAtomContainerSchema({
        defaultValues: { background: { visible: true } },
    }),
    overlayPadding: generateStringSchema("Overlay Padding", "Specify a padding for overlay content"),
    backgroundColor: generateColorSchema("Center Box Background Color", "BG for live matches"),
    imgLeaguelogo: ImageParticleSpec.generateSchema("Competition Logo", "Upload/specify URL for competition logo", {
        defaultValues: { container: { width: "5rem", height: "5rem" }, sizing: "cover" }
    }),
    matchDateTime: generateDateTimeSchema("Match Date Time", "The date and time of the match."),
    txtMatchDate: TextParticleSpec.generateSchema("Match Date", "The styling for the match date.", {
        defaultValues: { size: "1.5rem", weight: "400", wrap: "nowrap" },
        uneditableFields: { text: true }
    }),
    txtMatchTime: TextParticleSpec.generateSchema("Match Time", "The styling for the match Time.", {
        defaultValues: { size: "3rem", weight: "800", transform: "uppercase" },
        uneditableFields: { text: true }
    }),
    txtStadiumName: TextParticleSpec.generateSchema("Venue Name", "Enter venue name", {
        defaultValues: { size: '1rem', weight: '400', transform: 'uppercase', color: "#7C7C7C" }
    }),
    imgHomeTeam: ImageParticleSpec.generateSchema("Home Team logo", "Upload/specify home team logo", {
        defaultValues: { container: { height: "7rem", width: "7rem" }, sizing: 'contain', source: 'https://resources-uk.yinzcam.com/soccer/shared/logos/_default.svg' }
    }),
    imgAwayTeam: ImageParticleSpec.generateSchema("Away Team logo", "Upload/specify away team logo", {
        defaultValues: { container: { height: "7rem", width: "7rem" }, sizing: 'contain', source: 'https://resources-uk.yinzcam.com/soccer/shared/logos/_default.svg' }
    }),
    btnActions: generateArraySchema("CTA Buttons", "Populate action buttons",
        ButtonParticleSpec.generateSchema("CTA Button", "Details of CTA", {
            defaultValues: {
                container: {
                    padding: "0.5rem",
                    border: {
                        visible: true,
                        radius: "3.75rem",
                    },
                    background: {
                        visible: true,
                    }, fillHeight: false, fillWidth: false,
                },
                text: {
                    size: "1rem", weight: "800", align: "left", transform: 'uppercase'
                },
                image: {
                    container: { height: "2.25rem", width: "2.25rem" },
                },
            }
        })
    ),
    matchStatus: generateStringSchema("Match Status", "Select match status", {
        choices: ['pre', 'live', 'post'],
        choiceTitles: ["Pre", "Live", "Post"],
        defaultValue: 'pre'
    }),
    homeTeamScore: TextParticleSpec.generateSchema("Home Score", "Enter home team score", {
        defaultValues: { size: "2rem", weight: "800" }
    }),
    awayTeamScore: TextParticleSpec.generateSchema("Away Score", "Enter home team score", {
        defaultValues: { size: "2rem", weight: "800" }
    }),
    matchMinutes: TextParticleSpec.generateSchema("Match minutes - Live", "Enter the minutes of live match", {
        defaultValues: { size: "1rem", weight: "300", color: "#164194" }
    }),
    isPenalty: generateBooleanSchema("Penalty", "Select if there was a penalty", {
        defaultValue: false
    }),
    txtLive: TextParticleSpec.generateSchema("Live text", "Defaults to `Live` when match status is Live", {
        defaultValues: { size: "1rem", weight: "700", transform: "uppercase" }
    }),
    txtLiveSmall: TextParticleSpec.generateSchema("Live text small", "Defaults to `Live` when match status is Live", {
        defaultValues: { size: "0.75rem", weight: "700", transform: "uppercase" }
    }),
    txtStatus: TextParticleSpec.generateSchema("Status Text", "The text to display status of a match", {
        defaultValues: { size: "1.125rem", weight: "400", text: "FT", color: "#7C7C7C" }
    }),
    txtHomeTeam: TextParticleSpec.generateSchema("Home Team", "Enter home team details", {
        defaultValues: { size: "1.5rem", weight: "700", align: "right", wrap: "wrap", container: { centerContentHorizontally: false, centerContentVertically: false } }
    }),
    txtAwayTeam: TextParticleSpec.generateSchema("Away Team", "Enter away team details", {
        defaultValues: { size: "1.5rem", weight: "700", align: "left", wrap: "wrap", container: { centerContentHorizontally: false, centerContentVertically: false } }
    }),
    txtHomeTeamAbbr: TextParticleSpec.generateSchema("Home Team Abbr", "Enter home team details", {
        defaultValues: { size: "1.5rem", weight: "700", align: "right", container: { centerContentHorizontally: false, centerContentVertically: false, fillWidth: false } }
    }),
    txtAwayTeamAbbr: TextParticleSpec.generateSchema("Away Team Abbr", "Enter away team details", {
        defaultValues: { size: "1.5rem", weight: "700", align: "left", container: { centerContentHorizontally: false, centerContentVertically: false, fillWidth: false } }
    }),
    colorHeaderBackground: generateColorSchema("Header Background", "Pick a color for header"),
    isTicketSoldOut: generateBooleanSchema("Ticket Sold?", "Ticket Sale Status", {
        defaultValue: false
    }),
    ticketSaleStartDate: TextParticleSpec.generateSchema("Tickets Sale Start Date", "Tickets Sale Start Date", {
        defaultValues: { size: "1rem", weight: "800" }
    }),
    attendanceCount: TextParticleSpec.generateSchema("Attendance Count", "Attendance Count", {
        defaultValues: { size: "1rem", weight: "800" }
    }),
    txtCenterBoxText: TextParticleSpec.generateSchema("Center Text", "Center Text", {
        defaultValues: { size: "1rem", weight: "400" }
    }),
    lastButtonColor: generateColorSchema("Last Button BG Color", "Last Button BG Color"),
    buyTicketColor: generateColorSchema("Buy Ticket Button BG Color", "Buy Ticket Button BG Color"),
    defaultButtonColor: generateColorSchema("Default Button BG Color", "Default Button BG Color"),
    bottomRowColor: generateColorSchema("Bottom Row Color", "Last Button Color"),
    isAdditionalContentReqd: generateBooleanSchema("Is Additional Content Required?", "Is Additional Content Required?", {
        defaultValue: false
    }),
    lastButtonFontColor: generateColorSchema("Last Button Font Color", "Last Button Font Color"),
    liveBackgroundColor: generateColorSchema("Live Background Color", "Live Background Color"),
    homeTeamDarkLogoUrl: generateStringSchema("Home Team Dark Logo URL", "Home Team Dark Logo URL"),
    awayTeamDarkLogoUrl: generateStringSchema("Away Team Dark Logo URL", "Away Team Dark Logo URL"),
    shouldSwitchToLiveMode: generateBooleanSchema("Should Switch To Live Mode?", "Should Switch To Live Mode?", {
        defaultValue: false
    })
}));
