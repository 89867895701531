<script lang="ts">import { BaseParticle } from "components/cards/particles";
import { TextInputParticle } from "../TextInput";
import "../Button";
import ButtonParticle from "../Button/ButtonParticle.svelte";
import { resolveUrl } from "js/url";
import { t } from "js/i18n";
import "../Image";
// props
export let container = undefined;
export let inputBox = undefined;
export let clearButton = undefined;
export let showClearButton;
export let searchIconProps;
// outputs
export let _value = '';
function handleKeypress(e) {
    if (e.key === 'Enter') {
        // enter pressed, submit search
        const value = _value;
        _value = '';
        resolveUrl(`yc://action/OPEN_PAGE?intent=SEARCH&contentSlug=${encodeURIComponent(value)}`).func();
        e.preventDefault();
    }
}
function handleClear(e) {
    _value = '';
    e.preventDefault();
}
</script>

<BaseParticle
  _name="SearchParticle"
  _cssVariablePrefix="rich-text-particle"
  {...container}
>
  <div class="search-particle-container">
    <div class="search-box">
      <TextInputParticle
        bind:_value
        on:keydown={handleKeypress}
        _block={true}
        {...inputBox}
        image={{ ...inputBox?.image, ...searchIconProps }}
        text={{...inputBox?.text, text:$t("Search")}}
      />
    </div>
    {#if showClearButton}
      <div class="clear-box">
        <ButtonParticle on:click={handleClear} {...clearButton} />
      </div>
    {/if}
  </div>
</BaseParticle>

<style>
  .search-particle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
    overflow: hidden;
  }

  .search-box {
    flex: 1 1 auto;
  }

  .clear-box {
    flex: 0 0 auto;
  }</style>
