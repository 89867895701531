<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { get, merge } from "lodash";
import { BaseAtom, getSignonManager, } from "components/cards/atoms";
import { TextParticle, ImageParticle, } from "components/cards/particles/YinzCam";
import { onMount } from "svelte";
export let container;
export let overlayPadding;
export let txtGreeting;
export let txtInformation;
export let txtFirstName;
export let txtLastName;
export let txtPlayerNumber;
export let imgTshirt;
export let imgPlayerNumberOne;
export let imgPlayerNumberTwo;
export let textContainerColor;
export let textContainerPadding;
export let textContainerBorderRadius;
export let containerHeight;
const sso = getSignonManager();
const profile = sso.getDefaultProfileSegmentStore();
$: loginStatus = sso.getStatusComponent().store;
$: isLoggedIn = $loginStatus.loggedIn;
$: _txtFirstName = ($profile === null || $profile === void 0 ? void 0 : $profile["first_name"]) || (txtFirstName === null || txtFirstName === void 0 ? void 0 : txtFirstName.text);
$: _txtLastName = ($profile === null || $profile === void 0 ? void 0 : $profile["last_name"]) || (txtLastName === null || txtLastName === void 0 ? void 0 : txtLastName.text);
let atomWidthPx;
let canvas;
let context;
const numberMap = {
    "0": "https://resources-uk.yinzcam.com/soccer/shared/premier_league_jersey_numbers/0.svg",
    "1": "https://resources-uk.yinzcam.com/soccer/shared/premier_league_jersey_numbers/1.svg",
    "2": "https://resources-uk.yinzcam.com/soccer/shared/premier_league_jersey_numbers/2.svg",
    "3": "https://resources-uk.yinzcam.com/soccer/shared/premier_league_jersey_numbers/3.svg",
    "4": "https://resources-uk.yinzcam.com/soccer/shared/premier_league_jersey_numbers/4.svg",
    "5": "https://resources-uk.yinzcam.com/soccer/shared/premier_league_jersey_numbers/5.svg",
    "6": "https://resources-uk.yinzcam.com/soccer/shared/premier_league_jersey_numbers/6.svg",
    "7": "https://resources-uk.yinzcam.com/soccer/shared/premier_league_jersey_numbers/7.svg",
    "8": "https://resources-uk.yinzcam.com/soccer/shared/premier_league_jersey_numbers/8.svg",
    "9": "https://resources-uk.yinzcam.com/soccer/shared/premier_league_jersey_numbers/9.svg",
};
const radianMap = {
    13: 1.05,
    12: 1.06,
    11: 1.07,
    10: 1.08,
    9: 1.09,
    8: 1.1,
    7: 1.11,
    6: 1.12,
    5: 1.13,
    4: 1.2,
    3: 1.3,
    2: 1.5,
    1: 2,
};
$: isMobile = atomWidthPx <= 625;
$: isBigMobile = false; //atomWidthPx > 420 && atomWidthPx <= 800;
$: isTablet = atomWidthPx <= 1050 && atomWidthPx > 625;
$: isSmallDesktop = false; //atomWidthPx < 1275 && atomWidthPx > 1140;
$: bgImageUrl = imgTshirt === null || imgTshirt === void 0 ? void 0 : imgTshirt.source;
$: txtGreetingMultiplier = isMobile ? "0.4" : isTablet ? "0.625" : "1.0";
$: imgNumberMultiplier =
    isMobile || isBigMobile ? "0.75" : isTablet ? "0.85" : "1.00";
function resizeCanvas() {
    renderCanvas(_txtLastName);
}
function renderCanvas(txtLastName) {
    if (!canvas)
        return;
    const context = canvas === null || canvas === void 0 ? void 0 : canvas.getContext("2d");
    const box = document.getElementById("tshirt-text-box");
    const rect = box.getBoundingClientRect();
    requestAnimationFrame(() => {
        var _a;
        canvas.width = (rect === null || rect === void 0 ? void 0 : rect.width) || 0;
        canvas.height = (rect === null || rect === void 0 ? void 0 : rect.height) || 0;
        let string = txtLastName ? (_a = txtLastName === null || txtLastName === void 0 ? void 0 : txtLastName.toUpperCase()) === null || _a === void 0 ? void 0 : _a.trim() : "";
        let fontSize = isMobile
            ? "1.65rem"
            : isBigMobile || isTablet
                ? "2.25rem"
                : "2.5rem";
        context.clearRect(0, 0, canvas.width, canvas.height);
        let len = string === null || string === void 0 ? void 0 : string.length;
        let angleValue = len > 10 && len <= 13
            ? 1.3
            : len >= 8
                ? 1.4
                : len >= 6
                    ? 1.7
                    : 2.0;
        let angle = Math.PI / angleValue; // in radians
        let radius = isMobile ? 80 : isBigMobile || isTablet ? 100 : 130;
        let translateHeight = isMobile || isTablet
            ? canvas.height / 3 + 30
            : canvas.height / 3 + 40;
        // let rotateConst = isMobile || isBigMobile || isTablet ? -0.9 : -1.1;
        context.font = `bold ${fontSize} LCFC Sans`;
        context.fillStyle = "white";
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.save();
        context.translate(canvas.width / 2, translateHeight);
        let initConstant = -1 * radianMap[len];
        let initialRotation = (initConstant * angle) / 2;
        context.rotate(initialRotation);
        // context.rotate((-1 * (angle / len)) / 2);
        for (let i = 0; i < len; i++) {
            context.rotate(angle / len);
            context.save();
            context.translate(0, -radius);
            context.fillText(string[i], 0, 0);
            context.strokeText(string[i], 0, 0);
            context.restore();
        }
        context.restore();
    });
}
function debounceCanvas(func, wait) {
    let timeout;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}
onMount(() => {
    setTimeout(() => {
        renderCanvas(_txtLastName);
    }, 1000);
    window.addEventListener("resize", debounceCanvas(() => setTimeout(resizeCanvas, 100), 100));
});
</script>

<BaseAtom
    _isotope="ProfileHeaderAtom"
    _cssVariablePrefix="profile-header"
    {...container}
    {overlayPadding}
    {bgImageUrl}
    {textContainerColor}
    {textContainerPadding}
    {textContainerBorderRadius}
    {containerHeight}
    bind:_clientWidthPx={atomWidthPx}
>
    <div
        class="main-container"
        id="header-main-container"
        class:layout-tablet-width={isTablet}
        class:layout-mobile-width={isMobile || isBigMobile}
    >
        <div class="border-container"></div>
        <div class="text-container">
            <div class="name-greeting">
                {#if _txtFirstName}
                    <TextParticle
                        {...txtGreeting}
                        text="HELLO, "
                        size={`calc(${get(txtGreeting, "size")}*${txtGreetingMultiplier})`}
                    />
                    <TextParticle
                        {...txtFirstName}
                        text={`${_txtFirstName}!`}
                        size={`calc(${get(txtGreeting, "size")}*${txtGreetingMultiplier})`}
                    />
                {:else}
                    <TextParticle
                        {...txtGreeting}
                        text="HELLO!"
                        size={`calc(${get(txtGreeting, "size")}*${txtGreetingMultiplier})`}
                    />
                {/if}
            </div>
            {#if txtInformation?.text}
                <div class="info-text" style="display: none;">
                    <TextParticle {...txtInformation} />
                </div>
            {/if}
        </div>
        <div class="tshirt-text" id="tshirt-text-box">
            <div class="tshirt-container">
                <ImageParticle {...imgTshirt} />
            </div>
            <div class="last-name">
                <canvas bind:this={canvas}></canvas>
            </div>
            <div class="player-number">
                <ImageParticle
                    source={txtPlayerNumber?.text
                        ? numberMap[txtPlayerNumber?.text[0]]
                        : ""}
                    {...merge({}, imgPlayerNumberOne, {
                        container: {
                            height: `calc(${get(imgPlayerNumberOne, "container.height")}*${imgNumberMultiplier})`,
                        },
                    })}
                />
                <ImageParticle
                    source={txtPlayerNumber?.text
                        ? numberMap[txtPlayerNumber?.text[1]]
                        : ""}
                    {...merge({}, imgPlayerNumberTwo, {
                        container: {
                            height: `calc(${get(imgPlayerNumberTwo, "container.height")}*${imgNumberMultiplier})`,
                        },
                    })}
                />
            </div>
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        display: grid;
        width: 100%;
        height: 100%;
        align-items: center;
        padding: var(--profile-header-overlay-padding, unset);
    }
    .main-container > * {
        grid-area: 1/1;
    }
    .main-container.layout-mobile-width {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        flex-direction: column;
        position: relative;
    }
    .main-container.layout-mobile-width .border-container {
            width: 100%;
            height: 70%;
            position: absolute;
        }
    .main-container.layout-mobile-width .text-container {
            width: 100%;
        }
    .main-container.layout-mobile-width .tshirt-text {
            align-self: center;
            justify-self: center;
            place-self: center;
        }
    .main-container.layout-mobile-width .tshirt-container {
            height: 30rem;
        }
    .main-container.layout-mobile-width .last-name {
            padding-top: 60px;
        }
    .main-container.layout-tablet-width .tshirt-container {
            height: 40rem;
        }
    .main-container.layout-tablet-width .last-name {
            padding-top: 0;
        }
    .main-container.layout-tablet-width .text-container {
            width: 50%;
        }
    .border-container {
        width: 100%;
        height: var(--profile-header-container-height);
        border-radius: var(
            --profile-header-text-container-border-radius,
            unset
        );
        background: var(--profile-header-text-container-color, unset);
    }
    .text-container {
        z-index: 1;
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 1rem;
        padding: var(--profile-header-text-container-padding, unset);
    }
    .name-greeting {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }
    .tshirt-text {
        height: 100%;
        align-self: end;
        justify-self: end;
        place-self: end;
        display: grid;
        align-items: center;
        justify-items: center;
        place-items: center;
        position: relative;
    }

    .tshirt-text > * {
        grid-area: 1/1;
    }
    .tshirt-container {
        width: 100%;
        height: 100%;
        position: relative;
        height: 40rem;
    }
    .last-name {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        align-self: flex-start;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 100px;
        text-align: center;
    }
    .player-number {
        z-index: 1;
        margin-top: -20%;
    }

    canvas {
        position: absolute;
        left: 0;
        top: 0;
    }</style>
