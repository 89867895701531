<script lang="ts">import { BaseParticle } from "components/cards/particles";
import "./spec";
import { getTranslator } from "components/cards/utilities";
// props
export let container = undefined;
export let name = "";
export let source = "";
export let translations = [];
// inputs
export let _block = false;
$: xlator = getTranslator(translations, { source });
$: styles = {};
</script>

<style>
  .iframe-container, .iframe-container > iframe {
    width: 100%;
  }

  .iframe-container > iframe {
    width: 100%;
    min-height: 15rem;
    aspect-ratio: 16/9;
  }</style>

<BaseParticle _name="WebEmbedParticle" _inline={!_block} _cssVariablePrefix="web-embed-particle" {...container} {...styles} >
  <div class="iframe-container">
    <iframe title={name} src={xlator({ source })} style="border: none;" allowfullscreen
      sandbox="allow-scripts allow-presentation allow-same-origin"
      allow="autoplay; fullscreen; picture-in-picture; xr-spatial-tracking; clipboard-write">
    </iframe>
  </div>
</BaseParticle>
