<script lang="ts">import { BaseParticle, } from "components/cards/particles";
import { ButtonParticle } from "../../Core/Button";
import { merge } from "lodash";
// props
export let container = undefined;
export let shareLink = undefined;
export let shareText = undefined;
export let buttonDefaults = undefined;
export let showShareLink = true;
export let showShareMail = true;
export let showShareLinkedIn = true;
export let showShareTwitter = true;
export let showShareFacebook = true;
export let showShareWeibo = true;
export let showShareLine = true;
export let showIconBackground;
export let iconFacebook = undefined;
export let iconTwitter = undefined;
export let iconCopy = undefined;
export let iconEmail = undefined;
export let iconWeibo = undefined;
export let iconLine = undefined;
let socialMediaIcons = CONFIG === null || CONFIG === void 0 ? void 0 : CONFIG.socialMediaIcons;
function popupShare(type) {
    var _a;
    let rawUrl = new URL(shareLink, window.origin).toString();
    let url = encodeURIComponent(rawUrl);
    let title = encodeURIComponent(shareText);
    switch (type) {
        case "link":
            (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(rawUrl);
            alert("URL copied to clipboard.");
            return;
        case "facebook":
            url = `https://facebook.com/sharer/sharer.php?title=${title}&u=${url}`;
            break;
        case "mail":
            url = `mailto:someone@domain.com?subject=${title}&body=${url}`;
            window.open(url, "_self");
            return;
        case "twitter":
            url = `https://twitter.com/intent/tweet/?text=${title}&url=${url}`;
            break;
        case "whatsapp":
            url = `whatsapp://send?text=${title + "\n" + url}`;
            break;
        case "weibo":
            url = `https://service.weibo.com/share/share.php?url=${encodeURIComponent(url)}`;
            break;
        case "line":
            url = `https://social-plugins.line.me/lineit/share?url=${url}&text=${title}`;
            break;
        default:
            break;
    }
    window.open(url, "YCShareWindow", `height=450, width=550, top=${window.innerHeight / 2 - 225}, left=${window.innerWidth / 2 - 275}, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`);
}
function navigatorShare(type) {
    navigator.share({
        url: shareLink,
        text: shareText,
    });
}
function onShareLink() {
    popupShare("link");
}
function onShareMail() {
    popupShare("mail");
}
function onShareLinkedIn() {
    popupShare("linkedin");
}
function onShareTwitter() {
    popupShare("twitter");
}
function onShareFacebook() {
    popupShare("facebook");
}
function onShareWeibo() {
    popupShare("weibo");
}
function onShareLine() {
    popupShare("line");
}
</script>

<BaseParticle
  _name="ShareStripParticle"
  _cssVariablePrefix="share-strip-particle"
  {...container}
>
  <div class="share-strip">
    {#if shareLink}
      <div class="share-button">
        {#if showShareLink}
          <ButtonParticle
            on:click={onShareLink}
            {...merge({}, iconCopy, {
              image: { source: socialMediaIcons?.copyLink },
              container: {
                background: {
                  color: showIconBackground ? "#E4E4E4" : "transparent",
                },
              },
            })}
          />
        {/if}
      </div>
      <!--
    <div class="share-button">
      {#if showShareLinkedIn}
      <ButtonParticle on:click={onShareLinkedIn} {...merge({}, buttonDefaults, { image: { icon: 'yc_icon_linkedin'} })} />
      {/if}
    </div>
    -->
      <div class="share-button">
        {#if showShareMail}
          <ButtonParticle
            on:click={onShareMail}
            {...merge({}, iconEmail, {
              image: { source: socialMediaIcons?.mail },
              container: {
                background: {
                  color: showIconBackground ? "#E4E4E4" : "transparent",
                },
              },
            })}
          />
        {/if}
      </div>
      <div class="share-button">
        {#if showShareTwitter}
          <ButtonParticle
            on:click={onShareTwitter}
            {...merge({}, iconTwitter, {
              image: { source: socialMediaIcons?.x },
              container: {
                background: {
                  color: showIconBackground ? "#E4E4E4" : "transparent",
                },
              },
            })}
          />
        {/if}
      </div>
      <div class="share-button">
        {#if showShareFacebook}
          <ButtonParticle
            on:click={onShareFacebook}
            {...merge({}, iconFacebook, {
              image: { source: socialMediaIcons?.facebook },
              container: {
                background: {
                  color: showIconBackground ? "#E4E4E4" : "transparent",
                },
              },
            })}
          />
        {/if}
      </div>
      <div class="share-button">
        {#if showShareWeibo}
          <ButtonParticle
            on:click={onShareWeibo}
            {...merge({}, iconWeibo, {
              image: {source: socialMediaIcons?.weibo},
              container: {
                background: {
                  color: showIconBackground ? "#E4E4E4" : "transparent",
                },
              },
            })}
          />
        {/if}
      </div>
      <div class="share-button">
        {#if showShareLine}
          <ButtonParticle
            on:click={onShareLine}
            {...merge({}, iconLine, {
              image: {source: socialMediaIcons?.line},
              container: {
                background: {
                  color: showIconBackground ? "#E4E4E4" : "transparent",
                },
              },
            })}
          />
        {/if}
      </div>
    {/if}
  </div>
</BaseParticle>

<style>
  .share-strip {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }</style>
