<script context="module" lang="ts">var _a, _b, _c, _d;
export { specification } from "./spec";
</script>

<script lang="ts">var _a, _b, _c, _d;
import { get, merge, uniqueId } from "lodash";
import { t } from "js/i18n";
import { DateTime } from "luxon";
import { BaseAtom, getSEOManager, } from "components/cards/atoms";
import { TextParticle, ImageParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
import "js/seo";
import { onDestroy } from "svelte";
import { resizeObserverAction } from "components/cards/common/actions";
export let container;
export let overlayPadding;
export let backgroundImage;
export let backgroundImageTint;
export let backgroundImageTintColor;
export let homePlayerGoals = undefined;
export let awayPlayerGoals = undefined;
export let homeTeamDetails = undefined;
export let awayTeamDetails = undefined;
export let imgLeagueIcon;
export let imgLiveIcon;
export let txtLive;
export let txtMinute;
export let txtHomeScore;
export let txtAwayScore;
export let txtVenueName;
export let matchDateTime;
export let matchStatus;
export let colorFlagBanner;
export let txtMatchDate;
export let txtMatchTime;
export let txtFullTime;
export let txtFlagLineOne;
export let txtFlagLineTwo;
export let imgRefereeIcon;
export let txtRefereeName;
export let txtRefereePosition;
export let btnTwitterHashtag;
export let btnCta;
export let txtHalfTime;
export let venueAddress;
export let imgSocialIcon;
export let homeLogoLightUrl;
export let awayLogoLightUrl;
export let leagueLogoLightUrl;
export let isLightLogoReqd;
export let btnListenLive;
export let setPageMetadata = undefined;
export let horizontalLineColor;
export let penaltyText;
export let headerBackgroundImageUrl;
let atomWidthPx;
const formatDateTime = (dateString, t) => {
    var _a;
    const dt = DateTime.fromISO(dateString, { zone: "Europe/London" });
    const convertedDateTime = (_a = dt
        .toLocaleString({
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZoneName: "short",
    })) === null || _a === void 0 ? void 0 : _a.split(" ");
    return convertedDateTime === null || convertedDateTime === void 0 ? void 0 : convertedDateTime.map((string) => string.trim().replace(",", ""));
};
function mapPlayerGoalMinute(playerGoals) {
    let result = {};
    playerGoals === null || playerGoals === void 0 ? void 0 : playerGoals.forEach((player) => {
        var _a, _b;
        let playerName = (_a = player === null || player === void 0 ? void 0 : player.name) === null || _a === void 0 ? void 0 : _a.text;
        let goalMin = (_b = player === null || player === void 0 ? void 0 : player.goalMinute) === null || _b === void 0 ? void 0 : _b.text;
        if (!result[playerName]) {
            result[playerName] = `${goalMin}`;
        }
        else {
            result[playerName] += `, ${goalMin}`;
        }
    });
    return result;
}
$: isMobile = atomWidthPx <= 600;
$: isTablet = atomWidthPx <= 800 && atomWidthPx > 600;
$: leagueIconMultiplier = isMobile ? "1.00" : "1.50";
$: txtPlayerNameMultiplier = isMobile ? "0.60" : isTablet ? "0.8" : "1.00";
$: txtFlagTextMultiplier = isMobile ? "0.60" : "1.00";
$: txtLiveMultiplier = isMobile ? "0.70" : "1.00";
$: txtScoreMultiplier = isMobile ? "0.6" : isTablet ? "0.75" : "1.00";
$: txtStadiumMultiplier = isMobile ? "0.58" : isTablet ? "0.83" : "1.00";
$: imgTeamIconMultiplier = isMobile ? "0.52" : isTablet ? "0.62" : "1.00";
$: imgLiveIconMultiplier = isMobile || isTablet ? "1.00" : "1.00";
$: txtTweetMultiplier = isMobile ? "0.75" : isTablet ? "0.9" : "1.00";
$: txtListenLiveMultiplier = isMobile ? "0.55" : isTablet ? "0.9" : "1.00";
$: [day, date, month, year, , time, timeperiod, timezone] =
    formatDateTime(matchDateTime, $t);
$: venueName = (_a = txtVenueName === null || txtVenueName === void 0 ? void 0 : txtVenueName.text) === null || _a === void 0 ? void 0 : _a.split(",")[0];
$: city = (_b = txtVenueName === null || txtVenueName === void 0 ? void 0 : txtVenueName.text) === null || _b === void 0 ? void 0 : _b.split(",")[1];
$: twitterHashtag = `${homeTeamDetails === null || homeTeamDetails === void 0 ? void 0 : homeTeamDetails.tricode}${awayTeamDetails === null || awayTeamDetails === void 0 ? void 0 : awayTeamDetails.tricode}`;
$: twitterUrl = `https://x.com/hashtag/${twitterHashtag}?src=hash`;
$: homeGoalsMap = mapPlayerGoalMinute(homePlayerGoals);
$: awayGoalsMap = mapPlayerGoalMinute(awayPlayerGoals);
$: txtRefereeMultiplier = isMobile ? "0.60" : "1.00";
$: liveIconWidth = isMobile ? 16 : 32;
$: whistleIconWidth = isMobile ? 32 : 64;
$: dateString = isMobile
    ? `${$t(day === null || day === void 0 ? void 0 : day.slice(0, 3))} ${date} ${$t(month === null || month === void 0 ? void 0 : month.slice(0, 3))}, ${year}`
    : `${$t(day)} ${date} ${$t(month)}, ${year}`;
const seoManager = getSEOManager();
const seoConfigKey = uniqueId();
$: if (setPageMetadata &&
    ((_c = homeTeamDetails === null || homeTeamDetails === void 0 ? void 0 : homeTeamDetails.name) === null || _c === void 0 ? void 0 : _c.text) &&
    ((_d = awayTeamDetails === null || awayTeamDetails === void 0 ? void 0 : awayTeamDetails.name) === null || _d === void 0 ? void 0 : _d.text)) {
    /*
    seoManager.registerSEOConfiguration({
        key: seoConfigKey,
        priority: JanusSEOPriority.Card,
        isPopup: false,
        title: homeTeamDetails.name?.text + " v " + awayTeamDetails.name?.text,
    });
    */
}
onDestroy(() => {
    seoManager.unregisterSEOConfiguration(seoConfigKey);
});
let overlayAreaEl;
function handleHomeTeamLogoResize({ borderBoxSize, contentBoxSize }) {
    if (!(borderBoxSize === null || borderBoxSize === void 0 ? void 0 : borderBoxSize.length)) {
        return;
    }
    const widthPx = borderBoxSize[0].inlineSize;
    if (overlayAreaEl) {
        const els = overlayAreaEl.querySelectorAll('.home-goals-box');
        for (let i = 0; i < els.length; i++) {
            els[i].style.width = `${widthPx}px`;
        }
    }
}
function handleAwayTeamLogoResize({ borderBoxSize, contentBoxSize }) {
    if (!(borderBoxSize === null || borderBoxSize === void 0 ? void 0 : borderBoxSize.length)) {
        return;
    }
    const widthPx = borderBoxSize[0].inlineSize;
    if (overlayAreaEl) {
        const els = overlayAreaEl.querySelectorAll('.away-goals-box');
        for (let i = 0; i < els.length; i++) {
            els[i].style.width = `${widthPx}px`;
        }
    }
}
</script>

<BaseAtom
    bind:_clientWidthPx={atomWidthPx}
    _isotope="MCHeaderAtomB"
    _cssVariablePrefix="mc-headerb"
    {...container}
    {overlayPadding}
    {colorFlagBanner}
    {liveIconWidth}
    {whistleIconWidth}
    {horizontalLineColor}
    {backgroundImageTintColor}
>
    <div class="main-container">
        <div class="background-image">
            <ImageParticle
                {...backgroundImage}
                _block={true}
                source={headerBackgroundImageUrl
                    ? headerBackgroundImageUrl
                    : backgroundImage?.source}
            />
        </div>
        <div
            class="overlay-area"
            class:tablet-width={isTablet}
            class:mobile-width={isMobile}
            class:with-tint={backgroundImageTint}
            bind:this={overlayAreaEl}
        >
            <div class="top-row">
                <div class="date-time-flag">
                    <div class="date-time-wrapper">
                        <div class="match-time">
                            <TextParticle
                                {...txtFlagLineOne}
                                text={`${time} ${$t(timeperiod)} KO`}
                                size={`calc(${txtFlagLineOne?.size}*${txtFlagTextMultiplier})`}
                                _block={true}
                            />
                        </div>
                        <div class="match-date">
                            <TextParticle
                                {...txtFlagLineTwo}
                                text={`${isMobile ? $t(day?.slice(0, 3)) : $t(day)} ${date} ${isMobile ? $t(month?.slice(0, 3)) : $t(month)}, ${year}`}
                                size={`calc(${txtFlagLineTwo?.size}*${txtFlagTextMultiplier})`}
                                _block={true}
                            />
                        </div>
                    </div>
                </div>
                <div class="league-icon">
                    <ImageParticle
                        {...merge({}, imgLeagueIcon, {
                            container: {
                                height: `calc(${get(imgLeagueIcon, "container.height")} * ${leagueIconMultiplier})`,
                                width: `calc(${get(imgLeagueIcon, "container.width")} * ${leagueIconMultiplier})`,
                            },
                        })}
                        _block={true}
                        source={isLightLogoReqd
                            ? leagueLogoLightUrl
                            : imgLeagueIcon?.source}
                    />
                </div>
                <div class="venue-city-flag">
                    <div class="venue-city-wrapper">
                        <div class="venue">
                            <TextParticle
                                {...txtFlagLineOne}
                                text={venueName}
                                size={`calc(${txtFlagLineOne?.size}*${txtFlagTextMultiplier})`}
                                _block={true}
                            />
                        </div>
                        <div class="venue-address">
                            <TextParticle
                                {...txtFlagLineTwo}
                                text={venueAddress}
                                size={`calc(${txtFlagLineTwo?.size}*${txtFlagTextMultiplier})`}
                                _block={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="match-container">
                <div class="grid-container">
                    <div class="team-score-grid">
                        <div class="home-team-details">
                            <div class="home-team-logo" use:resizeObserverAction={handleHomeTeamLogoResize}>
                                <ImageParticle
                                    {...merge(
                                        {},
                                        get(homeTeamDetails, "icon"),
                                        {
                                            container: {
                                                height: `calc(${get(homeTeamDetails, "icon.container.height")}*${imgTeamIconMultiplier})`,
                                                width: `calc(${get(homeTeamDetails, "icon.container.width")}*${imgTeamIconMultiplier})`,
                                            },
                                        },
                                    )}
                                    _block={true}
                                    source={isLightLogoReqd
                                        ? homeLogoLightUrl
                                        : homeTeamDetails?.icon?.source}
                                />
                            </div>
                        </div>
                        <div class="top-mid-content">
                            {#if matchStatus === "pre"}
                                <div class="pre-content">
                                    <div class="match-time">
                                        <TextParticle
                                            {...txtMatchTime}
                                            text={`${time} ${$t(timeperiod)}`}
                                        />
                                    </div>
                                </div>
                            {/if}
                            {#if matchStatus === "post"}
                                <div class="post-content">
                                    <div class="home-score">
                                        <TextParticle
                                            {...txtHomeScore}
                                            size={`calc(${get(txtHomeScore, "size")} * ${txtScoreMultiplier})`}
                                        />
                                    </div>
                                    <span class="score-dash">-</span>
                                    <div class="away-score">
                                        <TextParticle
                                            {...txtAwayScore}
                                            size={`calc(${get(txtAwayScore, "size")} * ${txtScoreMultiplier})`}
                                        />
                                    </div>
                                </div>
                            {/if}
                            {#if matchStatus === "live"}
                                <div class="live-content">
                                    <div class="home-score">
                                        <TextParticle
                                            {...txtHomeScore}
                                            size={`calc(${get(txtHomeScore, "size")} * ${txtScoreMultiplier})`}
                                        />
                                    </div>
                                    <div class="live-minute">
                                        <div class="minute">
                                            <TextParticle
                                                {...txtMinute}
                                                _block={true}
                                                size={`calc(${get(txtMinute, "size")}*${txtLiveMultiplier})`}
                                            />
                                        </div>
                                        <div class="live-text">
                                            <div class="live-icon-ind">
                                                <svg
                                                    width={`${liveIconWidth}`}
                                                    height={`${liveIconWidth}`}
                                                    viewBox="0 0 32 32"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <circle
                                                        cx="16"
                                                        cy="16"
                                                        r="11"
                                                        stroke="red"
                                                        stroke-width="2"
                                                    />
                                                    <circle
                                                        cx="16"
                                                        cy="16"
                                                        r="6.66667"
                                                        fill="red"
                                                    />
                                                </svg>
                                            </div>
                                            <div>
                                                <TextParticle
                                                    {...txtLive}
                                                    _block={true}
                                                    size={`calc(${get(txtLive, "size")}*${txtLiveMultiplier})`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="away-score">
                                        <TextParticle
                                            {...txtAwayScore}
                                            size={`calc(${get(txtAwayScore, "size")} * ${txtScoreMultiplier})`}
                                        />
                                    </div>
                                </div>
                            {/if}
                        </div>
                        <div class="away-team-details">
                            <div class="away-team-logo" use:resizeObserverAction={handleAwayTeamLogoResize}>
                                <ImageParticle
                                    {...merge(
                                        {},
                                        get(awayTeamDetails, "icon"),
                                        {
                                            container: {
                                                height: `calc(${get(awayTeamDetails, "icon.container.height")}*${imgTeamIconMultiplier})`,
                                                width: `calc(${get(awayTeamDetails, "icon.container.width")}*${imgTeamIconMultiplier})`,
                                            },
                                        },
                                    )}
                                    _block={true}
                                    source={isLightLogoReqd
                                        ? awayLogoLightUrl
                                        : awayTeamDetails?.icon?.source}
                                />
                            </div>
                        </div>
                    </div>
                    <div class="player-venue-grid">
                        <div class="home-players">
                            {#if matchStatus !== "pre"}
                                {#each Object.entries(homeGoalsMap) || [] as homeGoal}
                                    <div class="home-goals-box">
                                        <div class="name-minute">
                                            <TextParticle
                                                {...homePlayerGoals[0].name}
                                                size={`calc(${get(homePlayerGoals[0], "name.size")} * ${txtPlayerNameMultiplier})`}
                                                text={homeGoal[0]}
                                            />
                                            <TextParticle
                                                {...homePlayerGoals[0]
                                                    .goalMinute}
                                                size={`calc(${get(homePlayerGoals[0], "goalMinute.size")} * ${txtPlayerNameMultiplier})`}
                                                text={homeGoal[1]}
                                            />
                                        </div>
                                    </div>
                                {/each}
                            {/if}
                        </div>
                        <div class="bottom-mid-content">
                            <!-- {#if matchStatus === "pre"}
                                <div class="date-venue">
                                    <div class="match-date">
                                        <TextParticle
                                            {...txtMatchDate}
                                            text={dateString}
                                        />
                                    </div>
                                    <div class="venue-name">
                                        <TextParticle
                                            {...txtVenueName}
                                            size={`calc(${get(txtVenueName, "size")}*${txtStadiumMultiplier})`}
                                        />
                                    </div>
                                </div>
                            {/if} -->
                            {#if matchStatus === "post"}
                                <div class="half-full-score">
                                    <div class="full-time-text">
                                        <TextParticle {...txtFullTime} />
                                    </div>
                                    {#if txtHalfTime?.text}
                                        <div class="half-time">
                                            <TextParticle
                                                {...txtHalfTime}
                                                text={`HT ${txtHalfTime?.text}`}
                                            />
                                        </div>
                                    {/if}
                                </div>
                            {/if}
                        </div>
                        <div class="away-players">
                            {#if matchStatus !== "pre"}
                                {#each Object.entries(awayGoalsMap) || [] as awayGoal}
                                    <div class="away-goals-box">
                                        <div class="name-minute">
                                            <TextParticle
                                                {...awayPlayerGoals[0].name}
                                                size={`calc(${get(awayPlayerGoals[0], "name.size")} * ${txtPlayerNameMultiplier})`}
                                                text={awayGoal[0]}
                                            />
                                            <TextParticle
                                                {...awayPlayerGoals[0].goalMinute}
                                                size={`calc(${get(awayPlayerGoals[0], "goalMinute.size")} * ${txtPlayerNameMultiplier})`}
                                                text={awayGoal[1]}
                                            />
                                        </div>
                                    </div>
                                {/each}
                            {/if}
                        </div>
                    </div>
                </div>
                {#if penaltyText?.text}
                    <div class="penalty-text">
                        <TextParticle {...penaltyText} />
                    </div>
                {/if}
            </div>
            <div class="bottom-row-content">
                <div class="referee-details">
                    <div class="referee-icon">
                        <svg
                            width={`${whistleIconWidth}`}
                            height={`${whistleIconWidth}`}
                            viewBox="0 0 32 32"
                            fill="white"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.0711 19.7755C8.0711 19.5993 8.06308 19.4231 8.0711 19.2508C8.17924 16.8516 9.24868 15.0051 11.2874 13.7314C11.6038 13.5312 11.9523 13.3709 12.3008 13.2348C14.3195 12.4457 16.3462 11.6727 18.3689 10.8956L18.4971 10.8475C18.5131 10.8836 18.5251 10.9156 18.5372 10.9517C18.7695 11.5525 18.9978 12.1493 19.2301 12.7501C19.3222 12.9904 19.5625 13.1186 19.8149 13.0665C20.0472 13.0185 20.2234 12.7982 20.2154 12.5578C20.2154 12.4898 20.1954 12.4177 20.1714 12.3496C19.9471 11.7648 19.7228 11.18 19.4985 10.5992C19.4864 10.5632 19.4704 10.5311 19.4544 10.4831L19.5585 10.443C20.624 10.0345 21.6894 9.6219 22.7548 9.21335C23.3196 8.99706 23.8924 9.2534 24.1127 9.81416C24.7175 11.3803 25.3183 12.9464 25.9231 14.5125C26.1394 15.0772 25.887 15.654 25.3223 15.8703C23.9404 16.403 22.5586 16.9357 21.1727 17.4684L21.0606 17.5125C21.1166 17.7608 21.1767 17.9972 21.2208 18.2415C21.6894 20.7448 20.624 23.3564 18.5412 24.8183C15.7054 26.809 11.8562 26.3123 9.62518 23.6648C9.56911 23.5967 9.52505 23.5927 9.44494 23.6207C9.13653 23.7369 8.83212 23.869 8.51569 23.9572C7.65053 24.1975 6.6612 23.7529 6.24865 22.9558C5.73195 21.9625 6.04037 20.825 6.98964 20.2362C7.08978 20.1721 7.19792 20.124 7.31007 20.08C7.56642 19.9758 7.82276 19.8797 8.07911 19.7836L8.0711 19.7755ZM13.1259 15.3376C10.8789 16.2027 9.75336 18.7221 10.6105 20.9651C11.4757 23.2202 13.9951 24.3497 16.2461 23.4885C18.4971 22.6274 19.6266 20.1 18.7655 17.853C17.9003 15.6019 15.3769 14.4764 13.1259 15.3416V15.3376Z"
                                fill="white"
                            />
                            <path
                                d="M17.1753 7.25875C17.0552 6.95033 16.939 6.64592 16.8189 6.33751C16.7107 6.05312 16.8349 5.76073 17.1072 5.65659C17.3756 5.55646 17.66 5.68463 17.7681 5.96501C18.0085 6.58985 18.2488 7.21469 18.4891 7.83953C18.5973 8.1199 18.4731 8.40829 18.2047 8.51243C17.9324 8.61657 17.648 8.48439 17.5358 8.20001C17.4157 7.88759 17.2955 7.57117 17.1753 7.25875Z"
                                fill="white"
                            />
                            <path
                                d="M14.4356 7.23064C14.5999 7.17857 14.7561 7.18258 14.8963 7.28271C15.409 7.63919 15.9176 7.99968 16.4263 8.36417C16.6586 8.53239 16.6987 8.84481 16.5345 9.06911C16.3703 9.29342 16.0658 9.34949 15.8375 9.18928C15.3329 8.8368 14.8282 8.48032 14.3235 8.11984C14.1593 7.99968 14.0872 7.83145 14.1192 7.63118C14.1473 7.43892 14.2634 7.30675 14.4397 7.23064H14.4356Z"
                                fill="white"
                            />
                            <path
                                d="M20.1433 7.76742C19.7388 7.91962 19.3463 7.6072 19.4264 7.22269C19.5545 6.60185 19.6947 5.98102 19.8429 5.36419C19.903 5.11185 20.1634 4.96365 20.4197 5.00771C20.68 5.05578 20.8603 5.28809 20.8363 5.55244C20.8363 5.58449 20.8282 5.62053 20.8202 5.65258C20.6921 6.23336 20.5599 6.81013 20.4317 7.39091C20.3917 7.57917 20.2835 7.70734 20.1433 7.76742Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                    <div class="referee-name-position">
                        {#if matchStatus === "pre"}
                            <TextParticle
                                {...txtRefereeName}
                                text="TBD"
                                size={`calc(${get(txtRefereeName, "size")}*${txtRefereeMultiplier})`}
                                _block={true}
                            />
                        {:else}
                            <TextParticle
                                {...txtRefereeName}
                                size={`calc(${get(txtRefereeName, "size")}*${txtRefereeMultiplier})`}
                                _block={true}
                            />
                        {/if}
                        <TextParticle
                            {...txtRefereePosition}
                            text={"Referee"}
                            size={`calc(${get(txtRefereeName, "size")}*${txtRefereeMultiplier})`}
                            _block={true}
                        />
                    </div>
                </div>
                <div class="listen-live-section">
                    <div class="listen-live-button">
                        <ButtonParticle
                            {...merge({}, btnListenLive, {
                                text: {
                                    size: `calc(${get(btnListenLive, "text.size")}*${txtListenLiveMultiplier})`,
                                },
                                image: {
                                    source: "https://resources-uk.yinzcam.com/soccer/shared/icon_lei_foxeshub.png",
                                },
                            })}
                        />
                    </div>
                </div>
                <div class="twitter-hashtag" class:mobile={isMobile}>
                    <ImageParticle {...imgSocialIcon} _block={true} />
                    <ButtonParticle
                        {...merge({}, btnTwitterHashtag, {
                            text: {
                                size: `calc(${get(btnTwitterHashtag, "text.size")}*${txtTweetMultiplier})`,
                                text: `#${twitterHashtag}`,
                            },
                        })}
                        link={twitterUrl}
                    />
                </div>
                <!-- <div class="cta-button">
                    <div class="cta-icon">
                        <ButtonParticle
                            {...merge({}, btnCta, {
                                text: {
                                    size: `calc(${get(btnCta, "text.size")}*${txtRefereeMultiplier})`,
                                },
                            })}
                        />
                        <svg
                            width={`${whistleIconWidth}`}
                            height={`${whistleIconWidth}`}
                            viewBox="0 0 32 32"
                            fill="white"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M23.6038 14.4647L26.1991 10.3405L23.2569 8.49034C22.5245 9.65308 20.9891 9.99996 19.8328 9.27405C18.6701 8.54172 18.3231 7.0065 19.049 5.85018L16.1067 4L13.5118 8.1177L15.034 9.07503L14.3982 10.0835L12.8756 9.12631L5 21.653L7.94191 23.5097C8.67424 22.3469 10.2096 22 11.3659 22.726C12.5287 23.4583 12.8757 24.9807 12.1498 26.1498L15.092 28L22.9805 15.4668L21.458 14.5097L22.0941 13.5075L23.6038 14.4582V14.4647ZM17.2695 11.8886L15.7601 10.9379L16.3831 9.92931L17.9056 10.8736L17.2695 11.8822V11.8886ZM20.0897 13.6617L18.58 12.7109L19.2162 11.7023L20.7321 12.6659L20.0963 13.6681L20.0897 13.6617Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        display: grid;
        width: 100%;
        height: 100%;
    }

    .background-image {
        grid-area: 1/1;
    }
    .overlay-area {
        width: 100%;
        grid-area: 1/1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .overlay-area.with-tint {
        /*
        background: linear-gradient(180deg, var(--mc-headerb-background-image-tint-color, var(--theme-mode-accent-color, transparent)) 0%, var(--mc-headerb-background-image-tint-color, var(--theme-mode-accent-color, transparent)) 100%);
        */
        background-color: var(--mc-headerb-background-image-tint-color, var(--theme-mode-accent-color, transparent));
    }
    .top-row {
        width: 100%;
        display: grid;
        grid-template-columns: 3fr 1.5fr 3fr;
        grid-auto-rows: auto;
        grid-gap: 1rem;
        gap: 1rem;
    }
    .league-icon {
        padding-top: 2.5rem;
        margin-bottom: -1rem;
    }
    .date-time-flag {
        background-color: var(--mc-headerb-color-flag-banner);
        border-bottom-right-radius: 5rem;
        padding: 1rem;
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .venue-city-flag {
        background-color: var(--mc-headerb-color-flag-banner);
        border-bottom-left-radius: 5rem;
        padding: 1rem;
        text-align: right;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .match-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .grid-container {
        /*width: 90%;*/
        align-items: center;
        justify-items: center;
        place-items: center;
    }
    .team-score-grid,
    .player-venue-grid {
        width: 100%;
        display: grid;
        grid-template-columns: 3fr 2fr 3fr;
        /*justify-items: center;*/
        align-items: center;
    }
    .home-team-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
    }
    .away-team-details {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
        flex-wrap: wrap;
    }
    .away-players,
    .home-players {
        display: flex;
        flex-direction: column;
        align-self: start;
    }
    .home-players {
        text-align: center;
        align-items: flex-start;
    }
    .away-players {
        text-align: center;
        align-items: flex-end;
    }
    .home-goals-box,
    .away-goals-box {
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
    }
    .home-goals-box .name-minute, .away-goals-box .name-minute {
            /*flex: 1 1 max-content;*/
        }
    .players-venue-grid {
        display: grid;
        grid-template-columns: 2fr 1fr 2fr;
    }
    .pre-content {
        align-self: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
    }
    .post-content {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 1rem;
    }
    .post-content span {
            color: var(--theme-mode-foreground-color, #000);
            text-align: center;
            font-size: 5rem;
            font-weight: 700;
        }
    .date-venue {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: flex-end;
        gap: 1rem;
    }
    .date-venue .venue-name {
            text-align: center;
        }
    .bottom-row-content {
        width: 80%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        border-top: 2px solid var(--mc-headerb-horizontal-line-color, #fff);
        padding: 1rem;
        grid-gap: 1.5rem;
        gap: 1.5rem;
    }

    .referee-details {
        /* flex-basis: 30%; */
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .referee-details .referee-name-position {
            display: flex;
            flex-direction: column;
        }

    .referee-details .referee-name-position > :global(*) {
            justify-content: left;
        }
    .half-full-score {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .live-content {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 1rem;
    }
    .cta-icon {
        display: flex;
        align-items: center;
    }
    .live-text {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.125rem;
    }
    .overlay-area.mobile-width .bottom-row-content, .overlay-area.tablet-width .bottom-row-content {
            width: 100%;
            justify-content: space-around;
        }
    .overlay-area.mobile-width .home-goals-box, .overlay-area.tablet-width .home-goals-box, .overlay-area.mobile-width .away-goals-box, .overlay-area.tablet-width .away-goals-box {
            white-space: normal;
        }

    .overlay-area.mobile-width .top-row .date-time-flag {
                align-items: flex-start;
            }

    .overlay-area.mobile-width .top-row .venue-city-flag {
                align-items: flex-end;
            }

    .overlay-area.mobile-width .bottom-row-content {
            /* .twitter-hashtag.mobile {
                order: -1;
                flex: 1 0 100%;
            }
            .referee-details,
            .cta-button {
                flex-basis: unset;
            } */
        }

    .overlay-area.mobile-width .home-score, .overlay-area.mobile-width .away-score {
            min-width: 3rem;
        }
    .referee-details,
    .cta-button {
        /* flex-basis: 30%; */
    }
    .cta-button {
        display: flex;
        justify-content: end;
    }
    .live-icon-ind {
        display: block;
    }
    .twitter-hashtag {
        justify-self: end;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.25rem;
    }
    .twitter-hashtag > :global(div) {
            flex: unset;
        }
    .live-minute {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .top-mid-content {
        width: 100%;
    }
    .listen-live-section {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .penalty-text {
        width: 100%;
        text-align: center;
        padding: 2rem 0;
    }
    .score-dash {
        display: inline-block;
        padding: 0rem 1rem;
    }
    .home-score, .away-score {
        min-width: 5rem;
        text-align: center;
    }</style>
