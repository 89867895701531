
<script lang="ts">import { AudioParticle, AudioParticleSpec, GalleryCarouselParticleSpec, ImageParticle, ImageParticleSpec, RichTextParticle, RichTextParticleSpec, TextParticle, TextParticleSpec, VideoParticle, VideoParticleSpec, WebEmbedParticle, WebEmbedParticleSpec } from "components/cards/particles/YinzCam";
import "./ArticleBodyNode";
import { merge } from "lodash";
import { resolveUrl, shouldOpenInNewWindow } from "js/url";
import GalleryCarouselParticle from "components/cards/particles/YinzCam/Media/GalleryCarousel/GalleryCarouselParticle.svelte";
export let node = undefined;
export let textDefaults = undefined;
export let imageDefaults = undefined;
export let galleryDefaults = undefined;
export let videoDefaults = undefined;
export let audioDefaults = undefined;
export let webEmbedDefaults = undefined;
export let htmlDefaults = undefined;
</script>

<style>
  a.article-body-link {
    font-weight: 700;
  }</style>

{#if node}
{#if node.type === 'text-block'}
{#each node.nodes as subNode}
{#if subNode}
{#if subNode.type === 'link' && subNode.uri && subNode.nodes && subNode.nodes.length > 0}
<a class="article-body-link link" href={resolveUrl(subNode.uri).href} class:external={resolveUrl(subNode.uri).external} target={(shouldOpenInNewWindow(resolveUrl(subNode.uri).href))? '_blank' : '_self'}>
{#each subNode.nodes as subSubNode}
{#if subSubNode.type === 'text' && subSubNode.particleProps}
  <TextParticle {...TextParticleSpec.propsWithDefaults(merge({}, textDefaults, subSubNode.particleProps, { useAccentColor: true }))} />
{/if}
{/each}
</a>
{:else if subNode.type === 'text' && subNode.particleProps}
<TextParticle {...TextParticleSpec.propsWithDefaults(merge({}, textDefaults, subNode.particleProps))} />
{/if}
{/if}
{/each}
{:else if node.type === 'image' && node.particleProps}
<ImageParticle _block={true} {...ImageParticleSpec.propsWithDefaults(merge({}, imageDefaults, node.particleProps))} />
{:else if node.type === 'gallery' && node.particleProps}
<GalleryCarouselParticle {...GalleryCarouselParticleSpec.propsWithDefaults(merge({}, galleryDefaults, node.particleProps))} />
{:else if node.type === 'video' && node.particleProps}
<VideoParticle {...VideoParticleSpec.propsWithDefaults(merge({}, videoDefaults, node.particleProps))} />
{:else if node.type === 'audio' && node.particleProps}
<AudioParticle {...AudioParticleSpec.propsWithDefaults(merge({}, audioDefaults, node.particleProps))} />
{:else if node.type === 'web-embed' && node.particleProps}
<WebEmbedParticle {...WebEmbedParticleSpec.propsWithDefaults(merge({}, webEmbedDefaults, node.particleProps))} />
{:else if node.type === 'html' && node.particleProps}
<RichTextParticle {...RichTextParticleSpec.propsWithDefaults(merge({}, htmlDefaults, node.particleProps))} />
{/if}
{/if}
