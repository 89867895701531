<Boundary> 
  <CardsContainerWrapper bind:wrapperEl={gridWrapper} style={`
    width: ${layout.width || '100%'};
    height: ${layout.height || '100%'};
    --row-spacing: ${gapRowPixels}px;
    --gutter-width-allowance: ${gapColumnPixels/2}px;
    --column-width-percent: ${100/maxColumns}%;
  `} {editorOptions}>
  {#each layout.elements as element, i (element.id)}
    <div class="grid-item"
      class:display-none={childContentAvailable[element.id] === false}
    >
      <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, i)} {element} bind:contentAvailable={childContentAvailable[element.id]} updater={elementUpdater.bind(null, i)} on:contentLoaded />
    </div>
  {/each}
  </CardsContainerWrapper>
</Boundary>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }

  .grid-item {
    float: left;
    width: calc(var(--column-width-percent) - var(--gutter-width-allowance));
    margin-bottom: var(--row-spacing);
  }</style>

<script lang="ts">import "yinzcam-cards";
import { generateSequenceId } from "../utilities";
import CardsElement from "./CardsElement.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import "./CardsEditorInterfaces";
import CardsContainerWrapper from "./CardsContainerWrapper.svelte";
import _ from "lodash";
import { onMount } from "svelte";
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';
export let parentSequenceId = "";
export let layout;
export let contentAvailable = true;
export let updater;
let childContentAvailable = {};
let gridWrapper;
$: {
    contentAvailable = Object.values(childContentAvailable).some((item) => item);
}
$: gapColumnPixels = (layout === null || layout === void 0 ? void 0 : layout.gapColumnPixels) || 0;
$: gapRowPixels = (layout === null || layout === void 0 ? void 0 : layout.gapRowPixels) || 0;
$: maxColumns = (layout === null || layout === void 0 ? void 0 : layout.maxColumns) || 1;
//$: console.log("DND LAYOUT UPDATED", layout);
function elementUpdater(i, element) {
    layout.elements[i] = element;
    updater(layout.elements);
}
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    items: layout.elements,
    updater
};
onMount(() => {
    // Create the masonry layout
    const masonry = new Masonry(gridWrapper, {
        itemSelector: '.grid-item',
        columnWidth: '.grid-item',
        percentPosition: true,
        gutter: gapColumnPixels
    });
    // Callback function to execute when changes happen that affect the masonry layout
    // 50ms debounce to eliminate jitter in masonry spread animation
    const callback = _.debounce(function () {
        masonry.layout();
    }, 50);
    // Observe DOM changes to rerender the masonry layout
    const observer = new MutationObserver(callback);
    observer.observe(gridWrapper, { attributes: true, childList: true, subtree: true });
    // Observe images loading to rerender the masonry layout
    const imgLoad = imagesLoaded(gridWrapper);
    imgLoad.on('progress', callback);
});
</script>
