<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { get, merge } from "lodash";
import { BaseAtom } from "components/cards/atoms";
import { ImageParticle, } from "components/cards/particles/YinzCam";
export let container;
export let overlayPadding;
export let backgroundImageDesktop;
export let backgroundImageMobile;
export let playerImage;
export let eventIcon;
export let colorPlayerBackground;
export let isHomeTeam;
export let teamDetails = undefined;
let atomWidthPx;
const eventIconNameMap = {
    Goals: "https://resources-uk.yinzcam.com/soccer/shared/soccer_ball.png",
    YellowCards: "https://resources-uk.yinzcam.com/soccer/shared/yellow_card.png",
    RedCards: "https://resources-uk.yinzcam.com/soccer/shared/red_card.png",
    SubbedOff: "https://resources-uk.yinzcam.com/soccer/shared/red_arrow.png",
    SubbedOn: "https://resources-uk.yinzcam.com/soccer/shared/green_arrow.png",
};
function handleImageError(event) {
    event.target.src =
        "https://resources-au.yinzcam.com/apl/shared/headshots/silhouette.png";
}
$: isMobile = atomWidthPx <= 500;
$: isTablet = atomWidthPx <= 800 && atomWidthPx > 500;
$: isBigScreen = atomWidthPx >= 1400;
$: playerImageMultiplier = isMobile ? "0.8" : isTablet ? "0.9" : isBigScreen ? "2.20" : "1.00";
$: eventIconMultiplier = isMobile ? "0.75" : isTablet ? "0.6" : isBigScreen ? "1.60" : "0.9";
//  class:mobile-layout={isMobile}
//     class:tablet-layout={isTablet}
</script>

<BaseAtom
    _isotope="MCLineupAtom"
    _cssVariablePrefix="mc-lineup"
    {...container}
    {overlayPadding}
    {colorPlayerBackground}
    bind:_clientWidthPx={atomWidthPx}
>
    <div
        class="main-container"
        class:mobile-layout={isMobile}
        class:tablet-layout={isTablet}
    >
        <div class="background-image">
            <!-- {#if !isMobile}
                <ImageParticle {...backgroundImageDesktop} />
            {/if}
            {#if isMobile} -->
                <ImageParticle {...backgroundImageMobile} _block={true}/>
            <!-- {/if} -->
        </div>
        <div class="overlay-area" class:away-team={!isHomeTeam}>
            {#each teamDetails || [] as rowData}
                <div class="player-row">
                    {#each rowData?.players || [] as rowElement}
                        <div class="player-image">
                            {#if rowElement?.subbedOffTimes?.length > 0}
                                <div class="top-left-sub-off">
                                    <ImageParticle
                                        {...merge({}, eventIcon, {
                                            container: {
                                                width: `calc(${get(eventIcon, "container.width")}*${eventIconMultiplier})`,
                                                height: `calc(${get(eventIcon, "container.height")}*${eventIconMultiplier})`,
                                            },
                                        })}
                                        source={eventIconNameMap["SubbedOff"]}
                                    />
                                </div>
                            {/if}
                            {#if rowElement?.subbedOnTimes?.length > 0}
                                <div class="top-left-sub-on">
                                    <ImageParticle
                                        {...merge({}, eventIcon, {
                                            container: {
                                                width: `calc(${get(eventIcon, "container.width")}*${eventIconMultiplier})`,
                                                height: `calc(${get(eventIcon, "container.height")}*${eventIconMultiplier})`,
                                            },
                                        })}
                                        source={eventIconNameMap["SubbedOn"]}
                                    />
                                </div>
                            {/if}
                            {#if rowElement?.yellowCardTimes?.length > 0}
                                <div class="top-right">
                                    <ImageParticle
                                        {...merge({}, eventIcon, {
                                            container: {
                                                width: `calc(${get(eventIcon, "container.width")}*${eventIconMultiplier})`,
                                                height: `calc(${get(eventIcon, "container.height")}*${eventIconMultiplier})`,
                                            },
                                        })}
                                        source={eventIconNameMap["YellowCards"]}
                                    />
                                </div>
                            {/if}
                            {#if rowElement?.redCardTimes?.length > 0}
                                <div class="top-right">
                                    <ImageParticle
                                        {...merge({}, eventIcon, {
                                            container: {
                                                width: `calc(${get(eventIcon, "container.width")}*${eventIconMultiplier})`,
                                                height: `calc(${get(eventIcon, "container.height")}*${eventIconMultiplier})`,
                                            },
                                        })}
                                        source={eventIconNameMap["RedCards"]}
                                    />
                                </div>
                            {/if}
                            {#if rowElement?.goalTimes?.length > 0}
                                <div class="bottom-right">
                                    <ImageParticle
                                        {...merge({}, eventIcon, {
                                            container: {
                                                width: `calc(${get(eventIcon, "container.width")}*${eventIconMultiplier})`,
                                                height: `calc(${get(eventIcon, "container.height")}*${eventIconMultiplier})`,
                                            },
                                        })}
                                        source={eventIconNameMap["Goals"]}
                                    />
                                </div>
                            {/if}
                            <div class="player-image-container">
                                <ImageParticle
                                    {...merge({}, playerImage, {
                                        container: {
                                            width: `calc(${get(playerImage?.container, "width")}*${playerImageMultiplier})`,
                                            height: `calc(${get(playerImage?.container, "height")}*${playerImageMultiplier})`,
                                        },
                                    })}
                                    source={rowElement?.playerImage}
                                    _block={true}
                                    {handleImageError}
                                />
                            </div>
                        </div>
                    {/each}
                </div>
            {/each}
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        width: 100%;
        display: grid;
        padding: var(--mc-lineup-overlay-padding, unset);
    }

    .main-container > * {
        grid-area: 1/1;
    }
    .overlay-area {
        z-index: 1;
        align-self: start;
        height: 90%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin-top: -2rem;
    }
    .overlay-area.away-team {
        flex-direction: column-reverse;
        justify-self: center;
        align-self: end;
    }
    .player-row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        gap: 1.5rem;
    }
    .player-image {
        position: relative;
    }
    .player-image-single {
        align-self: center;
    }
    .player-image-container {
        border-radius: 50%;
        background-color: var(
            --mc-lineup-color-player-background,
            var(--theme-mode-accent-color, gray)
        );
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .main-container.mobile-layout .overlay-area {
            z-index: 1;
            flex-direction: column;
            align-self: unset;
            height: 90%;
            width: 100%;
        }

    .main-container.mobile-layout .overlay-area .player-row {
                flex-direction: row;
                gap: 1.5rem;
            }

    .main-container.mobile-layout .overlay-area.away-team {
            flex-direction: column-reverse;
            justify-self: center;
            align-self: end;
        }

    .main-container.mobile-layout .top-left-sub-off {
            top: unset;
            left: unset;
        }

    .main-container.mobile-layout .top-right {
        top: -0.25rem;
    }

    .main-container.tablet-layout {
        width: 80%;
    }

    .main-container.tablet-layout .player-row {
            gap: 0;
        }
    .top-left-sub-off {
        position: absolute;
        top: 0.25rem;
        left: 0.25rem;
    }
    .top-left-sub-on {
        position: absolute;
        top: 0.6rem;
        left: 0.25rem;
    }
    .top-right {
        position: absolute;
        top: 0.25rem;
        right: 0.25rem;
    }
    .bottom-right {
        position: absolute;
        bottom: 0.3rem;
        right: 0.3rem;
    }</style>
