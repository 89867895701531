import { FromSchema } from 'json-schema-to-ts';
import { JSONSchemaType } from 'json-schema-to-ts/lib/types/definitions';
import { Narrow } from 'json-schema-to-ts/lib/types/type-utils';
import _ from 'lodash';
import { layerBackgroundSchema, layerBorderSchema, RecursivePartial, YinzCamCardsComponentProps, YinzCamCardsComponentSchema } from './common-schema';


export function generatePrimitiveSchema<A extends JSONSchemaType, B extends string, C extends string, D extends string, E extends FromSchema<{ type: Narrow<A> }>[], F extends string[], G extends FromSchema<{ type: Narrow<A> }>, H extends boolean, I extends boolean, J extends string>(
  type: Narrow<A>,
  title: Narrow<B>,
  description: Narrow<C>,
  options: { format?: Narrow<D>; choices?: Narrow<E>; choiceTitles?: Narrow<F>, defaultValue?: Narrow<G>, nullable?: Narrow<H>, allowUpload?: Narrow<I>, href?: Narrow<J>} = {}
) {
  const { format, choices, choiceTitles, defaultValue, nullable, allowUpload, href } = options;
  return {
    type,
    title,
    description,
    ...(!_.isUndefined(format) && { format }),
    ...(!_.isUndefined(choices) && { enum: choices }),
    ...(!_.isUndefined(defaultValue) && { default: defaultValue }),
    ...(!_.isUndefined(nullable) && { nullable }),
    options: {
      ...(!_.isUndefined(choiceTitles) && { enum_titles: choiceTitles }),
      ...(format === 'jodit' && { jodit: { disabled: false } }),
      ...(allowUpload && { upload: {} })
    },
    ...(!_.isUndefined(href) && { links: [ { href } ] }),
    additionalProperties: false,
  } as const;
}

export function generateBooleanSchema<A extends string, B extends string, C extends boolean, D extends boolean>(
  title: Narrow<A>,
  description: Narrow<B>,
  options: { defaultValue?: Narrow<C>, nullable?: Narrow<D> } = {}
) {
  return generatePrimitiveSchema("boolean", title, description, options);
}

export function generateNumberSchema<A extends string, B extends string, C extends number, D extends boolean>(
  title: Narrow<A>,
  description: Narrow<B>,
  options: { defaultValue?: Narrow<C>, nullable?: Narrow<D> } = {}
) {
  return generatePrimitiveSchema("number", title, description, options);
}

export function generateStringSchema<A extends string, B extends string, C extends string, D extends string[], E extends string[], F extends string, G extends boolean, H extends boolean, I extends string>(
  title: Narrow<A>,
  description: Narrow<B>,
  options: { format?: Narrow<C>; choices?: Narrow<D>; choiceTitles?: Narrow<E>, defaultValue?: Narrow<F>, nullable?: Narrow<G>, allowUpload?: Narrow<H>, href?: Narrow<I> } = {}
) {
  return generatePrimitiveSchema("string", title, description, options);
}

export function generateHtmlSchema<A extends string, B extends string, C extends string, D extends boolean>(
  title: Narrow<A>,
  description: Narrow<B>,
  options: { defaultValue?: Narrow<C>, nullable?: Narrow<D> } = {}
) {
  return generateStringSchema(title, description, { ...options, format: "jodit" });
}

export function generateUrlSchema<A extends string, B extends string, C extends string, D extends boolean>(
  title: Narrow<A>,
  description: Narrow<B>,
  options: { defaultValue?: Narrow<C>, nullable?: Narrow<D> } = {}
) {
  return generateStringSchema(title, description, { ...options, format: "url" });
}

export function generateMediaUrlSchema<A extends string, B extends string, C extends string, D extends boolean>(
  title: Narrow<A>,
  description: Narrow<B>,
  options: { defaultValue?: Narrow<C>, nullable?: Narrow<D> } = {}
) {
  return generateStringSchema(title, description, { ...options, format: "url", allowUpload: true, href: "{{self}}" })
}

export function generateColorSchema<A extends string, B extends string, C extends string, D extends boolean>(
  title: Narrow<A>,
  description: Narrow<B>,
  options: { defaultValue?: Narrow<C>, nullable?: Narrow<D> } = {}
) {
  return generateStringSchema(title, description, { ...options, /*format: "color"*/ });
}

export function generateDateTimeSchema<A extends string, B extends string, C extends string, D extends boolean>(
  title: Narrow<A>,
  description: Narrow<B>,
  options: { defaultValue?: Narrow<C>, nullable?: Narrow<D> } = {}
) {
  return generateStringSchema(title, description, { ...options, format: "date-time" });
}

export function generateDurationSchema<A extends string, B extends string, C extends string, D extends boolean>(
  title: Narrow<A>,
  description: Narrow<B>,
  options: { defaultValue?: Narrow<C>, nullable?: Narrow<D> } = {}
) {
  return generateStringSchema(title, description, { ...options, format: "duration" });
}

const languageNames = new Intl.DisplayNames(['en'], {
  type: 'language'
});
const langChoices = Object.values(CONFIG.supportedLanguages);
const langTitles = langChoices.map((c) => languageNames.of(c));
export function generateLanguageSchema<A extends string, B extends string, C extends boolean>(
  title: Narrow<A>,
  description: Narrow<B>,
  options: { nullable?: Narrow<C> } = {}
) {
  return generateStringSchema(title, description, { ...options,
    choices: langChoices,
    choiceTitles: langTitles,
  });
}

export function generateTranslationSchema<A extends string, B extends { [k: string]: C }, C extends YinzCamCardsComponentSchema>(
  objectName: Narrow<A>, properties: Narrow<B>
) {
  return {
    title: "Translations",
    description: `Translations for this ${objectName}.`,
    type: 'array',
    items: {
      title: "Translation",
      description: `A translation for this ${objectName as string}.`,
      type: 'object',
      properties: {
        language: generateLanguageSchema("Language", "The language for this translation."),
        ...(properties as B)
      },
      additionalProperties: false
    },
  } as const satisfies YinzCamCardsComponentSchema;
}

export function generateObjectSchema<A extends string, B extends string, C extends { [k: string]: D }, D extends YinzCamCardsComponentSchema>(
  title: Narrow<A>,
  description: Narrow<B>,
  properties: Narrow<C>,
  options: { propertyOrder?: string[], additionalProperties?: boolean } = {}
) {
  return {
    ...generateRootObjectSchema(properties, options),
    title,
    description
  } as const;
}

export function generateNamedObjectSchema<A extends string, B extends string, C extends YinzCamCardsComponentSchema>(
  title: Narrow<A>,
  description: Narrow<B>,
  rootObject: Narrow<C>
) {
  return {
    ...rootObject,
    title,
    description
  } as const satisfies YinzCamCardsComponentSchema;
}

export function generateRootObjectSchema<A extends { [k: string]: B }, B extends YinzCamCardsComponentSchema> (
  properties: Narrow<A>,
  options: { propertyOrder?: string[], additionalProperties?: boolean } = {}
) {
  const { propertyOrder, additionalProperties } = options;
  return {
    type: 'object',
    properties,
    ...(!_.isUndefined(propertyOrder) && { propertyOrder }),
    additionalProperties: additionalProperties || false,
  } as const;
}

export function generateArraySchema<A extends string, B extends string, C extends YinzCamCardsComponentSchema>(
  title: Narrow<A>,
  description: Narrow<B>,
  itemSchema: Narrow<C>
) {
  return {
    title,
    description,
    type: 'array',
    items: itemSchema
  } as const satisfies YinzCamCardsComponentSchema;
}

export function generateBorderSchema<A extends string, B extends string>(
  title: Narrow<A>,
  description: Narrow<B>
) {
  return generateObjectSchema(title, description, {
    visible: generateBooleanSchema("Visible", "The visibility of the border.", {
      defaultValue: false
    }),
    width: generateStringSchema("Width", "The width of the border in standard CSS units."),
    style: generateStringSchema("Style", "The style of the border.", {
      choices: [ 'none', 'solid', 'dotted', 'dashed', 'double', 'groove', 'ridge', 'inset', 'outset', 'hidden' ],
      choiceTitles: [ 'None', 'Solid', 'Dotted', 'Dashed', 'Double', '3D Groove', '3D Ridge', '3D Inset', '3D Outset', 'Hidden' ],
      defaultValue: 'none',
    }),
    color: generateColorSchema("Color", "The color of the border."),
    radius: generateStringSchema("Radius", "The radius of the corners of the border in standard CSS units."),
  })
}

export function generateBackgroundSchema<A extends string, B extends string>(
  title: Narrow<A>,
  description: Narrow<B>
) {
  return generateObjectSchema(title, description, {
    visible: generateBooleanSchema("Visible", "The visibility of the background.", {
      defaultValue: false
    }),
    color: generateColorSchema("Color", "The solid color of the background. Defaults to the current theme mode's background color."),
    colorLightness: generateNumberSchema("Color Lightness Modifier", "The percentage value to modify the lightness of the background color. The percentage is calculated using the CIELAB color space."),
    useHighlightColor: generateBooleanSchema("Use Highlight Color", "Use the current theme mode's highlight color for the background, instead of the theme mode's background color."),
    image: generateMediaUrlSchema("Image", "The image to use as the background. Defaults to no image."),
    size: generateStringSchema("Size", "The sizing mode for the background image.", {
      choices: [ 'auto', 'cover', 'contain' ],
      choiceTitles: [ 'Auto', 'Cover', 'Contain' ],
      defaultValue: 'cover',
    }),
    attachment: generateStringSchema("Attachment", "Whether the background scrolls with content or is fixed (static).", {
      choices: [ 'scroll', 'fixed' ],
      choiceTitles: [ 'Scroll', 'Fixed' ],
      defaultValue: 'scroll',
    }),
    horizontalAlignment: generateStringSchema("Horizontal Alignment", "The horizontal alignment of the background image relative to its container.", {
      choices: [ 'left', 'center', 'right' ],
      choiceTitles: [ 'Left', 'Center', 'Right' ],
      defaultValue: 'center',
    }),
    verticalAlignment: generateStringSchema("Vertical Alignment", "The vertical alignment of the background image relative to its container.", {
      choices: [ 'top', 'center', 'bottom' ],
      choiceTitles: [ 'Top', 'Center', 'Bottom' ],
      defaultValue: 'center',
    }),
    repeat: generateStringSchema("Repeat", "Whether the background repeats if the container is larger than the background image.", {
      choices: [ 'no-repeat', 'repeat', 'repeat-x', 'repeat-y', 'space', 'round' ],
      choiceTitles: [ 'No Repeat', 'Repeat', 'Repeat Horizontally', 'Repeat Vertically', 'Fit Without Clipping', 'Stretch to Cover' ],
      defaultValue: 'no-repeat',
    }),
    clip: generateStringSchema("Clipping", "Whether the background is constrained to the border (entire container), padding (entire container except the border), just the content, or just the text.", {
      choices: [ 'border-box', 'padding-box', 'content-box', 'text' ],
      choiceTitles: [ 'Border', 'Padding', 'Content', 'Text' ],
      defaultValue: 'border-box',
    }),
    filter: generateStringSchema("Filter", "The filter(s) to apply to the background as a CSS filter string."),
  });
}

const CONTAINER_ACTION_SCHEMA = generateObjectSchema("Action", "A single action.", {
  trigger: generateStringSchema("Trigger", "The trigger for the action.", {
    choices: [ 'click' ],
    choiceTitles: [ "Click" ],
  }),
  effect: generateStringSchema("Effect", "The effect to produce when the trigger fires.", {
    choices: [ 'navigate', 'navigate-back', 'carousel-left', 'carousel-right', 'hide', 'show', 'set-variable', 'clear-variable', 'set-background', 'set-border', 'add-repeats', 'remove-repeats' ],
    choiceTitles: [ "Navigate to Page", "Navigate Back", "Carousel Left", "Carousel Right", "Hide Layer", "Show Layer", "Set Variable", "Clear Variable", "Set Background", "Set Border", "Add Repeats", "Remove Repeats" ],
  }),
  forwardToChildren: generateBooleanSchema("Forward to Children", "Forward this action to all of the target's children, rather than the target itself.", { defaultValue: false }),
  target: generateStringSchema("Target", "The target of the action. This depends on the action, but is usually a Layer ID."),
  navigate: generateObjectSchema("Navigate", "The options for navigate effects.", {
    link: generateStringSchema("Link", "The link to navigate to when this effect is triggered."),
  }),
  variable: generateObjectSchema("Variable", "The options for set-variable effects.", {
    name: generateStringSchema("Name", "The variable name. This is what is placed between curly braces: {{ ... }}"),
    value: generateStringSchema("Value", "The value to set on the variable."),
  }),
  layerBackground: { ...layerBackgroundSchema, title: "Layer Background" },
  layerBorder: { ...layerBorderSchema, title: "Layer Border" },
  repeats: generateNumberSchema("Repeats", "The number of repeats to add or remove."),
});

export type ContainerActionProps = YinzCamCardsComponentProps<typeof CONTAINER_ACTION_SCHEMA>;

const CONTAINER_COMMON_SCHEMA = {
  actions: generateArraySchema("Actions", "The actions to perform when the user interacts with this container.", CONTAINER_ACTION_SCHEMA),
  background: generateBackgroundSchema("Background", "The container's background color, sizing, positioning, and image if specified in standard CSS units."),
  border: generateBorderSchema("Border", "The container's border width, style, color, and radius in standard CSS units."),
  width: generateStringSchema("Fixed Width", "The fixed width of this container in standard CSS units. If this is specified, the Fill Width property has no effect."),
  height: generateStringSchema("Fixed Height", "The fixed height of this container in standard CSS units. If this is specified, the Fill Height property has no effect."),
  aspectRatio: generateStringSchema("Fixed Aspect Ratio", "Set a fixed aspect ratio for the container."),
  maxWidth: generateStringSchema("Max Width", "The maximum width of this container in standard CSS units. Does not apply to inline elements."),
  maxHeight: generateStringSchema("Max Height", "The maximum height of this container in standard CSS units. Does not apply to inline elements."),
  hidden: generateBooleanSchema("Hidden", "Whether this container and its contents should be hidden.", {
    defaultValue: false
  }),
  margin: generateStringSchema("Margin", "The dimension(s) of the container margins (outside border) in standard CSS units."),
  padding: generateStringSchema("Padding", "The dimension(s) of the container padding (inside border) in standard CSS units."),
  themeMode: generateStringSchema("Theme Mode", "The theme mode (e.g. inverted) to use for this container. This mode is inherited by nested containers (the default) unless overriden on those containers.", {
    choices: Object.values(CONFIG.themeModes),
    choiceTitles: Object.values(CONFIG.themeModeTitles)
  }),
  filter: generateStringSchema("Filter", "The filter(s) to apply to the container contents as a CSS filter string."),
  overflow: generateStringSchema("Overflow", "Whether content that overflows the size of this container should be hidden or visible on the screen.", {
    choices: [ 'visible', 'hidden', 'scroll', 'auto' ],
    choiceTitles: [ 'Visible', 'Hidden', 'Scroll (Always Show Scrollbars)', 'Scroll (Automatic Scrollbars)' ],
  }),
  boxShadow: generateStringSchema("Box Shadow", "The box shadow to apply to the container using CSS syntax."),
  hideOnChildrenPages: generateBooleanSchema("Hide on Children Pages", ""),
  hideOnChildrenPagesVisibility: generateBooleanSchema("Hide on Children Pages - Reserve Space", ""),
}

export function generateContainerSchema<A extends string, B extends string>(
  title: Narrow<A>,
  description: Narrow<B>
) {
  return generateObjectSchema(title, description, {
    centerContentHorizontally: generateBooleanSchema("Center Content Horizontally", "Whether this container should center its contents horizontally. This is the default behavior for block containers and does not apply to inline containers. Note that this only has an effect if child containers do not fill the width of their parent.", {
      defaultValue: true
    }),
    centerContentVertically: generateBooleanSchema("Center Content Vertically", "Whether this container should center its contents vertially. This is the default behavior for block containers and does not apply to inline containers. Note that this only has an effect if child containers do not fill the width of their parent.", {
      defaultValue: true
    }),
    fillWidth: generateBooleanSchema("Fill Width", "Whether this container should stretch to fill the width of its parent's container. This is the default behavior for block containers and does not apply to inline containers. If Fixed Width is specified, this property has no effect.", {
      defaultValue: true
    }),
    fillHeight: generateBooleanSchema("Fill Height", "Whether this container should stretch to fill the height of its parent's container. This is the default behavior for block containers and does not apply to inline containers. If Fixed Height is specified, this property has no effect.", {
      defaultValue: true
    }),
    ...CONTAINER_COMMON_SCHEMA
  });
}

export function generateGenericContainerSchema<A extends string, B extends string>(
  title: Narrow<A>,
  description: Narrow<B>
) {
  return generateObjectSchema(title, description, {
    layoutMode: generateStringSchema("Layout Mode", "The container's layout mode.", {
      choices: [ 'block', 'flex', 'grid', 'inline' ],
      choiceTitles: [ 'Block', 'Flexbox', 'Grid', 'Inline' ],
      defaultValue: 'block'
    }),
    flex: generateObjectSchema("Flexbox", "Flexbox properties for this container.", {
      direction: generateStringSchema("Direction", "The direction of content layout in the flexbox.", {
        choices: [ 'row', 'column', 'row-reverse', 'column-reverse' ],
        choiceTitles: [ "Row", "Column", "Row Reverse", "Column Reverse" ],
        defaultValue: 'row'
      }),
      alignItems: generateStringSchema("Alignment", "Alignment of items above/center/below (row) or left/center/right (column).", {
        choices: [ 'normal', 'start', 'center', 'end' ],
        choiceTitles: [ "Default", "Above or Left", "Center", "Below or Right" ],
        defaultValue: 'normal'
      }),
      justifyContent: generateStringSchema("Justification", "Justification of content left/center/right (row) or above/center/below (column).", {
        choices: [ 'normal', 'start', 'center', 'end' ],
        choiceTitles: [ "Default", "Left or Above", "Center", "Right or Below" ],
        defaultValue: 'normal'
      }),
      gap: generateStringSchema("Gap", "The gap between items within this container in standard CSS units."),
    }),
    ...CONTAINER_COMMON_SCHEMA
  });
}

export type ContainerSchema = ReturnType<typeof generateContainerSchema>;

export type ContainerProps = YinzCamCardsComponentProps<ContainerSchema>;

export type RecursiveBooleanified<T> = T extends object ? {
  [K in keyof T]: RecursiveBooleanified<T[K]>
} : boolean;

export function applyAnnotationsToSchema<T extends YinzCamCardsComponentSchema, U = YinzCamCardsComponentProps<T>, V = RecursivePartial<U>, W = RecursiveBooleanified<V>>(schema: Narrow<T>, defaultValues?: V, uneditableFields?: W): Narrow<T> {
  if (schema.type === 'object') {
    const propsWithAnnotations = {};
    for (const k of Object.keys(schema.properties || {})) {
      const propSchema = schema.properties[k];
      propsWithAnnotations[k] = (_.isObject(propSchema))? applyAnnotationsToSchema(propSchema, defaultValues?.[k], uneditableFields?.[k]) : propSchema;
    }
    return {
      ...schema,
      properties: propsWithAnnotations as typeof schema.properties
    } as const;
  } else {
    return {
      ...schema,
      ...(!_.isUndefined(defaultValues) && !_.isObject(defaultValues) && { default: defaultValues }),
      ...(uneditableFields === true && { uneditable: true }),
    } as const;
  }
}

export function applyDefaultsToPropsRecursive<A extends YinzCamCardsComponentSchema, B = YinzCamCardsComponentProps<A>, C = RecursivePartial<B>>(schema?: boolean | A, props?: C): C {
  if (!_.isObject(schema)) {
    return props;
  }
  if (schema.type === 'object') {
    if (_.isUndefined(props)) {
      props = {} as C;
    }
    if (!_.isPlainObject(props)) {
      throw new Error('value is not a plain object');
    }
    for (const key in (schema.properties || {})) {
      try {
        props[key] = applyDefaultsToPropsRecursive(schema.properties?.[key], props[key]);
      } catch (e) {
        console.warn(`propsWithDefaultsHelper unable to map property ${key}`, e)
      }
    }
    return props;
  } else if (schema.type === 'array') {
    if (_.isUndefined(props)) {
      props = [] as C;
    }
    if (!_.isArray(props)) {
      throw new Error('value is not an array');
    }
    if (_.isArrayLike(schema.items)) {
      throw new Error('array items schema is not an object');
    }
    for (let i = 0; i < props.length; i++){
      try {
        props[i] = applyDefaultsToPropsRecursive(schema.items, props[i]);
      } catch (e) {
        console.warn(`propsWithDefaultsHelper unable to map array index ${i}`, e)
      }
    }
    return props;
  } else {
    // assumed primitive type
    return (_.isUndefined(props))? schema.default as C : props;
  }
}
