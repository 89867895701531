<script context="module" lang="ts">var _a;
export { specification } from "./spec";
</script>

<script lang="ts">var _a;
import { get } from "lodash";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, } from "components/cards/particles/YinzCam";
export let container;
export let containerPadding;
export let matchStats = undefined;
export let statsList;
export let txtPossessionText;
export let txtHomeTeamPercent;
export let txtAwayTeamPercent;
export let imgHomeTeamLogo;
export let imgAwayTeamLogo;
export let imgFieldMap;
export let myTeamColor;
export let opponentTeamColor;
export let homeTricode;
export let awayTricode;
let atomWidthRem;
let homeTeamColor = "", awayTeamColor = "";
$: isMobile = atomWidthRem <= 30;
$: txtTextMultiplier = isMobile ? "0.75" : "1.00";
$: selectedStat = matchStats === null || matchStats === void 0 ? void 0 : matchStats.find((stat) => !(stat === null || stat === void 0 ? void 0 : stat.statName.localeCompare(statsList)));
$: homeTeamPercent = `${Math.round((parseInt(selectedStat === null || selectedStat === void 0 ? void 0 : selectedStat.homeValue) / parseInt(selectedStat === null || selectedStat === void 0 ? void 0 : selectedStat.maxValue)) * 100) || 0}%`;
// $: awayTeamPercent = `${Math.floor((parseInt(selectedStat?.awayValue) / parseInt(selectedStat?.maxValue))*100) || 0}%`;
$: awayTeamPercent = `${100 - parseInt(homeTeamPercent)}%`;
$: if ((homeTricode === null || homeTricode === void 0 ? void 0 : homeTricode.toLowerCase()) === ((_a = CONFIG === null || CONFIG === void 0 ? void 0 : CONFIG.tricode) === null || _a === void 0 ? void 0 : _a.toLowerCase())) {
    homeTeamColor = myTeamColor;
    awayTeamColor = opponentTeamColor;
}
else {
    homeTeamColor = opponentTeamColor;
    awayTeamColor = myTeamColor;
}
</script>

<BaseAtom
    bind:_clientWidthRem={atomWidthRem}
    _isotope="MCPossessionTracker"
    _cssVariablePrefix="mc-possession"
    {...container}
    {containerPadding}
    {homeTeamPercent}
    {awayTeamPercent}
    {homeTeamColor}
    {awayTeamColor}
>
    <div class="main-container" class:mobile-layout={isMobile}>
        <div class="heading">
            <div class="possession-text">
                <TextParticle
                    {...txtPossessionText}
                    size={`calc(${get(txtPossessionText, "size")}*${txtTextMultiplier})`}
                    text= {selectedStat?.statName || txtPossessionText?.text}
                />
            </div>
            {#if !isMobile}
                <div class="team-possession-stat">
                    <div class="home-team">
                        <ImageParticle {...imgHomeTeamLogo} _block={true} />
                        <TextParticle
                            {...txtHomeTeamPercent}
                            size={`calc(${get(txtHomeTeamPercent, "size")}*${txtTextMultiplier})`}
                            text={`${homeTeamPercent}`}
                        />
                    </div>
                    <div class="away-team">
                        <TextParticle
                            {...txtAwayTeamPercent}
                            size={`calc(${get(txtAwayTeamPercent, "size")}*${txtTextMultiplier})`}
                            text={`${awayTeamPercent}`}
                        />
                        <ImageParticle {...imgAwayTeamLogo} _block={true} />
                    </div>
                </div>
            {/if}
        </div>
        <div class="field">
            <div class="field-image">
                <ImageParticle {...imgFieldMap} _block={true}/>
            </div>
            <div class="home-team-percent"></div>
            <div class="away-team-percent"></div>
        </div>
        {#if isMobile}
            <div class="team-possession-stat">
                <div class="home-team">
                    <ImageParticle {...imgHomeTeamLogo} _block={true} />
                    <TextParticle
                        {...txtHomeTeamPercent}
                        size={`calc(${get(txtAwayTeamPercent, "size")}*${txtTextMultiplier})`}
                        text={`${homeTeamPercent}`}
                    />
                </div>
                <div class="away-team">
                    <TextParticle
                        {...txtAwayTeamPercent}
                        size={`calc(${get(txtAwayTeamPercent, "size")}*${txtTextMultiplier})`}
                        text={`${awayTeamPercent}`}
                    />
                    <ImageParticle {...imgAwayTeamLogo} _block={true} />
                </div>
            </div>
        {/if}
    </div>
</BaseAtom>

<style>
    .main-container {
        display: flex;
        flex-direction: column;
        padding: var(--mc-possession-container-padding, unset);
        gap: 1rem;
        width: 100%;
    }

    .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .team-possession-stat {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
    }
    .team-possession-stat .home-team {
            display: flex;
            align-items: center;
        }
    .team-possession-stat .away-team {
            display: flex;
            align-items: center;
        }

    .main-container.mobile-layout .team-possession-stat {
            width: 100%;
            align-items: center;
            justify-content: space-between;
        }
    .field {
        display: grid;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    .field > * {
        grid-area: 1/1;
    }
    .home-team-percent{
        align-self: left;
        justify-self: left;
        place-self: left;
        width: var(--mc-possession-home-team-percent, unset);
        border-right: 0.5rem solid #FFF;
        background-color: var(--mc-possession-home-team-color);
        opacity: 0.75;
    }
    .away-team-percent{
        align-self: right;
        justify-self: right;
        place-self: right;
        width: var(--mc-possession-away-team-percent, unset);
        border-left: 0.5rem solid #FFF;
        background-color: var(--mc-possession-away-team-color);
        opacity: 0.75;
    }</style>
