import { generateArraySchema, generateBooleanSchema, generateColorSchema, generateDateTimeSchema, generateMediaUrlSchema, generateObjectSchema, generateRootObjectSchema, generateStringSchema, generateTranslationSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseParticleSpec, GetParticlePropsType, GetParticleSchemaType, generateParticleContainerSchema } from "components/cards/particles";
import { AuthorParticleSpec, ImageParticleSpec, ShareStripParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

export const GalleryCarouselParticleSpec = new BaseParticleSpec("Gallery Carousel", generateRootObjectSchema({
  container: generateParticleContainerSchema(),
  publishDateTime: generateDateTimeSchema("Publish Date and Time", "The date and time that this content was published."),
  carouselPositionText: TextParticleSpec.generateSchema("Carousel Position Text", "The styling for the dynamic carousel position text.", {
    defaultValues: { size: '1rem', weight: '600', container: { padding: '0.75rem' } },
    uneditableFields: { text: true },
  }),
  photoTitleText: TextParticleSpec.generateSchema("Title Text", "The styling for the dynamic photo title text.", {
    defaultValues: { size: '1.25rem', weight: '700' },
    uneditableFields: { text: true },
  }),
  photoDescriptionText: TextParticleSpec.generateSchema("Description Text", "The styling for the dynamic photo description text.", {
    defaultValues: { size: '1rem', weight: '400' },
    uneditableFields: { text: true },
  }),
  currentPhoto: ImageParticleSpec.generateSchema("Current Photo", "The styling for the current photo display.", {
    defaultValues: { sizing: 'contain' },
    uneditableFields: { source: true, icon: true }
  }),
  carouselPhoto: ImageParticleSpec.generateSchema("Carousel Photo", "The styling for the photo thumbnails within the photo carousel.", {
    defaultValues: { container: { height: '6rem', width: 'max-content' }, sizing: 'contain' },
    uneditableFields: { source: true, icon: true }
  }),
  photos: generateArraySchema("Photos List", "The list of photos to display.",
    generateObjectSchema("Photo", "A photo within the photo gallery.", {
      source: generateUrlSchema("Image Source", "The URL of the photo image."),
      title: generateStringSchema("Image Title", "A title for the photo."),
      description: generateStringSchema("Image Description", "A description for the photo.")
    })
  ),
}));

export const specification = GalleryCarouselParticleSpec;

export type GalleryCarouselParticleSchema = GetParticleSchemaType<typeof GalleryCarouselParticleSpec>;

export type GalleryCarouselParticleProps = GetParticlePropsType<typeof GalleryCarouselParticleSpec>;
