<YCPage query={pageQuery} bind:clearPreviousPage={clearPreviousPage}>
  {#if !isLoginRedirect && (!hideHeaderInAppMode || pageQuery[appQSParam] !== "true")}
  <CardsPage {listName} {pathParams} {pageQuery} {pageData} on:contentLoaded={() => clearPreviousPage()} />
  {/if}
</YCPage>

<style></style>

<script lang='ts'>import YCPage from '../components/YCPage.svelte';
import CardsPage from '../components/cards/common/CardsPage.svelte';
import { getHeaderPageData } from '../headers';
import { writable } from "svelte/store";
import '../js/utilities';
const isLoginRedirect = CONFIG.ssoOAuthRedirectPath === window.location.pathname;
const hideHeaderInAppMode = CONFIG.hideHeaderInAppMode;
const appQSParam = CONFIG.appModeQueryStringParameter;
export let f7route;
let clearPreviousPage;
const listName = f7route.params.listName;
const pathParams = (f7route.params.path1) ? [f7route.params.path1] : undefined;
const pageQuery = f7route.query;
const pageData = writable(getHeaderPageData());
</script>
