<Boundary>
  <CardsContainerWrapper style="
    width: {layout.width || '100%'};
    height: {layout.height || 'auto'};
    min-height: 2rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    flex-direction:{flowDirection};
    " {editorOptions}>
    {#each layout.elements as element, i (element.id)}
    <Link class="waistband-tab" tabLink="#{element.data.targetTabID}" tabLinkActive={i === defaultTabIndex}>
        <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, 0)} {element} updater={elementUpdater.bind(null, i)} on:contentLoaded />
    </Link>
    {/each}
  </CardsContainerWrapper>
</Boundary>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }</style>

<script lang="ts">import "yinzcam-cards";
//import { expandRepeats } from '../utilities';
import CardsElement from "./CardsElement.svelte";
import { Link } from 'framework7-svelte';
import { generateSequenceId } from "../utilities";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import "./CardsEditorInterfaces";
import CardsContainerWrapper from "./CardsContainerWrapper.svelte";
export let parentSequenceId = "";
export let layout;
export let updater;
let flowDirection = 'row';
let findDefaultTab = function (elementArray) {
    for (let i = 0; i < elementArray.length; i++) {
        if (elementArray[i].data.defaultTab) {
            return i;
        }
    }
    return 0;
};
//let expandedElements: YinzCamCardsServiceElement[];
let defaultTabIndex = 0;
let foundDefaultTab = false;
$: if (layout) {
    //expandedElements = expandRepeats(layout.elements);
    if (layout.direction.toLowerCase() === 'vertical') {
        flowDirection = 'column';
    }
    else if (layout.direction.toLowerCase() === 'horizontal') {
        flowDirection = 'row';
    }
    if (!foundDefaultTab) {
        //defaultTabIndex = findDefaultTab(expandedElements);
        defaultTabIndex = findDefaultTab(layout.elements);
        foundDefaultTab = true;
    }
}
function elementUpdater(i, element) {
    layout.elements[i] = element;
    updater(layout.elements);
}
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    items: layout.elements,
    updater
};
</script>
