import { generateRootObjectSchema, generateArraySchema, generateObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { AudioParticleSpec, ButtonParticleSpec, CaptionedParticleSpec, ImageParticleSpec, RichTextParticleSpec, TextParticleSpec, VideoParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Leicester Footer Atom", generateRootObjectSchema({
    container: generateAtomContainerSchema({ defaultValues: { themeMode: 'primary' } }),
    bottomRowPadding: generateStringSchema("Bottom Row Padding", "Bottom Row Padding"),
    cardBackgroundColor: generateStringSchema("Card Background Color", "Card Background Color", { defaultValue: "#164194" }),
    btnLargeSponsor: generateArraySchema("Sponsors - Large", "Sponsors - Large",
        ButtonParticleSpec.generateSchema("Sponsor Icon - Large", "Sponsor Icon - Large", {
            defaultValues: { image: { container: { height: "6.5rem" }, sizing: "contain" } }
        })),
    btnSmallSponsor: generateArraySchema("Sponsors - Small", "Sponsors - Small",
        ButtonParticleSpec.generateSchema("Sponsor Icon - Small", "Sponsor Icon - Small", {
            defaultValues: { image: { container: { height: "5.5rem" }, sizing: "contain" } }
        })),
    btnXtraSmallSponsor: generateArraySchema("Sponsors - Xtra Small", "Sponsors - Xtra Small",
        ButtonParticleSpec.generateSchema("Sponsor Icon - XSmall", "Sponsor Icon - XSmall", {
            defaultValues: { image: { container: { height: "5rem" }, sizing: "contain" } }
        })),
    btnXtraSmall2Sponsor: generateArraySchema("Sponsors - Xtra Small 2", "Sponsors - Xtra Small 2",
        ButtonParticleSpec.generateSchema("Sponsor Icon - XSmall2", "Sponsor Icon - XSmall2", {
            defaultValues: { image: { container: { height: "5rem" }, sizing: "contain" } }
        })),
    imgDivider: ImageParticleSpec.generateSchema("Divider Image", "Divider Image"),
    imgTeamLogo: ImageParticleSpec.generateSchema("Team Logo", "Team Logo", {
        defaultValues: { container: { width: "5rem", height: "5rem" }, sizing: "cover" }
    }),
    txtUsefulLinks: TextParticleSpec.generateSchema("Useful Links", "Useful Links", {
        defaultValues: { size: "1rem", family: "LCFC Sans", color: "#FFF", weight: "400", text: "Useful Links" }
    }),
    btnUsefulLinksArray: generateArraySchema("Useful Links Array", "Useful Links Array",
        ButtonParticleSpec.generateSchema("Useful Links", "Useful Links", {
            defaultValues: {
                container: { fillWidth: false, width: "unset", centerContentHorizontally: false, centerContentVertically: false },
                text: {
                    size: "1rem", family: "LCFC Sans", color: "#FFF", weight: "800", container: { centerContentHorizontally: false, centerContentVertically: false }
                },
                showImage: false,
            }
        })
    ),
    txtFindUs: TextParticleSpec.generateSchema("Find Us", "Find Us", {
        defaultValues: { size: "1rem", family: "LCFC Sans", color: "#FFF", weight: "400", text: "Find Us" }
    }),
    btnFindUsArray: generateArraySchema("Find Us Array", "Find Us Array",
        ButtonParticleSpec.generateSchema("Find Us", "Find Us", {
            defaultValues: {
                container: { fillWidth: false, width: "unset", centerContentHorizontally: false, centerContentVertically: false },
                text: {
                    size: "1rem", wrap: "balance", align: "left", family: "LCFC Sans", color: "#FFF", weight: "800", container: { centerContentHorizontally: false, centerContentVertically: false }
                },
                showImage: false,
            }
        })
    ),
    txtFollowUs: TextParticleSpec.generateSchema("Follow Us", "Follow Us", {
        defaultValues: { size: "1rem", family: "LCFC Sans", color: "#FFF", weight: "400", text: "Follow Us" }
    }),
    txtFollowUsArray: generateArraySchema("Follow Us Array", "Follow Us Array",
        TextParticleSpec.generateSchema("Follow Us", "Follow Us", {
            defaultValues: {
                // container: { fillWidth: false, width: "unset", centerContentHorizontally: false, centerContentVertically: false },
                size: "1rem", family: "LCFC Sans", color: "#FFF", weight: "800"
            }
        })
    ),
    btnClubSocialIcons: generateArraySchema("Club Social Icons Array", "Club Social Icons Array",
        ButtonParticleSpec.generateSchema("Club Social Icons", "Club Social Icons", {
            defaultValues: {
                container: { width: "2rem", border: { visible: true, radius: "50%" } },
                showText: false,
            }
        })
    ),
    btnMensSocialIcons: generateArraySchema("Men's Social Icons Array", "Men's Social Icons Array",
        ButtonParticleSpec.generateSchema("Men's Social Icons", "Men's Social Icons", {
            defaultValues: {
                container: { width: "2rem", border: { visible: true, radius: "50%" } },
                showText: false,
            }
        })
    ),
    btnWomensSocialIcons: generateArraySchema("Women's Social Icons Array", "Women's Social Icons Array",
        ButtonParticleSpec.generateSchema("Women's Social Icons", "Women's Social Icons", {
            defaultValues: {
                container: { width: "2rem", border: { visible: true, radius: "50%" } },
                showText: false,
            }
        })
    ),
    btnAppStoreIcons: generateArraySchema("App Store Icons Array", "App Store Icons Array",
        ButtonParticleSpec.generateSchema("App Store Icons", "App Store Icons", {
            defaultValues: {
                image: { container: { height: "4rem" }, sizing: "contain" },
                showText: false,
            }
        })
    ),
    btnBoilerplateLinks: generateArraySchema("Boilerplate Link Array", "Boilerplate Link Array",
        ButtonParticleSpec.generateSchema("Boilerplate Link", "Boilerplate Link", {
            defaultValues: {
                showImage: false,
                justification: "left",
                text: {
                    size: "1rem", family: "Fira Sans", weight: "300", color: "#FFF",
                    container: {
                        centerContentHorizontally: false, centerContentVertically: false
                    }
                }
            }
        })
    ),
}));
