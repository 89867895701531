export let redirects = {};

if (CONFIG.league === 'fa' && CONFIG.tricode === 'bha') {
  redirects = {
    "/club-info/club-partners": "club-partners",
    "/renew": "https://tickets.brightonandhovealbion.com/subscriptions",
    "/cashless": "fans-matchday-at-the-amex",
    "/seasonticket": "https://tickets.brightonandhovealbion.com/subscriptions",
    "/albion-as-one": "albion-as-one",
    "/contact": "contact-us",
    "/membership": "https://tickets.brightonandhovealbion.com/",
    "/1901": "the-1901-club",
    "/1901renewal": "1901-club-events",
    "/privacy-policy": "privacy-policy",
    "/hospitality/1901-club/existing-member": "1901-club-events",
    "/seasontickets": "https://tickets.brightonandhovealbion.com/subscriptions",
    "/st": "https://tickets.brightonandhovealbion.com/subscriptions",
    "/soccerschools": "https://bookings.albioninthecommunity.org.uk/soccerschools/courses/courses.htm?coursesSearch=search&typeId=78",
    "/resale": "https://tickets.brightonandhovealbion.com/screenloader.aspx?type=include&page=usercontent/documents/html/resale.html",
    "/ssopentimes": "https://tickets.brightonandhovealbion.com/screenloader.aspx?type=include&page=usercontent/documents/html/openingtimes.html",
    "/resubscribe": "https://tickets.brightonandhovealbion.com/screenloader.aspx?type=include&page=usercontent/documents/html/re-subscribe.html",
    "/1901tix": "https://tickets.brightonandhovealbion.com/screenloader.aspx?type=include&page=usercontent/documents/html/1901tickethelp.html",
    "/hospitality-guide-202122": "https://resources.brightonandhovealbion.com/bhafc/document/2021/10/27/4afc35c4-5ef0-4291-af11-8d6e20811502/MatchdayHospitalityGuide.pdf",
    "/pl-home-tickets": "https://tickets.brightonandhovealbion.com/en-gb/categories/home",
    "/matchdayroles": "https://www.brightonandhovealbion.com/club/club/jobs/matchday-roles",
    "/tunnel": "https://thetunnelclub.brightonandhovealbion.com/",
    "/club/stadiums/the-amex-stadium/fans-guide": "fans-matchday-at-the-amex",
    "/help": "https://tickets.brightonandhovealbion.com/screenloader.aspx?type=include&page=usercontent/documents/html/askalbion.html",
    "/fab": "fans-fan-advisory-board",
    "/christmas": "https://www.eventsatbhafc.co.uk/christmas-parties",
    "/myplanet": "environmental-sustainability",
    "/club/club/jobs": "career-opportunities",
    "/club/stadiums/the-amex-stadium": "https://www.eventsatbhafc.co.uk/",
    "/club/matchday-hospitality": "matchday-hospitality",
    "/club/matchday-hospitality/matchday-menus-and-guide": "https://downloads.ctfassets.net/rrpvignygaa1/3lST221zCnsHM3PhOvqKfr/74462d6ab11543e615db8c862c510fc3/65481_BHA-_Hospitality_Guide_2024_v3.pdf",
    "/hospitality/matchday-sponsorship/matchday-sponsor": "matchday-sponsors",
    "/club/club/accessibility": "disability-and-accessibility",
    "/club/club/brand-protection": "policies-and-reports",
    "/club/club/careers": "careers",
    "/club/club/club-charter": "policies-and-reports",
    "/club/club/company-details": "company-details",
    "/club/club/contact-us": "contact-us",
    "/club/club/disability": "disability-and-accessibility",
    "/club/club/environmental-statement": "environmental-sustainability",
    "/club/club/equality-and-diversity": "equality-diversity-and-inclusions",
    "/club/club/gender-pay-gap": "policies-and-reports",
    "/club/club/media-enquiries": "media-enquiries",
    "/club/club/modern-slavery": "policies-and-reports",
    "/club/club/myplanet": "environmental-sustainability",
    "/club/club/partners": "partner-and-sponsors",
    "/club/club/privacy-policy": "privacy-policy",
    "/club/club/safeguarding-information": "safeguarding-policy",
    "/club/club/terms-of-use": "terms-of-use",
    "/club/club/whos-who": "club-whos-who",
    "/club/club/disability/audio-description": "disability-and-accessibility",
    "/club/club/disability/hidden-disability-wristband": "disability-and-accessibility",
    "/club/club/disability/inclusion-room": "disability-and-accessibility",
    "/club/club/disability/stadium-access": "disability-and-accessibility",
    "/club/club/disability/tickets": "disability-and-accessibility",
    "/club/club/disability/travel": "disability-and-accessibility",
    "/club/club/careers/inclusivity-and-belonging": "careers",
    "/club/club/careers/recruitment-partners": "careers",
    "/club/club/careers/recruitment-privacy-policy": "careers",
    "/club/club/careers/values": "careers",
    "/club/club/jobs/matchday-roles": "career-opportunities",
    "/club/club/myplanet/action": "environmental-sustainability",
    "/club/club/myplanet/biodiversity": "environmental-sustainability",
    "/club/club/myplanet/community": "environmental-sustainability",
    "/club/club/myplanet/economic": "environmental-sustainability",
    "/club/club/myplanet/energy-and-water": "environmental-sustainability",
    "/club/club/myplanet/environmental": "environmental-sustainability",
    "/club/club/myplanet/food": "environmental-sustainability",
    "/club/club/myplanet/governance": "environmental-sustainability",
    "/club/club/myplanet/objectives": "environmental-sustainability",
    "/club/club/myplanet/offsetting": "environmental-sustainability",
    "/club/club/myplanet/people": "environmental-sustainability",
    "/club/club/myplanet/plastic": "environmental-sustainability",
    "/club/club/myplanet/procurement": "environmental-sustainability",
    "/club/club/myplanet/social": "environmental-sustainability",
    "/club/club/myplanet/transport": "environmental-sustainability",
    "/club/club/myplanet/waste": "environmental-sustainability",
    "/club/club/myplanet/water": "environmental-sustainability",
    "/club/fans/albion-allies": "equality-diversity-and-inclusions",
    "/club/fans/fan-advisory-board": "fans-fan-advisory-board",
    "/club/fans/japan-tour": "japan-tour-2024",
    "/club/fans/membership": "memberships",
    "/club/fans/supporters-club": "fans-supporters-clubs",
    "/club/fans/fan-advisory-board/agenda-minutes": "fans-fan-advisory-board",
    "/club/fans/fan-advisory-board/fan-advisory-board-apply": "fans-fan-advisory-board",
    "/club/fans/fan-advisory-board/fan-advisory-board-representatives": "fans-fan-advisory-board",
    "/club/fans/membership/juniors": "memberships",
    "/club/fans/membership/myalbion": "memberships",
    "/club/fans/membership/myalbion-membership-news": "memberships",
    "/club/fans/membership/myalbion-plus": "memberships",
    "/club/fans/membership/season-tickets": "memberships",
    "/club/fans/supporters-club/about-supporters-clubs": "fans-supporters-clubs",
    "/club/fans/supporters-club/form-a-supporters-club": "fans-supporters-clubs",
    "/club/fans/supporters-club/form-new": "fans-supporters-clubs",
    "/club/fans/supporters-club/official-supporters-clubs": "fans-supporters-clubs",
    "/club/history/club-history": "club-history",
    "/club/history/club-history/a-knight-to-the-rescue": "mens-first-team-history",
    "/club/history/club-history/early-years": "mens-first-team-history",
    "/club/history/club-history/formation": "mens-first-team-history",
    "/club/history/club-history/into-the-football-league": "mens-first-team-history",
    "/club/history/club-history/into-the-top-flight": "mens-first-team-history",
    "/club/history/club-history/on-our-way-to-the-premier-league": "mens-first-team-history",
    "/club/history/club-history/promotion-at-last": "mens-first-team-history",
    "/club/history/club-history/the-amex-stadium": "mens-first-team-history",
    "/club/history/club-history/the-downward-spiral": "mens-first-team-history",
    "/club/history/club-history/the-fa-cup-final": "mens-first-team-history",
    "/club/history/club-history/the-withdean-years": "mens-first-team-history",
    "/club/history/womens-history": "womens-first-team-history",
    "/club/history/womens-history-becoming-officially-associated-with-albion": "womens-first-team-history",
    "/club/history/womens-history-early-days": "womens-first-team-history",
    "/club/history/womens-history-the-modern-era": "womens-first-team-history",
    "/club/matchday-hospitality/brunos-restaurant": "brunos-restaurant",
    "/club/matchday-hospitality/executive-boxes": "matchday-hospitality",
    "/club/matchday-hospitality/goldstone-restaurant": "goldstone-restaurant",
    "/club/matchday-hospitality/hbs-restaurant": "hbs-restaurant",
    "/club/matchday-hospitality/heineken-experience": "heineken-experience",
    "/club/matchday-hospitality/legends-box": "legends-box",
    "/club/matchday-hospitality/new": "matchday-hospitality",
    "/club/matchday-hospitality/mullerys-restaurant": "mullerys-restaurant",
    "/club/stadiums/broadfield-stadium": "fans-womens-matchday",
    "/club/stadiums/broadfield-stadium/fan-guide": "fans-womens-matchday",
    "/club/stadiums/broadfield-stadium/how-to-get-to-crawley": "fans-womens-matchday",
    "/club/stadiums/the-amex-stadium/access-stadium": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/access-stadium/accessible-toilets-changing-places": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/access-stadium/blind-partially-sighted": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/access-stadium/deaf-hard-hearing": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/access-stadium/inclusion-room": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/access-stadium/stadium-access": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/access-stadium/viewing-area": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/access-stadium/website-accessibility": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/access-ticketing": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/access-ticketing/personal-assistant-tickets": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/access-transport": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/access-transport/accessible-bus-travel": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/access-transport/accessible-parking": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/access-transport/accessible-train-travel": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/cashless": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/contact-us": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/dicks-bar": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/parking": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/stadium": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/stadium/behaviour-guidelines": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/stadium/cashless-stadium": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/stadium/faqs": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/stadium/stadium-entry-guidelines": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/stadium/stadium-plan": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/stadium-tours": "calendar.aspx?showid=274fd7a1-b8b0-47a2-a3f5-a49e18060c0c",
    "/club/stadiums/the-amex-stadium/superstore": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/ticket-office": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/ticketing": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/transport": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/transport/bus-travel": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/transport/car-parking": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/transport/free-travel-services": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/transport/park-ride-sites": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/transport/seagull-travel": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/transport/train-travel": "fans-matchday-at-the-amex",
    "/club/stadiums/the-amex-stadium/transport/walking-route": "fans-matchday-at-the-amex",
    "/fixtures/mens-team": "fixtures-first-team-men",
    "/fixtures/under-18": "mens-academy-mu18s-fixtures",
    "/fixtures/under-21": "mens-academy-mu21s-fixtures",
    "/fixtures/womens": "womens-first-team-fixtures",
    "/galleries": "latest-galleries",
    "/hospitality": "matchday-hospitality",
    "/hospitality/1901-club": "the-1901-club",
    "/hospitality/1901-club/about": "the-1901-club",
    "/hospitality/1901-club/affiliates": "1901-club-affiliate-scheme",
    "/hospitality/1901-club/exsisting-member": "1901-club-events",
    "/hospitality/1901-club/join": "the-1901-club",
    "/hospitality/1901-club/join/form": "the-1901-club",
    "/hospitality/1901-club/join/upgrade": "the-1901-club",
    "/hospitality/1901-club/lounge-prize-draw": "the-1901-club",
    "/hospitality/1901-club/renewal": "the-1901-club",
    "/hospitality/1901-club/renewal/package-prices": "the-1901-club",
    "/hospitality/matchday-hospitality": "matchday-hospitality",
    "/hospitality/matchday-hospitality/brochure-download": "matchday-hospitality",
    "/hospitality/matchday-hospitality/menus guide": "matchday-hospitality",
    "/hospitality/matchday-hospitality/menus guide/hospitality-faqs": "matchday-hospitality",
    "/hospitality/matchday-hospitality/options": "matchday-hospitality",
    "/hospitality/matchday-hospitality/options/executive-boxes": "matchday-hospitality",
    "/hospitality/matchday-hospitality/options/hospitality-restaurants": "matchday-hospitality",
    "/hospitality/matchday-hospitality/options/the-dugout": "matchday-hospitality",
    "/hospitality/packages-prices": "matchday-hospitality",
    "/hospitality/packages-prices/hospitality-terms-and-conditions": "matchday-hospitality",
    "/hospitality/matchday-sponsorship": "matchday-sponsors",
    "/hospitality/matchday-sponsorship/advertise-your-business": "matchday-sponsors",
    "/hospitality/matchday-sponsorship/current-sponsors": "matchday-sponsors",
    "/hospitality/matchday-sponsorship/play-on-the-pitch": "matchday-sponsors",
    "/live-audio-commentary": "latest-videos",
    "/match": "fixtures-first-team-men",
    "/my-albion-tv": "latest-videos",
    "/my-albion-tv/audio": "podcasts",
    "/my-albion-tv/audio-commentary": "latest-videos",
    "/my-albion-tv/box-sets": "latest-videos",
    "/my-albion-tv/christmas-wish": "latest-videos",
    "/my-albion-tv/eplay": "my-albion-gaming",
    "/my-albion-tv/eplay/albion-arcade": "my-albion-gaming",
    "/my-albion-tv/eplay/albion-arcade/cup-game": "cup-game",
    "/my-albion-tv/eplay/albion-arcade/drop-game": "drop-game",
    "/my-albion-tv/eplay/albion-arcade/memory-game": "memory-game",
    "/my-albion-tv/eplay/albion-arcade/quiz": "super-fan-quiz",
    "/my-albion-tv/eplay/albion-arcade/sliding-puzzle": "sliding-puzzle",
    "/my-albion-tv/eplay/albion-arcade/snake": "snake-game",
    "/my-albion-tv/eplay/matchday": "my-albion-gaming",
    "/my-albion-tv/eplay/my-albion-gaming": "my-albion-gaming",
    "/my-albion-tv/features": "myalbion-features",
    "/my-albion-tv/interviews": "interviews",
    "/my-albion-tv/match-highlights": "match-highlights",
    "/my-albion-tv/my-albion-audio": "podcasts",
    "/my-albion-tv/press-conference": "latest-videos",
    "/my-albion-tv/teams": "latest-videos",
    "/my-albion-tv/teams/mens-team-videos": "latest-videos",
    "/my-albion-tv/teams/u18-videos": "latest-videos",
    "/my-albion-tv/teams/u21-team-videos": "latest-videos",
    "/my-albion-tv/teams/womens-team-videos": "latest-videos",
    "/my-albion-tv/videos": "latest-videos",
    "/news": "all-latest-news",
    "/news/albion-analytics": "all-latest-news",
    "/news/albion-as-one": "all-latest-news",
    "/news/categories": "all-latest-news",
    "/news/categories/all-categories": "all-latest-news",
    "/news/categories/community-news": "all-latest-news",
    "/news/categories/epl-news": "all-latest-news",
    "/news/categories/euro2022-news": "all-latest-news",
    "/news/categories/fantasy-premier-league-news": "all-latest-news",
    "/news/categories/historical-hub": "all-latest-news",
    "/news/categories/match-previews": "all-matchday-news",
    "/news/categories/match-reports": "all-matchday-news",
    "/news/categories/player-reaction": "all-matchday-news",
    "/news/categories/ticket-news": "all-latest-news",
    "/news/categories/transfer-blug": "all-latest-news",
    "/news/eplay-news": "all-latest-news",
    "/news/features": "all-latest-news",
    "/news/features/how-i-can-help": "all-latest-news",
    "/news/features/my-albion-memories": "all-latest-news",
    "/news/features/player-awards": "all-latest-news",
    "/news/galleries": "latest-galleries",
    "/news/matchday-guides": "all-matchday-news",
    "/news/media-news": "all-latest-news",
    "/news/media-reviews": "all-matchday-news",
    "/news/teams": "all-latest-news",
    "/news/teams/mens-news": "latest-news-men",
    "/news/teams/u18-news": "latest-ma-u18s",
    "/news/teams/u21-news": "latest-ma-u21s",
    "/news/teams/womens-news": "latest-news-womens",
    "/news/teams/wu21-news": "latest-news-WU19s",
    "/results": "results-first-team-men",
    "/results/mens-team": "results-first-team-men",
    "/results/under-18": "mens-academy-mu18s-results",
    "/results/under-21": "mens-academy-mu21s-results",
    "/results/womens": "womens-first-team-results",
    "/tables": "table-first-team-men",
    "/tables/europa-league": "table-first-team-men",
    "/tables/mens-team": "table-first-team-men",
    "/tables/mens-team/europa-league": "table-first-team-men",
    "/tables/under-18": "mens-academy-mu18s-league-table",
    "/tables/under-21": "mens-academy-mu21s-league-table",
    "/tables/womens": "womens-first-team-league-table",
    "/teams": "first-team-men-squad",
    "/teams/mens-team": "first-team-men-squad",
    "/teams/mens-team/coaches": "mens-first-team-coaches",
    "/teams/mens-team/players": "first-team-men-squad",
    "/teams/u18s-team": "mens-academy-squad-u18s",
    "/teams/u18s-team/coaches": "mens-academy-teams-mu18-coaches",
    "/teams/u18s-team/players": "mens-academy-squad-u18s",
    "/teams/u21s-team": "mens-academy-squad",
    "/teams/u21s-team/coaches": "mens-academy-coaches",
    "/teams/u21s-team/players": "mens-academy-squad",
    "/teams/womens-team": "first-team-women-squad",
    "/teams/womens-team/coaches": "womens-first-team-coaches",
    "/teams/womens-team/players": "first-team-women-squad",
    "/teams/womens-u19-team": "womens-academy-squad-u19s",
    "/teams/womens-u19-team/squad": "womens-academy-squad-u19s",
    "/teams/womens-u19-team/players": "womens-academy-squad-u19s",
    "/wft-programmes": "wft-programmes",
    "/treeforlife": "memberships",
  };
} else if (CONFIG.league === 'fa' && CONFIG.tricode === 'bir') {
  redirects = {
    "/auth/login": "home",
    "/auth/register": "home",
    "/auth/password": "home",
    "/news": "latest-news",
    "/news/mens": "mens-news",
    "/news/womens": "womens-news",
    "/news/u21s": "academy-news",
    "/news/academy": "academy-news",
    "/news/tickets": "ticket-news",
    "/news/matchday": "matchday-news",
    "/news/match-preview": "home",
    "/news/match-reaction": "home",
    "/news/match-report": "home",
    "/news/family-zone": "home",
    "/news/hospitality": "hospitality-news",
    "/news/events": "event-news",
    "/news/commercial": "commercial-news",
    "/news/club": "club-updates",
    "/news/club-update": "club-updates",
    "/news/archive": "home",
    "/bluestv": "latest-videos",
    "/bluestv/all": "latest-videos",
    "/bluestv/u21s": "latest-videos",
    "/bluestv/boxsets": "latest-videos",
    "/bluestv/post-match-interview": "latest-videos",
    "/bluestv/highlights": "latest-videos",
    "/bluestv/full-match": "latest-videos",
    "/bluestv/podcast": "latest-videos",
    "/bluestv/the-archive": "latest-videos",
    "/bluestv/feature": "latest-videos",
    "/bluestv/community": "latest-videos",
    "/bluestv/pre-season": "latest-videos",
    "/bluestv/academy": "latest-videos",
    "/bluestv/packages": "latest-videos",
    "/matches/mens-team/fixtures": "mens-fixtures",
    "/matches/mens-team/results": "mens-fixtures",
    "/matches/mens-team/tables": "mens-tables",
    "/matches/womens-team/fixtures": "womens-fixtures",
    "/matches/womens-team/results": "womens-fixtures",
    "/matches/womens-team/tables": "womens-tables",
    "/teams": "mens-team",
    "/teams/mens-team/squad-list": "mens-team",
    "/teams/mens-team/season-stats": "mens-fixtures",
    "/teams/womens-team/squad-list": "womens-team",
    "/tickets": "https://www.eticketing.co.uk/bcfc/",
    "/tickets/groundregulations": "ground-regulations",
    "/tickets/terms-conditions": "ticketing-terms-and-conditions",
    "/tickets/stadium-tours": "stadium-tours",
    "/tickets/mascot-celebration-packages": "mascot-packages",
    "/tickets/blues-ticketing-guide": "ticketing-guide",
    "/store": "https://clubstore.bcfc.com",
    "/store/blues-store-opening-times": "https://clubstore.bcfc.com",
    "/more": "home",
    "/more/fans": "supporters",
    "/more/fans/disabled-supporter-information": "disabled-supporter-information",
    "/more/fans/equality-diversity": "equality-and-diversity",
    "/more/fans/family-zone": "arthurs-area",
    "/more/fans/getting-to-the-ground": "getting-to-st-andrews-knighthead-park",
    "/more/fans/new-to-the-ground": "st-andrews-knighthead-park",
    "/more/fans/stadium-plan": "stadium-information",
    "/more/fans/supporter-charter": "supporter-charter",
    "/more/fans/where-to-drink-at-the-stadium": "where-to-eat-at-the-stadium",
    "/more/fans/where-to-eat-at-the-stadium": "where-to-eat-at-the-stadium",
    "/more/community": "foundation",
    "/more/community/about-us": "about-the-foundation",
    "/more/community/academy-schools": "academy-schools",
    "/more/community/community-hub": "community-hub",
    "/more/community/education": "education",
    "/more/community/football-for-all": "football-for-all",
    "/more/community/junior-blues": "junior-blues",
    "/more/community/premier-league-primary-stars": "primary-league-primary-stars",
    "/more/commercial": "commercial",
    "/more/commercial/advertising-sponsorship": "advertising",
    "/more/commercial/conferences-events": "commercial",
    "/more/commercial/download-brochure": "commercial",
    "/more/commercial/matchday-boxes": "hospitality-landing-page",
    "/more/commercial/matchday-hospitality-suites": "hospitality-landing-page",
    "/more/commercial/the-2023-24-blues-business-club": "home",
    "/more/club": "club",
    "/more/club/academy": "blues-academy",
    "/more/club/board-management": "board-management",
    "/more/club/charity-requests": "contact-the-club",
    "/more/club/contact": "contact-the-club",
    "/more/club/gender-pay-gap-report": "gender-pay-gap-report",
    "/more/club/history": "history",
    "/more/club/media-requests": "media-requests",
    "/more/club/safeguarding": "safeguarding",
    "/more/club/vacancies": "vacancies",
    "/more/hospitality": "hospitality-landing-page",
    "/privacy-policy": "privacy-policy",
    "/terms-and-conditions": "terms-of-use",
    "/financial-results": "financial-results",
    "/news/community": "foundation-news",
    "/news/bluestv": "latest-videos",
    "/news/blues-store": "https://clubstore.bcfc.com",
    "/bluestv/mens": "latest-videos",
    "/bluestv/womens": "latest-videos",
    "/news/bluestv/bluestv-support": "bluestv-support",
    "/teams/u21s-team/squad-list": "mens-team",
    "/202425-mens-season-tickets": "https://www.eticketing.co.uk/bcfc/Events",
    "/store/temporary-blues-store-opening-times": "https://clubstore.bcfc.com",
    "/more/hospitality/the-sponsors-lounge": "the-sponsors-lounge",
    "/more/hospitality/the-garrison": "the-garrison",
    "/more/hospitality/the-city-view": "the-city",
    "/more/hospitality/the-boardroom": "the-boardroom",
    "/more/hospitality/download-brochure": "hospitality-landing-page",
  };
} else if (CONFIG.league === 'fa' && CONFIG.tricode === 'lei') {
  redirects = {
    "/en": "home",
    "/th": "home",
    "/rss-podcast.xml": "https://feeds.buzzsprout.com/2370347.rss",
    "/shop": "https://shop.lcfc.com",
    "/tickets": "https://tickets.lcfc.com",
    "/watch": "foxeshub",
    "/listen": "foxeshub",
    "/news/3989109/introducing-the-new-lcfc-app-on-ios": "app",
    "/matches/fixtures": "matches-fixtures",
    "/teams/men": "teams-men",
    "/home": "home",
    "/account": "profile",
    "/foxeshub": "foxeshub",
    "/news": "news",
    "/foxeshub/live-video": "foxeshub-live",
    "/matches/standings": "standings?teamID=13",
    "/matches/results": "matches-results",
    "/club/careers/vacancies": "vacancies",
    "/tv": "foxeshub",
    "/tv/live": "foxeshub-live",
    "/foxeshub/live-audio": "foxeshub",
    "/teams/women": "teams-women",
    "/king-power-stadium": "king-power-stadium",
    "/club/contact": "club-contact",
    "/hospitality": "matchday-hospitality",
    "/king-power-stadium/parking-travel": "king-power-stadium-parking-travel",
    "/foxeshub/packages": "foxeshub",
    "/teams/staff": "teams-staff",
    "/packages": "foxeshub",
    "/teams/staff/men": "teams-staff-men",
    "/tours": "tours",
    "/community": "help",
    "/spurscomp": "teams-staff",
    "/king-power-stadium/stadium-map": "king-power-stadium-stadium-map",
    "/search": "search",
    "/players/8979/Jamie-Vardy/profile": "player-detail-statistics-forward/101668",
    "/foxeshub/territories": "foxeshub",
    "/matches/matchday": "matchday-experiences",
    "/foxeshub/faqs": "foxeshub",
    "/academy/men/player-registration": "academy-men-player-registration",
    "/tv/live-video": "foxeshub",
    "/radio": "foxeshub",
    "/stadiumconsultation": "stadiumconsultation",
    "/tv/latest": "foxeshub",
    "/terms": "terms",
    "/terms-of-use": "terms-of-use",
    "/foxeshub/on-demand": "foxes-hub",
    "/help": "help",
    "/conferences-events": "conferences-events",
    "/community/football": "help",
    "/club/senior-management": "senior-management",
    "/academy/women": "academy-women",
    "/tv/3983335/prepare-like-we-have-all-season": "foxeshub",
    "/index.html": "",
    "/tv/3897585": "foxeshub",
    "/tv/live-audio": "foxeshub",
    "/foxeshub/thank-you": "foxeshub",
    "/creativerequest": "https://forms.monday.com/forms/32f7cffa003e029a95dcdd95f0fecade?r=use1",
    "/photographyrequest": "https://forms.monday.com/forms/47d968b582592b4ede0b463f6577ce54?r=use1",
    "/productionrequest": "https://forms.monday.com/forms/cfe7703029ac53d889efee07bf1ce0d0?r=use1",
    "/websiterequest": "https://forms.monday.com/forms/ed58591ca44231d18b9841264e253100?r=use1",
    "/men": "news",
    "/women": "news",
    "/u21s": "news",
    "/u18s": "news",
    "/foxeshub/interviews": "foxeshub",
    "/foxeshub/press-conferences": "foxeshub",
    "/foxeshub/highlights": "foxeshub",
    "/foxeshub/training": "foxeshub",
    "/hospitality/tv-studio": "matchday-hospitality",
    "/hospitality/faqs": "matchday-hospitality",
    "/news/1871090/club/tax-strategy": "media-article/Tax-Strategy",
    "/results": "fixtures-results?teamID=13&roundID=past",
    "/teams/first-team": "teams-men",
    "/events": "conferences-events",
    "/fixtures": "fixtures-results?teamID=13&roundID=future",
    "/kicks": "https://linktr.ee/lcitc_kicks",
    "/jv9oat": "media-video/jamie-vardy-rustles",
  };
}
