import { JanusAnalyticsPageContext } from "../../../js/analytics";
import { Readable } from "svelte/store";
import { ManualPassthrough, ReactiveMicrocomponent } from "yinzcam-rma";
import { CardsDataSourceManager } from "./CardsDataSourceManager";
import { CardsEditorModeManager } from "./CardsEditorModeManager";
import { Item } from "svelte-dnd-action";
import { YinzCamAPIRequestParameters } from "yinzcam-api";
import { CardsPageContentManager } from "./CardsPageContentManager";
import { JanusSignonManager } from "js/sso";
import { JanusModeContextManager } from "js/mode";
import { JanusInternationalizationManager } from "js/i18n";
import { JanusSEOManager } from "js/seo";

export const CardsPageContextKey = {};
export const CardsTabContextKey = {};
export const PlayerDetailContextKey = {};
export const UserPreferencesContextKey = {};
export const CardsEditorSubtreeContextKey = {};

export interface CardsTabContext {
  dataSourceManager: CardsDataSourceManager;
  mergedParamsComp: ReactiveMicrocomponent<{ [key: string]: string }>;
  setParam: (key: string, value?: string) => void;
  scrollContainer: Readable<HTMLElement>;
}

export interface CardsPageContext {
  id: string;
  name: string;
  query: {[key: string]: string};
  stickyHeights: number[];
  discoModeClass: string;
  editorModeManager: CardsEditorModeManager;
  defaultParamsComp: ReactiveMicrocomponent<YinzCamAPIRequestParameters>;
  pathParamsMappingComp: ReactiveMicrocomponent<string[]>;
  overrideParamsComp: ReactiveMicrocomponent<YinzCamAPIRequestParameters>;
  allViewsParamsComp: ManualPassthrough<YinzCamAPIRequestParameters>;
  getAnalytics(): JanusAnalyticsPageContext;
  contentManager: CardsPageContentManager;
  signonManager: JanusSignonManager;
  modeManager: JanusModeContextManager;
  i18nManager: JanusInternationalizationManager;
  seoManager: JanusSEOManager;
  pageContentLoadedStore: Readable<boolean>;
  useSequentialDisplay: boolean;
}

export interface UserPreferencesContext {
  country: string;
  team: string;
}

export interface CardsEditorSubtreeContext {
  componentId: string;
  component: Item;
  subtreeCloned: boolean;
  subtreePreview: boolean;
  getChildSubtree: (childId: string) => Item;
  moveUpChild: (childId: string) => void;
  moveDownChild: (childId: string) => void;
  cloneChild: (childId: string) => void;
  removeChild: (childId: string) => void;
  addChildBefore: (childId: string, newChild?: Item) => void;
  addChildAfter: (childId: string, newChild?: Item) => void;
}
