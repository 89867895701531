<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "components/cards/utilities";
import _ from "lodash";
import { ButtonParticle, TextParticle, } from "components/cards/particles/YinzCam";
import { BaseAtom, getSignonManager, } from "components/cards/atoms";
import Axios from "axios";
import RichTextParticle from "components/cards/particles/YinzCam/Core/RichText/RichTextParticle.svelte";
import { resolveUrl } from "js/url";
import "js/sso";
export let container;
export let feedUrl = undefined;
export let itemBoxPadding = undefined;
export let overlayPadding = undefined;
export let topLeftButton;
export let topRightButton;
export let titleText;
export let descriptionText;
export let ctaButton;
export let purchasePromptCTAText = undefined;
export let loginPromptTitle = undefined;
export let loginPromptDescription = undefined;
export let loginPromptCTAText = undefined;
// UNUSED?
export let footerBackgroundColor;
export let footerText;
export let footerButton;
const sso = getSignonManager();
$: loginStatus = sso.getStatusComponent().store;
$: isLoggedIn = $loginStatus.loggedIn;
function handleLoginClick() {
    sso.requireAuth();
}
/*
        <div class="footer-row">
          <div class="footer-row-text1">
            <TextParticle {...footerText} text={item?.type} />
          </div>
          <div class="footer-row-text2">
            <TextParticle {...footerText} text={item?.salary} />
          </div>
          <div class="footer-row-spacer">
          </div>
          <div class="footer-row-button">
            <ButtonParticle {..._.merge({}, footerButton, { text: { text: item?.livedate || '' } }) } />
          </div>
        </div>
 */
let atomWidthRem;
$: dataPromise = (function (..._) {
    const delay = t => new Promise(resolve => setTimeout(resolve, t));
    if (feedUrl && isLoggedIn) {
        return delay(0).then(() => sso.getVideoToken('')).then((result) => {
            var _a;
            //console.log('VIDEO TOKEN SSO RESULT', result);
            const url = new URL(feedUrl);
            url.searchParams.set('apijwttoken', (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.token);
            return url.toString();
        }).then((url) => {
            return Axios.get(url);
        }).then((rsp) => {
            const doc = rsp.data;
            const items = (doc === null || doc === void 0 ? void 0 : doc.SubscriptionPlanOptions) || [];
            //console.log('returning items', items);
            return items;
        });
    }
    else if (feedUrl && !isLoggedIn) {
        return delay(0).then(() => Axios.get(feedUrl)).then((rsp) => {
            const doc = rsp.data;
            const items = (doc === null || doc === void 0 ? void 0 : doc.SubscriptionPlanOptions) || [];
            //console.log('returning items', items);
            return items;
        });
    }
})(feedUrl, isLoggedIn);
/*
 {:else}
    <div
      class="main-container"
      class:layout-mobile-width={atomWidthRem < 30}
      class:layout-tablet-width={atomWidthRem < 45}
    >
      <div class="overlay-area">
        <div class="top-row">
        </div>
        <div class="bottom-row">
          <div class="text-icon-area">
            <div class="text-area">
              <div class="title-text">
              {#if !_.isNil(titleText)}
                <TextParticle _block={true} {...titleText} lineClamp={2} text={loginPromptTitle || ''} />
              {/if}
              </div>
              <div class="description-text">
              {#if !_.isNil(descriptionText)}
                <RichTextParticle _block={true} {...descriptionText} html={loginPromptDescription || ''}/>
              {/if}
              </div>
            </div>
          </div>
        </div>
        <div class="cta-row">
          {#if loginPromptCTAText}
          <div class="cta-button">
            <ButtonParticle {..._.merge({}, ctaButton, { text: { text: loginPromptCTAText } })} on:click={handleLoginClick} />
          </div>
          {/if}
        </div>
      </div>
    </div>
    {/if}
 */
</script>

<BaseAtom
  bind:_clientWidthRem={atomWidthRem}
  _isotope="StreamAMGPackagesAtom"
  _cssVariablePrefix="streamamg-packages"
  {...container}
  {overlayPadding}
  {itemBoxPadding}
  {footerBackgroundColor}
>
  <div class="outer-container">
    <div class="left-panel">
    </div>
    <div class="right-panel">
      {#await dataPromise then items}
      {#if items}
      {#each items as item}        
      <div
        class="main-container"
        class:layout-mobile-width={atomWidthRem < 30}
        class:layout-tablet-width={atomWidthRem < 45}
      >
        <div class="overlay-area">
          <div class="top-row">
            <div class="header-area">
              <div class="category-list">
                <ButtonParticle {..._.merge({}, topLeftButton, { text: { text: item?.AmountFormatted || '' } }) } />
              </div>
            </div>
            <div class="top-right-area">
              <ButtonParticle {..._.merge({}, topRightButton, { text: { text: item?.Interval?.match(/[a-zA-Z]+|[0-9]+/g)?.join(' ') || '' } }) } />
            </div>
          </div>
          <div class="bottom-row">
            <div class="text-icon-area">
              <div class="text-area">
                <div class="title-text">
                {#if !_.isNil(titleText)}
                  <TextParticle _block={true} {...titleText} lineClamp={2} text={item?.Title || ''} />
                {/if}
                </div>
                <div class="description-text">
                {#if !_.isNil(descriptionText)}
                  <RichTextParticle _block={true} {...descriptionText} html={item?.Body || ''}/>
                {/if}
                </div>
              </div>
            </div>
          </div>
          <div class="cta-row">
            {#if purchasePromptCTAText}
            <div class="cta-button">
              {#if isLoggedIn}
              <ButtonParticle {..._.merge({}, ctaButton, { text: { text: purchasePromptCTAText } })} link={resolveUrl(item?.PurchaseUrl || '#').href || '#'} />
              {:else}
              <ButtonParticle {..._.merge({}, ctaButton, { text: { text: purchasePromptCTAText } })} on:click={handleLoginClick} />              
              {/if}
            </div>
            {/if}
          </div>
        </div>
      </div>
      {/each}
      {/if}
      {/await}
    </div>
  </div>
</BaseAtom>

<style>
  .outer-container {
    width: 100%;
    padding: 2rem;
    background-color: transparent;
  }

  .left-panel {
    width: 0;
  }

  .right-panel {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    gap: 3rem;
    width: 100%;
  }

  .main-container {
    padding: var(--streamamg-packages-item-box-padding, unset);

    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    background-size: cover;
    background-position: top center;
    position: relative;
    height: 100%;
    width: 100%;

    background-color: white;
    border-radius: 1.5rem;
    overflow: hidden;
  }

  .main-container > .overlay-area {    
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0rem;
    padding: 0;
  }

  .main-container > .overlay-area > .top-row {
    padding: var(--streamamg-packages-overlay-padding, unset);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }

  .main-container > .overlay-area > .top-row > .header-area {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
  }

  .main-container > .overlay-area > .top-row > .header-area > .category-list {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: start;
    gap: 1rem;
  }

  .main-container > .overlay-area > .bottom-row {
    width: 100%;
    padding: var(--streamamg-packages-overlay-padding, unset);
    display: flex;
    justify-content: space-between;
  }

  .main-container > .overlay-area > .bottom-row > .text-icon-area {
    width: 100%;;
  }

  .main-container > .overlay-area > .bottom-row > .text-icon-area > .text-area {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .main-container > .overlay-area > .cta-row {
    padding: var(--streamamg-packages-overlay-padding, unset);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .main-container > .overlay-area > .cta-row > .cta-button {
    background-color: var(--cta-background-color);
    color: var(--cta-font-color);
    font-size: var(--cta-font-size, 1rem);
    font-weight: var(--cta-font-weight, 600);

    display: block;
    border-radius: 8px;
    white-space: nowrap;
  }

  .main-container.layout-mobile-width
    > .overlay-area
    > .top-row
    > .header-area {
    flex-direction: column;
    align-items: start;
    flex-wrap: wrap;
  }

  .text-icon-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .title-text {
    width: 100%;
  }

  .description-text {
    width: 100%;
  }

  .main-container > .overlay-area > .footer-row {
    padding: var(--streamamg-packages-overlay-padding, unset);
    padding-top: 3rem;
    padding-bottom: 1rem;

    display: flex;
    flex-flow: row wrap;
    justify-content: start;
    align-items: center;
    width: 100%;
    gap: 3rem;
    background-color: var(--streamamg-packages-footer-background-color, unset);
  }

  .main-container > .overlay-area > .footer-row > * {
    flex: 0 0 max-content;
  }

  .main-container > .overlay-area > .footer-row > .footer-row-spacer {
    flex: 1 0 auto;
  }</style>
