<Boundary>
  <Tabs>
    {#each tabs as tab, i (tab.id)}
      <Tab id={tab.id} tabActive={defaultTabIndex === i} on:tabShow={onTabShow.bind(null, tab.id)}>
      {#if (!tab.fragmentUri && tab.sections?.length > 0)}
        <CardsTab {parentSequenceId} sequenceId={generateSequenceId('TAB', parentSequenceId, i)} {tab} updater={(tab) => tabs[i] = tab} on:domLoaded={() => onTabDomLoaded(tab.id)} />
      {/if}
      </Tab>
    {/each}
  </Tabs>
</Boundary>

<style>
  /*
  {#if $editorModeEnabled}
  <CardsTab {parentSequenceId} sequenceId={generateSequenceId('TAB', parentSequenceId, i)} {tab} updater={(tab) => tabs[i] = tab} on:domLoaded={() => onTabDomLoaded(tab.id)} />
  {:else}
  <CardsTabCached {parentSequenceId} sequenceId={generateSequenceId('TAB', parentSequenceId, i)} {tab} updater={(tab) => tabs[i] = tab} on:domLoaded={() => onTabDomLoaded(tab.id)} />
  {/if}
  */

  *, *::before, *::after {
    box-sizing: border-box;
  }</style>

<script lang="ts">import "yinzcam-cards";
import { Tabs, Tab } from 'framework7-svelte';
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { createEventDispatcher, getContext } from 'svelte';
import { CardsPageContextKey } from './context';
import { generateSequenceId } from "../utilities";
import "./CardsTabCached.svelte";
import CardsTab from "./CardsTab.svelte";
export let parentSequenceId = "";
export let tabs;
const dispatch = createEventDispatcher();
const defaultTabIndex = Math.max(tabs === null || tabs === void 0 ? void 0 : tabs.findIndex((val) => val === null || val === void 0 ? void 0 : val.default), 0);
const pageContext = getContext(CardsPageContextKey);
const editorModeEnabled = pageContext.editorModeManager.getEnabledStore();
function onTabShow(id) {
    pageContext.getAnalytics().sendInteractionEvent('Tab_Click', id);
}
const tabsLoaded = {};
let domLoaded = false;
function onTabDomLoaded(tabId) {
    tabsLoaded[tabId] = true;
    if (!domLoaded && Object.keys(tabsLoaded).length === tabs.length) {
        domLoaded = true;
        console.log('all tabs loaded');
        dispatch('domLoaded');
    }
}
</script>
