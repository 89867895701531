import { LoggerToken } from 'yinzcam-log';
import type { Logger } from 'yinzcam-log';
import { injectable } from 'inversify';
import { injectToken, Token } from 'inversify-token';
import _ from 'lodash';

export const NetworkCacheManagerToken = new Token<NetworkCacheManager>(Symbol.for("NetworkCacheManagerToken"));

function promiseWithResolvers<T>() {
  let resolve, reject;
  const promise = new Promise<T>((res, rej) => {
    resolve = res;
    reject = rej;
  });
  return { promise, resolve, reject };
}

@injectable()
export class NetworkCacheManager {

  private cachedCache: Cache = null;
  private loadGroups: { [url: string]: any[] } = {};

  constructor(@injectToken(LoggerToken) private readonly log: Logger) {
  }

  private async getCache(): Promise<Cache> {
    if (this.cachedCache) {
      return this.cachedCache;
    }
    if (!window.caches) {
      this.log.debug("cache module unavailable, possibly not in secure context");
      return null;
    }
    try {
      this.cachedCache = await window.caches.open('Janus__NetworkCacheManager');
    } catch (e) {
      this.log.warn("cache open threw exception, returning null", e);
    }
    return this.cachedCache;
  }

  public async put(url: URL, data: string, ttl: number) {
    try {
      const cache = await this.getCache();
      if (!cache) {
        return;
      }
      const rsp = new Response(data, {
        headers: {
          'x-cache-expiration-ts': (Date.now() + ttl).toString()
        }
      });
      await cache.put(url, rsp);
    } catch (e) {
      this.log.warn("cache put threw exception, ignoring", e);
    }
  }

  public async get(url: URL): Promise<string | undefined> {
    try {
      const cache = await this.getCache();
      if (!cache) {
        // console.info('cache MISS (unavailable)', url);
        return undefined;
      }
      const rsp = await cache.match(url);
      if (!rsp) {
        // console.info('cache MISS (not found)', url);
        return undefined;
      }
      const expTs = parseInt(rsp.headers.get('x-cache-expiration-ts'));
      if (Date.now() > expTs) {
        // console.info('cache MISS (expired)', url);
        return undefined;
      }
      // console.info('cache HIT', url);
      return rsp.text();
    } catch (e) {
      this.log.warn("cache get threw exception, returning undefined", e);
      return undefined;
    }
  }

  public async getOrPut(url: URL, loader: () => Promise<[string, number]>): Promise<string> {
    const cache = await this.getCache();
    if (!cache) {
      // console.info('cache MISS (unavailable)', url);
      return (await loader())[0];
    }

    // this block will return if we have a cache hit
    let missReason = 'unknown';
    const rsp = await cache.match(url);
    if (rsp) {
      const expTs = parseInt(rsp.headers.get('x-cache-expiration-ts'));
      if (Date.now() < expTs) {
        // console.info('cache HIT (direct)', url);
        return rsp.text();
      } else {
        missReason = 'expired';
      }
    } else {
      missReason = 'not found';
    }

    // we have a cache miss
    const groupKey = url.toString();
    let loadGroup = this.loadGroups[groupKey];
    if (_.isNil(loadGroup)) {
      // no other call is loading this URL, so we will do it
      this.loadGroups[groupKey] = loadGroup = [];
      try {
        // console.info(`cache MISS (${missReason})`, url);
        const [data, ttl] = await loader();
        const rsp = new Response(data, {
          headers: {
            'x-cache-expiration-ts': (Date.now() + ttl).toString()
          }
        });
        await cache.put(url, rsp);
        for (const resolve of loadGroup) {
          resolve(data);
        }
        return data;
      } finally {
        loadGroup = null;
        delete this.loadGroups[groupKey];
      }
    } else {
      // someone else is loading this URL, so we will wait for that to finish and use the same result
      const pwr = promiseWithResolvers<string>();
      loadGroup.push(pwr.resolve);
      const data = await pwr.promise;
      // console.info('cache HIT (group)', url);
      return data;
    }
  }
}