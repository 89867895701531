import { Container } from "inversify";
import { TokenContainerModule } from "inversify-token";
import { YinzCamInjectModule } from "yinzcam-inject";
import { CardsDataSourceToken } from "../common/CardsDataSourceToken";

import { ContentfulCDNQuery } from "./ContentfulCDNQuery/ContentfulCDNQuery";
import { ContentfulPageData } from "./ContentfulPageData/ContentfulPageData";

import { YinzCamAppServerGameBox } from "./YinzCamAppServerGameBox/YinzCamAppServerGameBox";
import { YinzCamAppServerGameCommentaryArray } from "./YinzCamAppServerGameCommentaryArray/YinzCamAppServerGameCommentaryArray";
import { YinzCamAppServerGameFormation } from "./YinzCamAppServerGameFormation/YinzCamAppServerGameFormation";
import { YinzCamAppServerGameList } from "./YinzCamAppServerGameList/YinzCamAppServerGameList";
import { YinzCamAppServerGamePlaysArray } from "./YinzCamAppServerGamePlaysArray/YinzCamAppServerGamePlaysArray";
import { YinzCamAppServerGameScores } from "./YinzCamAppServerGameScores/YinzCamAppServerGameScores";
import { YinzCamAppServerGameScoresFilter } from "./YinzCamAppServerGameScoresFilter/YinzCamAppServerGameScoresFilter";
import { YinzCamAppServerStatsStandings } from "./YinzCamAppServerStatsStandings/YinzCamAppServerStatsStandings";
import { YinzCamAppServerStatsStandingsFilter } from "./YinzCamAppServerStatsStandingsFilter/YinzCamAppServerStatsStandingsFilter";
import { YinzCamAppServerStatsTeam } from "./YinzCamAppServerStatsTeam/YinzCamAppServerStatsTeam";
import { YinzCamAppServerStatsPlayer } from "./YinzCamAppServerStatsPlayer/YinzCamAppServerStatsPlayer";
import { YinzCamAppServerTeamRoster } from "./YinzCamAppServerTeamRoster/YinzCamAppServerTeamRoster";
import { DIYSource } from "./DIYSource/DIYSource";
import { DIYSourceModule } from "./DIYSource/module";

const SOURCE_LIBRARY = {
  ContentfulCDNQuery,
  ContentfulPageData,

  DIYSource,

  YinzCamAppServerGameBox,
  YinzCamAppServerGameCommentaryArray,
  YinzCamAppServerGameFormation,
  YinzCamAppServerGameList,
  YinzCamAppServerGamePlaysArray,
  YinzCamAppServerGameScores,
  YinzCamAppServerGameScoresFilter,
  YinzCamAppServerStatsStandings,
  YinzCamAppServerStatsStandingsFilter,
  YinzCamAppServerStatsTeam,
  YinzCamAppServerStatsPlayer,
  YinzCamAppServerTeamRoster
};

export const CardsDataSourcesModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    for (let k in SOURCE_LIBRARY) {
      const clazz = k.replace(/__/g, '.');
      const rec = SOURCE_LIBRARY[k];
      // this monkey patches each data source with its class name as listed in SOURCE_LIBRARY
      rec.prototype.clazz = clazz;
      bindToken(CardsDataSourceToken).to(rec).whenTargetNamed(clazz);
    }
  }));
  DIYSourceModule.configure(container);
});
