import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";
import { JSONSchema4 } from "json-schema";
import { DateTime } from "luxon";
import { } from "components/cards/utilities/constants"

@injectable()
export class YinzCamAppServerGameScores extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  monthList = ["January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"]

  public async getDataSourceConfigSpec(path?: string, specData?: { [key: string]: any }): Promise<JSONSchema4> {
    return {
      type: "object",
      properties: {
        "month": {
          type: "string",
          title: "Month",
          description: "Select a month to filter data",
          "enum": [
            "january",
            "february",
            "march",
            "april",
            "may",
            "june",
            "july",
            "august",
            "september",
            "october",
            "november",
            "december"
          ],
          "options": {
            "enum_titles": [
              ...this.monthList
            ]
          }
        },
        "singleObject": {
          type: "boolean",
          title: "Single Object",
          description: "If the data source is an array of items, pass the entire array to the first card using this data source, rather than spreading the items in the array among all cards using this data source (the normal behavior).",
        },
      },
    };
  }

  protected processResponse(path: string, response: YinzCamAPIResponse, specData?: { [key: string]: any }): object | any[] {
    const data = response?.data as any;
    if (specData) {
      if (specData?.month) {
        return [].concat(data?.Scores?.Day?.Game).filter(e => {
          return this.monthList[DateTime.fromISO(e?.Timestamp?._text)?.month - 1]?.toLowerCase() === specData?.month?.toLowerCase();
        })
      }
      if (specData?.singleObject) {
        let ret = { fixturesArray: [].concat(data?.Scores?.Day?.Game) };
        return ret;
      }
    } else {
      return [].concat(data?.Scores?.Day?.Game).filter(e => e);
    }
  }
}
