import { generateBooleanSchema, generateColorSchema, generateDateTimeSchema, generateRootObjectSchema, generateStringSchema, generateArraySchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { TextParticleSpec, ImageParticleSpec, ButtonParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Fixtures Card - Stacked", generateRootObjectSchema({
    container: generateAtomContainerSchema({
        defaultValues: { background: { visible: true } },
    }),
    overlayPadding: generateStringSchema("Overlay Padding", "Specify a padding for overlay content"),
    backgroundColor: generateColorSchema("Center Box Background Color", "BG for live matches"),
    imgLeaguelogo: ImageParticleSpec.generateSchema("Competition Logo", "Upload/specify URL for competition logo", {
        defaultValues: { container: { height: "4rem" }, sizing: "cover" }
    }),
    matchDateTime: generateDateTimeSchema("Match Date Time", "The date and time of the match."),
    txtMatchDate: TextParticleSpec.generateSchema("Match Date", "The styling for the match date.", {
        defaultValues: { size: "1.25rem", weight: "400", wrap: "nowrap" },
        uneditableFields: { text: true }
    }),
    txtMatchTime: TextParticleSpec.generateSchema("Match Time", "The styling for the match Time.", {
        defaultValues: { size: "2.25rem", weight: "800", wrap: "nowrap", transform: "uppercase" },
        uneditableFields: { text: true }
    }),
    txtStadiumName: TextParticleSpec.generateSchema("Venue Name", "Enter venue name", {
        defaultValues: { size: '1rem', weight: '400', transform: 'uppercase' }
    }),
    imgHomeTeam: ImageParticleSpec.generateSchema("Home Team logo", "Upload/specify home team logo", {
        defaultValues: { container: { height: "5.5rem", width: "5.5rem" }, sizing: 'cover', source: 'https://resources-uk.yinzcam.com/soccer/shared/logos/_default.svg' }
    }),
    imgAwayTeam: ImageParticleSpec.generateSchema("Away Team logo", "Upload/specify away team logo", {
        defaultValues: { container: { height: "5.5rem", width: "5.5rem" }, sizing: 'cover', source: 'https://resources-uk.yinzcam.com/soccer/shared/logos/_default.svg' }
    }),
    btnCTA: generateArraySchema("CTA Buttons", "Populate action buttons",
        ButtonParticleSpec.generateSchema("CTA Button", "Details of CTA", {
            defaultValues: {
                container: {
                    padding: "0 0.5rem",
                    border: {
                        visible: true,
                    },
                    background: {
                        visible: true
                    }, fillHeight: false, fillWidth: false,
                },
                text: {
                    size: "1.25rem", weight: "800", align: "left", transform: 'uppercase'
                },
                image: {
                    container: { height: "2.25rem", width: "2.25rem" },
                },
            }
        })
    ),
    matchStatus: generateStringSchema("Match Status", "Select match status", {
        choices: ['pre', 'live', 'post'],
        choiceTitles: ["Pre", "Live", "Post"],
        defaultValue: 'pre'
    }),
    homeTeamScore: TextParticleSpec.generateSchema("Home Score", "Enter home team score", {
        defaultValues: { size: "3rem", weight: "700" }
    }),
    awayTeamScore: TextParticleSpec.generateSchema("Away Score", "Enter home team score", {
        defaultValues: { size: "3rem", weight: "700" }
    }),
    matchMinutes: TextParticleSpec.generateSchema("Match minutes - Live", "Enter the minutes of live match", {
        defaultValues: { size: "1rem", weight: "300", color: "#fff" }
    }),
    isPenalty: generateBooleanSchema("Penalty", "Select if there was a penalty", {
        defaultValue: false
    }),
    txtLive: TextParticleSpec.generateSchema("Title text", "Defaults to `Live` when match status is Live", {
        defaultValues: { size: "1.25rem", weight: "700", transform: "uppercase" }
    }),
    txtLiveSmall: TextParticleSpec.generateSchema("Live text small", "Defaults to `Live` when match status is Live", {
        defaultValues: { size: "0.75rem", weight: "700", transform: "uppercase" }
    }),
    txtPenalties: TextParticleSpec.generateSchema("Penalties Text", "The text to display when a match is won on penalties.", {
        defaultValues: { size: "1rem", weight: "400", align: "center" }
    }),
    colorLiveIconBackground: generateColorSchema("Header Background", "Pick a color for header"),
    txtCountDownTime: TextParticleSpec.generateSchema("Timer Handles", "Timer Handles", {
        defaultValues: { size: "2rem", weight: "800" }
    }),
    txtCountDownText: TextParticleSpec.generateSchema("Timer Text", "Timer Text", {
        defaultValues: { size: "1rem", weight: "800" }
    }),
    countdownBoxColor: generateColorSchema("Countdown Box Color", "Countdown Box Color"),
    isCountdownRequired: generateBooleanSchema("Countdown Required?", "Countdown Required?", {
        defaultValue: false
    })
}));
