<script context="module" lang="ts">var _a;
export { specification } from "./spec";
</script>

<script lang="ts">var _a;
import _, { merge } from "lodash";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
export let container;
export let containerPadding;
export let txtHeading;
export let txtHeaderData;
export let txtRowData;
export let imgTeamLogo;
export let btnFullTable;
export let colorHeadingBackground;
export let tableHeaderColor;
export let maxRows = undefined;
export let centerOnTeam = undefined;
export let tableColumns = [];
export let darkBackgroundFontColor;
export let variantType;
export let imgLeagueLogo;
export let colorFooterBackground;
export let homeTeamFontColor;
export let regularFontColor;
export let colorAlternateRows;
export let tableData = [];
export let homeTeamBorderRadius;
export let shouldDisplayBorder;
export let boxBorderColor;
export let tableBorderRadius;
export let liveIconPosition;
export let hasGroupData = undefined;
export let groupTableData = [];
export let groupNames = undefined;
export let groupHeaderBGColor = undefined;
export let txtGroupName = undefined;
const computeGoalDifference = (goalDiff, goalsFor, goalsAgainst) => {
    if (!_.isNil(goalDiff))
        return goalDiff;
    let goalDifference = 0;
    if (goalsFor && goalsAgainst) {
        goalDifference = parseInt(goalsFor) - parseInt(goalsAgainst);
    }
    // console.log('computeGoalDifference', goalDiff, goalsFor, goalsAgainst);
    return goalDifference;
};
let atomWidthRem;
$: isMobile = atomWidthRem < 35;
$: isTablet = atomWidthRem < 60 && atomWidthRem >= 35;
$: leagueLogoVariant = variantType === "with-league-logo";
$: !(tableColumns === null || tableColumns === void 0 ? void 0 : tableColumns.includes("GD")) &&
    (tableColumns === null || tableColumns === void 0 ? void 0 : tableColumns.splice(tableColumns.length - 1, 0, "GD"));
let filteredTableData = [];
$: isAnyLive = (_a = tableData === null || tableData === void 0 ? void 0 : tableData.find((data) => data === null || data === void 0 ? void 0 : data.isLive)) === null || _a === void 0 ? void 0 : _a.isLive;
$: if (tableData) {
    if (centerOnTeam && maxRows > 0) {
        const teamIndex = Math.max(tableData.findIndex((r) => {
            var _a, _b;
            return ((_a = r === null || r === void 0 ? void 0 : r.teamTriCode) === null || _a === void 0 ? void 0 : _a.toLowerCase()) ===
                ((_b = CONFIG.tricode) === null || _b === void 0 ? void 0 : _b.toLowerCase());
        }), 0);
        let beforeRows, afterRows;
        if (maxRows % 2 === 0) {
            beforeRows = maxRows / 2 - 1;
            afterRows = maxRows / 2;
        }
        else {
            beforeRows = afterRows = (maxRows - 1) / 2;
        }
        let startIndex, endIndex;
        if (teamIndex - beforeRows < 0) {
            startIndex = 0;
            endIndex = Math.min(tableData.length - 1, teamIndex + afterRows - (teamIndex - beforeRows));
        }
        else if (teamIndex + afterRows >= tableData.length) {
            endIndex = tableData.length - 1;
            startIndex = Math.max(0, teamIndex -
                beforeRows -
                (teamIndex + afterRows - (tableData.length - 1)));
        }
        else {
            startIndex = teamIndex - beforeRows;
            endIndex = teamIndex + afterRows;
        }
        filteredTableData = tableData.slice(startIndex, endIndex + 1);
    }
    else if (maxRows > 0) {
        filteredTableData = tableData.slice(0, Math.min(tableData.length, maxRows));
    }
    else {
        filteredTableData = tableData;
    }
}
</script>

<BaseAtom
    bind:_clientWidthRem={atomWidthRem}
    _isotope="LeagueTableTwoAtom-Stats"
    _cssVariablePrefix="league-table-2"
    {...container}
    {containerPadding}
    {colorHeadingBackground}
    {tableHeaderColor}
    {darkBackgroundFontColor}
    {colorFooterBackground}
    {homeTeamFontColor}
    {regularFontColor}
    {colorAlternateRows}
    {boxBorderColor}
    {tableBorderRadius}
>
    {#if filteredTableData?.length > 0}
        <div class="main-container">
            <div class="heading-text">
                <div class="team-name-logo">
                    {#if leagueLogoVariant}
                        <ImageParticle _block={true} {...imgLeagueLogo} />
                    {:else}
                        <TextParticle _block={true} {...txtHeading} />
                    {/if}
                </div>
                <div class="live-icon">
                    {#if isAnyLive === true && liveIconPosition === "top"}
                        <img
                            style="height: 4rem; width: 4rem;"
                            src={CONFIG.liveIndicatorImageUrl}
                        />
                    {/if}
                </div>
            </div>
            <div
                class="table-container"
                class:mobile-layout={isMobile}
                class:tablet-layout={isTablet}
                class:box-border={shouldDisplayBorder}
            >
                <table>
                    <thead class="table-header">
                        <tr>
                            <th class="Pos"
                                ><TextParticle
                                    {...txtHeaderData}
                                    text="Pos"
                                /></th
                            >
                            <th class="Club club-header"
                                ><TextParticle
                                    {...txtHeaderData}
                                    text="Club"
                                    _block={true}
                                /></th
                            >
                            {#each tableColumns || [] as header}
                                <th class={header}>
                                    <TextParticle
                                        {...txtHeaderData}
                                        text={header}
                                    />
                                </th>
                            {/each}
                        </tr>
                    </thead>
                    <tbody>
                        {#if !hasGroupData}
                            {#each filteredTableData || [] as data, index}
                                <tr
                                    class:home-team={CONFIG.tricode.toLowerCase() ===
                                        data?.teamTriCode?.toLowerCase()}
                                    class:dark-background-row={index % 2 === 0}
                                    class:rounded-border={CONFIG.tricode.toLowerCase() ===
                                        data?.teamTriCode?.toLowerCase() &&
                                        homeTeamBorderRadius}
                                >
                                    <td class="Pos"
                                        ><TextParticle
                                            {...txtRowData}
                                            text={data?.position}
                                            color={CONFIG.tricode.toLowerCase() ===
                                            data?.teamTriCode?.toLowerCase()
                                                ? "var(--league-table-2-home-team-font-color, #fff)"
                                                : "var(--league-table-2-regular-font-color, #000)"}
                                        /></td
                                    >
                                    <td class="Club"
                                        ><ImageParticle
                                            {...imgTeamLogo}
                                            source={data?.teamLogo}
                                            _block={true}
                                        />
                                        <TextParticle
                                            {...txtRowData}
                                            text={isMobile
                                                ? data?.teamTriCode
                                                : data?.teamName}
                                            color={CONFIG.tricode.toLowerCase() ===
                                            data?.teamTriCode?.toLowerCase()
                                                ? "var(--league-table-2-home-team-font-color, #fff)"
                                                : "var(--league-table-2-regular-font-color, #000)"}
                                        />
                                        {#if data?.isLive && liveIconPosition === "team"}
                                            <img
                                                style="max-height: 4rem; padding-left: 1rem;"
                                                src={CONFIG.liveIndicatorImageUrl}
                                            />
                                        {/if}
                                    </td>
                                    {#each tableColumns || [] as header, index}
                                        <td class={header}>
                                            <TextParticle
                                                {...txtRowData}
                                                text={header === "GD"
                                                    ? `${computeGoalDifference(
                                                          data[header],
                                                          data["GF"],
                                                          data["GA"],
                                                      )}`
                                                    : data[header]}
                                                color={CONFIG.tricode.toLowerCase() ===
                                                data?.teamTriCode?.toLowerCase()
                                                    ? "var(--league-table-2-home-team-font-color, #fff)"
                                                    : "var(--league-table-2-regular-font-color, #000)"}
                                            />
                                        </td>
                                    {/each}
                                </tr>
                            {/each}
                        {:else}
                            {#each groupNames || [] as group}
                                <tr class="cup-group-name">
                                    <td
                                        colspan="9"
                                        align="left"
                                        class="group-name-td"
                                    >
                                        <TextParticle
                                            {...txtGroupName}
                                            text={group}
                                        />
                                    </td>
                                </tr>
                                {#each groupTableData?.filter((data) => data?.groupName === group) || [] as data, index}
                                    <tr
                                        class:home-team={CONFIG.tricode.toLowerCase() ===
                                            data?.teamTriCode?.toLowerCase()}
                                        class:dark-background-row={index % 2 ===
                                            0}
                                        class:rounded-border={CONFIG.tricode.toLowerCase() ===
                                            data?.teamTriCode?.toLowerCase() &&
                                            homeTeamBorderRadius}
                                    >
                                        <td class="Pos"
                                            ><TextParticle
                                                {...txtRowData}
                                                text={data?.groupRank}
                                                color={CONFIG.tricode.toLowerCase() ===
                                                data?.teamTriCode?.toLowerCase()
                                                    ? "var(--league-table-2-home-team-font-color, #fff)"
                                                    : "var(--league-table-2-regular-font-color, #000)"}
                                            /></td
                                        >
                                        <td class="Club"
                                            ><ImageParticle
                                                {...imgTeamLogo}
                                                source={data?.teamLogo}
                                                _block={true}
                                            />
                                            <TextParticle
                                                {...txtRowData}
                                                text={isMobile
                                                    ? data?.teamTriCode
                                                    : data?.teamName}
                                                color={CONFIG.tricode.toLowerCase() ===
                                                data?.teamTriCode?.toLowerCase()
                                                    ? "var(--league-table-2-home-team-font-color, #fff)"
                                                    : "var(--league-table-2-regular-font-color, #000)"}
                                            />
                                            {#if data?.isLive && liveIconPosition === "team"}
                                                <img
                                                    style="max-height: 4rem; padding-left: 1rem;"
                                                    src={CONFIG.liveIndicatorImageUrl}
                                                />
                                            {/if}
                                        </td>
                                        {#each tableColumns || [] as header, index}
                                            <td class={header}>
                                                <TextParticle
                                                    {...txtRowData}
                                                    text={header === "GD"
                                                        ? `${computeGoalDifference(
                                                              data[header],
                                                              data["GF"],
                                                              data["GA"],
                                                          )}`
                                                        : data[header]}
                                                    color={CONFIG.tricode.toLowerCase() ===
                                                    data?.teamTriCode?.toLowerCase()
                                                        ? "var(--league-table-2-home-team-font-color, #fff)"
                                                        : "var(--league-table-2-regular-font-color, #000)"}
                                                />
                                            </td>
                                        {/each}
                                    </tr>
                                {/each}
                            {/each}
                        {/if}
                    </tbody>
                </table>
            </div>
            <div
                class="full-table-button"
                class:with-league-logo={leagueLogoVariant}
            >
                <ButtonParticle
                    {...merge({}, btnFullTable, {
                        text: {
                            text: btnFullTable?.text?.text || "Full Table",
                        },
                    })}
                />
            </div>
        </div>
    {/if}
</BaseAtom>

<style>
    .main-container {
        padding: var(--league-table-2-container-padding, unset);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        /* border-radius: 2rem; */
        overflow: hidden;
    }
    .heading-text {
        width: 100%;
        text-align: center;
        background-color: var(
            --league-table-2-color-heading-background,
            #164194
        );
        color: var(--league-table-2-dark-background-font-color, #fff);
        padding: 0.5rem;
        border-top-left-radius: var(--league-table-2-table-border-radius, 2rem);
        border-top-right-radius: var(
            --league-table-2-table-border-radius,
            2rem
        );
        position: relative;
    }
    .live-icon {
        position: absolute;
        right: 1rem;
        top: -0.5rem;
    }
    .table-container {
        width: 100%;
        height: 100%;
        justify-content: space-evenly;
    }
    .table-container table {
            text-align: left;
            width: 100%;
            border-collapse: collapse;
            height: 100%;
        }
    .table-container table thead {
                background-color: var(
                    --league-table-2-table-header-color,
                    #d0d0d0
                );
                /* height: 100%; */
            }
    .table-container table thead tr th {
                        height: 100%;
                        width: 5%;
                        text-align: center;
                        vertical-align: middle;
                        background-color: var(
                            --league-table-2-table-header-color,
                            #d0d0d0
                        );
                        box-shadow:
                            -1px 0px 0px 0px
                                var(
                                    --league-table-2-table-header-color,
                                    #d0d0d0
                                ),
                            1px 0px 0px 0px
                                var(
                                    --league-table-2-table-header-color,
                                    #d0d0d0
                                );
                    }
    .table-container table tbody tr td {
                        text-align: center;
                        border: none;
                        padding: 0;
                    }
    .table-container table tbody tr.dark-background-row {
                    background-color: var(
                        --league-table-2-color-alternate-rows,
                        #f1f1f1
                    );
                }
    .table-container table tbody tr.dark-background-row td {
                        background-color: var(
                            --league-table-2-color-alternate-rows,
                            #f1f1f1
                        );
                    }
    .table-container .Club {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            height: 100%;
        }

    .box-border {
        border: 2px solid var(--league-table-2-box-border-color, #164194);
        border-top: none;
        border-bottom: none;
    }

    .table-container.mobile-layout table thead tr th {
                        /* width: 10%; */
                        width: 3rem;
                    }

    .table-container.mobile-layout .Club {
            display: flex;
            align-items: center;
            height: 100%;
        }

    .table-container.tablet-layout table thead tr th {
                        width: 7%;
                    }
    .club-header {
        /* padding-top: 1rem; */
        margin-left: 1rem;
    }
    .full-table-button {
        padding: 0.5rem 0;
        background-color: var(
            --league-table-2-color-footer-background,
            #164194
        );
        color: var(--league-table-2-dark-background-font-color, #fff);
        border-bottom-left-radius: var(
            --league-table-2-table-border-radius,
            2rem
        );
        border-bottom-right-radius: var(
            --league-table-2-table-border-radius,
            2rem
        );
        overflow: hidden;
    }
    .home-team {
        /* padding: 0.5rem 0; */
        background-color: var(
            --league-table-2-color-heading-background,
            #164194
        ) !important;
        color: var(--league-table-2-dark-background-font-color, #fff);
    }
    .home-team tr {
            background-color: var(
                --league-table-2-color-heading-background,
                #164194
            ) !important;
        }
    .home-team td {
            background-color: var(
                --league-table-2-color-heading-background,
                #164194
            ) !important;
            box-shadow:
                -1px 0px 0px 0px
                    var(--league-table-2-color-heading-background, #164194),
                1px 0px 0px 0px
                    var(--league-table-2-color-heading-background, #164194);
        }
    .rounded-border {
        overflow: hidden;
    }
    .rounded-border td:first-child {
            border-radius: 2rem 0 0 2rem;
        }
    .rounded-border td:last-child {
            border-radius: 0 2rem 2rem 0;
        }
    .cup-group-name {
        background-color: var(
            --stands-table-group-header-bg-color,
            #595959
        ) !important;
        color: #fff !important;
    }
    .group-name-td {
        text-align: left !important;
        padding: 1rem !important;
    }</style>
