import { generateRootObjectSchema, generateArraySchema, generateColorSchema, generateObjectSchema, generateStringSchema, generateBooleanSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ButtonParticleSpec, ImageParticleSpec, TextParticle, TextParticleSpec } from "components/cards/particles/YinzCam";

const propsPlayerName = {
    size: "1.25rem",
    weight: "400",
}

const propsGoalMinute = {
    size: "1.25rem",
    weight: "400",
}

const propsTeamIcon = {
    container: {
        width: "12rem",
        height: "12rem",
    }
}

const propsTeamName = {
    size: "1.5rem", weight: "700", lineHeight: "1",
}

const propsLiveMinute = { size: "2.25rem", weight: "800" }

const propsScore = { size: "7rem", weight: "800" };

const propsVenueName = { size: "1.5rem", weight: "400" };

export const specification = new BaseAtomSpec("MC-Header-B", generateRootObjectSchema({
    container: generateAtomContainerSchema(),
    backgroundImage: ImageParticleSpec.generateSchema("Background Image", "Background Image"),
    backgroundImageTint: generateBooleanSchema("Apply Background Image Tint", "Apply tint to the background image?"),
    backgroundImageTintColor: generateColorSchema("Background Image Tint Color", "The tint color to apply to the background image. Defaults to the current theme's accent color."),
    overlayPadding: generateStringSchema("Overlay Padding", "Specify a padding for overlay"),
    matchDateTime: generateStringSchema("Match Date Time", "Enter match date and time is ISO format"),
    txtMatchDate: TextParticleSpec.generateSchema("Match Date", "The styling for the match date.", {
        defaultValues: { size: "1.25rem", weight: "400", wrap: "nowrap" },
        uneditableFields: { text: true }
    }),
    txtMatchTime: TextParticleSpec.generateSchema("Match Time", "The styling for the match Time.", {
        defaultValues: { size: "4rem", weight: "700", wrap: "nowrap", transform: "uppercase" },
        uneditableFields: { text: true }
    }),
    txtFlagLineOne: TextParticleSpec.generateSchema("Flag Line One", "Flag Line One", {
        defaultValues: { size: "1.25rem", weight: "700", transform: "uppercase", container: { centerContentHorizontally: false, centerContentVertically: false } }
    }),
    txtFlagLineTwo: TextParticleSpec.generateSchema("Flag Line Two", "Flag Line Two", {
        defaultValues: { size: "1.25rem", weight: "400", container: { centerContentHorizontally: false, centerContentVertically: false } }
    }),
    matchStatus: generateStringSchema("Match Status", "Select a match status", {
        choices: ['pre', 'post', 'live'],
        choiceTitles: ['Pre', 'Post', 'Live'],
        defaultValue: 'pre',
    }),
    txtFullTime: TextParticleSpec.generateSchema("Full Time", "Full time text", {
        defaultValues: { size: "2rem", weight: "700", transform: "uppercase", text: "FT" }
    }),
    txtHalfTime: TextParticleSpec.generateSchema("Half Time", "Half Time Text", {
        defaultValues: { size: "1.25rem", weight: "400", transform: "uppercase" }
    }),
    homePlayerGoals: generateArraySchema("Home Players", "Players of Home Team",
        generateObjectSchema("Player", "Player details", {
            name: TextParticleSpec.generateSchema("Player Name", "Player Name", {
                defaultValues: propsPlayerName
            }),
            goalMinute: TextParticleSpec.generateSchema("Goal Minute", "Goal Minute", {
                defaultValues: propsGoalMinute
            }),
            icon: ImageParticleSpec.generateSchema("Image", "Image", {
                defaultValues: {
                    container: { width: "1rem", height: "1rem" },
                    source: "https://resources-uk.yinzcam.com/soccer/shared/soccer_ball.png", sizing: "contain"
                }
            })
        })
    ),
    awayPlayerGoals: generateArraySchema("Away Players", "Players of Home Team",
        generateObjectSchema("Player", "Player details", {
            name: TextParticleSpec.generateSchema("Player Name", "Player Name", {
                defaultValues: propsPlayerName
            }),
            goalMinute: TextParticleSpec.generateSchema("Goal Minute", "Goal Minute", {
                defaultValues: propsGoalMinute
            }),
            icon: ImageParticleSpec.generateSchema("Image", "Image", {
                defaultValues: {
                    container: { width: "1rem", height: "1rem" },
                    source: "https://resources-uk.yinzcam.com/soccer/shared/soccer_ball.png", sizing: "contain"
                }
            })
        })
    ),
    homeTeamDetails: generateObjectSchema("Home Team", "Home team icon and name", {
        icon: ImageParticleSpec.generateSchema("Team Icon", "Team Icon", {
            defaultValues: propsTeamIcon
        }),
        name: TextParticleSpec.generateSchema("Team Name", "Team Name", {
            defaultValues: propsTeamName
        }),
        tricode: generateStringSchema("Home Tricode", "Home Tricode")
    }),
    awayTeamDetails: generateObjectSchema("Away Team", "Home team icon and name", {
        icon: ImageParticleSpec.generateSchema("Team Icon", "Team Icon", {
            defaultValues: propsTeamIcon
        }),
        name: TextParticleSpec.generateSchema("Team Name", "Team Name", {
            defaultValues: propsTeamName
        }),
        tricode: generateStringSchema("Away Tricode", "Away Tricode")
    }),
    imgLeagueIcon: ImageParticleSpec.generateSchema("League Icon", "Upload Image Icon", {
        defaultValues: { container: { height: "5rem" }, sizing: "contain" }
    }),
    imgLiveIcon: ImageParticleSpec.generateSchema("Live Icon", "Live Icon", {
        defaultValues: { container: { width: "2rem", height: "2rem" } }
    }),
    txtLive: TextParticleSpec.generateSchema("LIVE", "LIVE", {
        defaultValues: { size: "1.5rem", weight: "400", text: "LIVE" }
    }),
    txtMinute: TextParticleSpec.generateSchema("Minutes", "Minutes", { defaultValues: propsLiveMinute }),
    txtHomeScore: TextParticleSpec.generateSchema("Home Score", "Home Score", {
        defaultValues: propsScore
    }),
    txtAwayScore: TextParticleSpec.generateSchema("Away Score", "Away Score", {
        defaultValues: propsScore
    }),
    txtVenueName: TextParticleSpec.generateSchema("Venue Name", "Venue Name", {
        defaultValues: propsVenueName
    }),
    colorFlagBanner: generateColorSchema("Flag Color", "Flag Color"),
    imgRefereeIcon: ImageParticleSpec.generateSchema("Referee Icon", "Referee Icon", {
        defaultValues: { container: { width: "5rem", height: "5rem" }, sizing: "cover" }
    }),
    txtRefereeName: TextParticleSpec.generateSchema("Referee Name", "Referee Name", {
        defaultValues: { size: "1.25rem", weight: "700" }
    }),
    txtRefereePosition: TextParticleSpec.generateSchema("Referee Name", "Referee Name", {
        defaultValues: { size: "1.25rem", weight: "400" }
    }),
    btnTwitterHashtag: ButtonParticleSpec.generateSchema("Hashtag text", "Hashtag text", {
        defaultValues: { text: { size: "1.5rem", weight: "800" }, showImage: false }
    }),
    btnCta: ButtonParticleSpec.generateSchema("CTA Icon", "CTA Icon", {
        defaultValues: { text: { size: "1.25rem", weight: "700" }, showImage: false }
    }),
    venueAddress: generateStringSchema("Venue Address", "Venue Address"),
    imgSocialIcon: ImageParticleSpec.generateSchema("Social Icon", "Social Icon", {
        defaultValues: { container: { width: "2.0rem", height: "2.0rem" }, sizing: "cover", icon: "yc_icon_twitter_x" }
    }),
    homeLogoLightUrl: generateStringSchema("Home Logo Light", "Home Logo Light"),
    awayLogoLightUrl: generateStringSchema("Away Logo Light", "Away Logo Light"),
    leagueLogoLightUrl: generateStringSchema("League Logo Light", "League Logo Light"),
    isLightLogoReqd: generateBooleanSchema("Light Logo Required?", "Light Logo Required?", {
        defaultValue: false
    }),
    btnListenLive: ButtonParticleSpec.generateSchema("Listen Live", "Listen Live", {
        defaultValues: {
            text: {
                size: "2rem", weight: "700", text: "LISTEN LIVE",
                container: { padding: "0.25rem" }
            },
            container: {
                border: { visible: true, radius: "5rem" },
                padding: "0.75rem 1.5rem", width: "auto"
            },
            image: {
                container: {
                    width: "2rem",
                    height: "2rem",
                    padding: "0 0.5rem 0 0"
                }
            }
        }
    }),
    horizontalLineColor: generateColorSchema("Horizontal Line Color", "Horizontal Line Color"),
    penaltyText: TextParticleSpec.generateSchema("Penalty Text", "Penalty Text", {
        defaultValues: {
            size: "1.5rem", weight: "500"
        }
    }),
    headerBackgroundImageUrl: generateStringSchema("Header Background Image URL", "Header Background Image URL")
}));    