<script lang="ts">import ROOT from '../inversify.config';
import { getToken } from 'inversify-token';
import { JanusInternationalizationManagerToken } from 'js/i18n';
import FontLoader from '../fonts/loaders/FontLoader-DEFAULT.svelte';
const league = CONFIG.league;
const tricode = CONFIG.tricode;
const i18nManager = getToken(ROOT, JanusInternationalizationManagerToken);
const langStore = i18nManager.getCurrentLanguageStore();
$: lang = $langStore;
</script>

<FontLoader {league} {tricode} {lang} />
