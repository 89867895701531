import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { AbstractTemporalCachedReactiveMicrocomponent, ReactiveMicrocomponent } from 'yinzcam-rma';
import { YinzCamAPIRequest } from './YinzCamAPIRequest';
import { YinzCamAPIResponse } from './YinzCamAPIResponse';
import { LOCAL_TIMESTAMP_HEADER_KEY } from './constants';
import { YinzCamAPIRequestParameters } from './YinzCamAPIRequestParameters';
import { YinzCamAPIRequestParameterComponent } from './YinzCamAPIRequestParameterComponent';
import { buildAxiosRequest, buildYinzCamAPIResponse } from './utilities';
import { YinzCamAPIConfig } from './YinzCamAPIConfig';

export class TemporalCachedYinzCamAPIRequestComponent extends AbstractTemporalCachedReactiveMicrocomponent<YinzCamAPIResponse, [ YinzCamAPIRequestParameters ]> {
  private readonly appConfig: YinzCamAPIConfig;
  private readonly axios: AxiosInstance;
  private readonly service: string;
  private readonly makeRequest: (params?: YinzCamAPIRequestParameters) => Promise<YinzCamAPIResponse>;

  public constructor(name: string, appConfig: YinzCamAPIConfig, axios: AxiosInstance, service: string, makeRequest: (params?: YinzCamAPIRequestParameters) => Promise<YinzCamAPIResponse>, parametersInput: YinzCamAPIRequestParameterComponent) {
    super(name, appConfig, parametersInput);
    this.appConfig = appConfig;
    this.axios = axios;
    this.service = service;
    this.makeRequest = makeRequest;
  }

  protected override async execUpdate(now: number, previousOutput: YinzCamAPIResponse, $parameters: YinzCamAPIRequestParameters): Promise<YinzCamAPIResponse> {
    const rsp = await this.makeRequest($parameters);

    // set the upstream timer to the ttl
    this.state.ttl = rsp.ttl;

    return rsp;
  }
}
