import { generateRootObjectSchema, generateArraySchema, generateObjectSchema, generateColorSchema, generateStringSchema, generateBooleanSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ImageParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";
import { matchCenterStatList } from "components/cards/utilities/constants";

export const specification = new BaseAtomSpec("MC-PossessionTrackerAtom", generateRootObjectSchema({
    container: generateAtomContainerSchema(),
    containerPadding: generateStringSchema("Container Padding", "Padding for the container"),
    txtPossessionText: TextParticleSpec.generateSchema("Possession Text", "Possession heading", {
        defaultValues: { size: "2rem", weight: "900", transform: "uppercase", color: "var(--theme-mode-highlight-color, unset)" }
    }),
    statsList:
        generateStringSchema("Select Stat", "Select stat from list", {
            choices: matchCenterStatList,
            choiceTitles: matchCenterStatList,
            defaultValue: matchCenterStatList[0],
        }),
    matchStats: generateArraySchema("Shot Stats", "Select shot stats to compare Home vs Away",
        generateObjectSchema("Shot Stat", "Shot Stat", {
            statName: generateStringSchema("Stat Name", "Stat Name"),
            homeValue: generateStringSchema("Home Value", "Home Value"),
            awayValue: generateStringSchema("Away Value", "Away Value"),
            maxValue: generateStringSchema('Max Value', "Max Value")
        })),
    txtHomeTeamPercent: TextParticleSpec.generateSchema("Home Team Possession", "Home Team Possession", {
        defaultValues: { size: "2rem", weight: "700" }
    }),
    txtAwayTeamPercent: TextParticleSpec.generateSchema("Away Team Possession", "Away Team Possession", {
        defaultValues: { size: "2rem", weight: "700" }
    }),
    imgHomeTeamLogo: ImageParticleSpec.generateSchema("Home Team Logo", "Home Team Logo", {
        defaultValues: { container: { width: "5rem", height: "5rem" } }
    }),
    imgAwayTeamLogo: ImageParticleSpec.generateSchema("Away Team Logo", "Away Team Logo", {
        defaultValues: { container: { width: "5rem", height: "5rem" } }
    }),
    imgFieldMap: ImageParticleSpec.generateSchema("Field Map", "Field Map", {
        defaultValues: { sizing: "cover" }
    }),
    myTeamColor: generateColorSchema("My Team Color", "Pick a color for home team"),
    opponentTeamColor: generateColorSchema("Opponent Team Color", "Pick a color for away team"),
    homeTricode: generateStringSchema("Home Tricode", "Home Tricode"),
    awayTricode: generateStringSchema("Home Tricode", "Home Tricode"),
}));