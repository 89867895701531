<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import _ from "lodash";
import { BaseAtom, getSignonManager, } from "components/cards/atoms";
import { ButtonParticle, TextParticle, } from "components/cards/particles/YinzCam";
import ImageParticle from "components/cards/particles/YinzCam/Core/Image/ImageParticle.svelte";
import { Toggle } from "framework7-svelte";
import RichTextParticle from "components/cards/particles/YinzCam/Core/RichText/RichTextParticle.svelte";
import { t } from "js/i18n";
import { eraseCookie } from "js/cookies";
import { resolveUrl } from "js/url";
import { f7 } from 'framework7-svelte';
export let container = undefined;
export let outerBoxPadding = undefined;
export let outerBoxBackgroundColor = undefined;
export let outerBoxBorderRadius = undefined;
export let titleRowPadding = undefined;
export let titleText = undefined;
export let innerBoxPadding = undefined;
export let innerBoxBackgroundColor = undefined;
export let innerBoxBorderRadius = undefined;
export let innerBoxDividerColor = undefined;
export let leftSideWidth = undefined;
export let leftSideActiveButtonTemplate = undefined;
export let leftSideInactiveButtonTemplate = undefined;
export let leftRightGap = undefined;
export let formBlockGap = undefined;
export let profileImageTemplate = undefined;
export let formBlockTitleTextTemplate = undefined;
export let formFieldTitleTextTemplate = undefined;
export let formFieldDescriptionTextTemplate = undefined;
export let formFieldValueTextTemplate = undefined;
export let formBlockTitlePadding = undefined;
export let formTitleGap = undefined;
export let formGridRowGap = undefined;
export let saveButtonTemplate = undefined;
const ICON_OK = '<svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.39672 15.5679C8.0772 15.2586 7.55916 15.2586 7.23964 15.5679C6.92012 15.8772 6.92012 16.3787 7.23964 16.688L12.7273 22L24.7604 10.352C25.0799 10.0427 25.0799 9.54126 24.7604 9.23197C24.4408 8.92268 23.9228 8.92268 23.6033 9.23197L12.7273 19.7599L8.39672 15.5679Z" fill="white"/></svg>';
const ICON_KO = '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12 14.8235C12.6334 14.8235 13.1616 14.3391 13.2161 13.708L13.4118 11.4445V5.64706C13.4118 4.86736 12.7797 4.23529 12 4.23529C11.2203 4.23529 10.5882 4.86736 10.5882 5.64706V11.4445L10.7839 13.708C10.8384 14.3391 11.3666 14.8235 12 14.8235ZM12 19.7647C12.7797 19.7647 13.4118 19.1326 13.4118 18.3529C13.4118 17.5732 12.7797 16.9412 12 16.9412C11.2203 16.9412 10.5882 17.5732 10.5882 18.3529C10.5882 19.1326 11.2203 19.7647 12 19.7647Z" fill="white"/></svg>';
const TOAST_DURATION = 3000;
const club_all_fields = [
    "optinmailclub",
    "optinsmsclub",
    "optintelclub",
    "optinemailclub",
];
const club_email_fields = [
    "optinemailclubnews",
    "optinemailcorporateandhospitality",
    "optinemailmerchandise",
    "optinemailticketinformation",
    "optinemailwomens",
    "optinemailotherproductsandservices",
];
const club_partner_all_fields = [
    "optinmailpartner",
    "optinsmspartner",
    "optintelpartner",
    "optinemailthirdparty",
];
//const club_lc_rev_map = Object.fromEntries([].concat(club_all_fields, club_email_fields, club_partner_all_fields).map((v) => [ v.toLowerCase(), v ]));
const sections = [
    { title: "My Profile", slug: "my-profile" },
    { title: "Settings", slug: "settings" },
];
let selectedSection = "my-profile";
let atomWidthPx;
const sso = getSignonManager();
const profileComp = sso.getDefaultProfileSegmentComponent();
const profile = profileComp.store;
$: loginStatus = sso.getStatusComponent().store;
$: isLoggedIn = $loginStatus.loggedIn;
let updatedProfile = null; // set after at least one successful save is made to the profile
let dirtyProfile = null; // set after the user makes changes to their profile and on first load
$: saveEnabled = _.isNil(updatedProfile) !== _.isNil(dirtyProfile) || !_.isEqual(updatedProfile, dirtyProfile);
//$: console.log('UPDATED PROFILE', updatedProfile);
//$: console.log('DIRTY PROFILE', dirtyProfile);
//$: console.log('SAVE ENABLED', saveEnabled);
let profileLoaded = false;
let profileFieldNotEditable = {};
$: profileData = (function (_profile, _dirtyProfile) {
    if (_dirtyProfile) {
        _profile = _dirtyProfile;
    }
    else if (profileLoaded) {
        return profileData;
    }
    if (!_profile || Object.keys(_profile).length === 0) {
        return {};
    }
    profileLoaded = true;
    ["first_name", "last_name", "birth_date"].forEach((key) => (profileFieldNotEditable[key] = !!_profile[key]));
    const ret = _.cloneDeep(_profile || {});
    if (!dirtyProfile) {
        dirtyProfile = _.cloneDeep(ret);
    }
    if (!updatedProfile) {
        updatedProfile = _.cloneDeep(ret);
    }
    return ret;
})($profile, dirtyProfile);
$: profileEditable = profileLoaded;
/*
async function updateProfile(profileData: Record<string, string>) {
  dirtyProfile = _.cloneDeep(profileData);
}
*/
async function saveProfile(profileData) {
    var _a;
    // filter duplicates out in favor of ones in all caps
    const oldUpdatedProfile = updatedProfile;
    try {
        updatedProfile = _.cloneDeep(dirtyProfile); //result.data; //_.cloneDeep(profileData);
        const toDelete = [];
        for (const key in dirtyProfile) {
            if (Object.hasOwn(dirtyProfile, key)) {
                const lowerKey = key.toLowerCase();
                if (key !== lowerKey) {
                    toDelete.push(key);
                }
            }
        }
        const profileDataCleaned = _.cloneDeep(dirtyProfile);
        for (const key of toDelete) {
            delete profileDataCleaned[key];
        }
        const result = await sso.updateDefaultProfileSegment(profileDataCleaned);
        if (!result.success) {
            throw new Error((_a = result.errorMessage) === null || _a === void 0 ? void 0 : _a.description);
        }
        f7.toast.show({
            icon: ICON_OK,
            text: 'Profile information saved.',
            position: 'bottom',
            horizontalPosition: 'center',
            closeTimeout: TOAST_DURATION,
            cssClass: 'profile-details-atom-happy-toast',
        });
    }
    catch (e) {
        // restore old profile
        updatedProfile = oldUpdatedProfile;
        f7.toast.show({
            icon: ICON_KO,
            text: 'Failed to save profile information. Please try again later.',
            position: 'bottom',
            horizontalPosition: 'center',
            closeTimeout: TOAST_DURATION,
            cssClass: 'profile-details-atom-sad-toast',
        });
    }
}
/*
const updateProfileThrottled = _.throttle(updateProfile, 2000, {
  leading: false,
  trailing: true,
});
*/
function changeProfileLocal(field, value) {
    //console.log('changeProfileLocal', field, value);
    /*
    if (profileData[field] === value) {
      return false;
    }
    profileData[field] = value;
    updateProfile(profileData);
    */
    if (dirtyProfile[field] === value) {
        return false;
    }
    dirtyProfile[field] = value;
    dirtyProfile = dirtyProfile;
    return true;
}
function onTextChange(field, e) {
    changeProfileLocal(field, e.target.innerText);
}
function onSelectChange(field, e) {
    changeProfileLocal(field, e.target.value);
}
function onInputKeypress(field, e) {
    if (e.key === 'Enter') {
        e.target.blur();
        e.preventDefault();
    }
}
function onInputChange(field, e) {
    changeProfileLocal(field, e.target.value);
}
function onToggleChange(field, val) {
    const strVal = (val ? "true" : "false");
    if (!changeProfileLocal(field, strVal)) {
        return;
    }
    if (field === "optinallclub") {
        club_all_fields.forEach((f) => changeProfileLocal(f, strVal));
        club_email_fields.forEach((f) => changeProfileLocal(f, strVal));
    }
    if (field === "optinemailclub") {
        club_email_fields.forEach((f) => changeProfileLocal(f, strVal));
    }
    if (field === "optinpartner") {
        club_partner_all_fields.forEach((f) => changeProfileLocal(f, strVal));
    }
    const club_global_email_optin = !!club_email_fields.find((f) => dirtyProfile[f] === "true");
    changeProfileLocal("optinemailclub", club_global_email_optin ? "true" : "false");
    const club_global_optin = !!club_all_fields.find((f) => dirtyProfile[f] === "true");
    changeProfileLocal("optinallclub", club_global_optin ? "true" : "false");
    const club_partner_optin = !!club_partner_all_fields.find((f) => dirtyProfile[f] === "true");
    changeProfileLocal("optinpartner", club_partner_optin ? "true" : "false");
}
function mapSupporterLevelNames(_profile) {
    const sln = (_profile["supporter_scheme_level_names"] || "").toLowerCase();
    if (sln.includes("exec box season ticket holder")) {
        return "Exec Box Season Ticket Holder";
    }
    else if (sln.includes("corporate season ticket holder")) {
        return "Corporate Season Ticket Holder";
    }
    else if (sln.includes("international member")) {
        return "International Member";
    }
    else if (sln.includes("fox member")) {
        return "Fox Member";
    }
    else if (sln.includes("season ticket holder")) {
        return "Season Ticket Holder";
    }
    return "Digital Member";
}
function handleLogout() {
    eraseCookie("_sa_sso_upid");
    sso.logout();
    resolveUrl("home").func();
}
$: {
    if (atomWidthPx <= 950 && atomWidthPx > 600) {
        formFieldValueTextTemplate = Object.assign({}, _.merge({}, formFieldValueTextTemplate, {
            container: { width: "30rem" },
        }));
    }
}
</script>

<BaseAtom
  _isotope="ProfileDetailsAtom"
  _cssVariablePrefix="profile-details"
  {...container}
  bind:_clientWidthPx={atomWidthPx}
  {outerBoxPadding}
  {outerBoxBackgroundColor}
  {outerBoxBorderRadius}
  {titleRowPadding}
  {leftSideWidth}
  {leftRightGap}
  {innerBoxPadding}
  {innerBoxBackgroundColor}
  {innerBoxBorderRadius}
  {innerBoxDividerColor}
  {formBlockGap}
  {formBlockTitlePadding}
  {formGridRowGap}
  {formTitleGap}
  formValueContainerWidth={formFieldValueTextTemplate?.container?.width || ""}
>
  <div class="outer-box" class:mobile-layout={atomWidthPx <= 950}>
    <div class="title-row">
      <TextParticle _block={true} {...titleText} />
    </div>
    <div class="inner-box">
      <div class="left-side">
        {#each sections as section (section.slug)}
          <div id={`${section?.slug}-button`} class={`profile-button ${section?.slug}-button`}>
            <ButtonParticle
              on:click={() => (selectedSection = section.slug)}
              {..._.merge(
                {},
                selectedSection === section.slug
                  ? leftSideActiveButtonTemplate
                  : leftSideInactiveButtonTemplate,
                { text: { text: section.title } },
              )}
            />
          </div>
        {/each}
        <!-- <div class="left-side-spacer"></div> -->
        <div class="logout-button">
          <ButtonParticle
            {..._.merge({}, leftSideInactiveButtonTemplate, {
              text: { text: `${$t("Change Your Password")}` },
            })}
            link="https://signin.lcfc.com/Dashboard/ChangePassword"
          />
        </div>
        <div class="logout-button">
          <ButtonParticle
            {..._.merge({}, leftSideInactiveButtonTemplate, {
              text: { text: `${$t("Linked Accounts")}` },
            })}
            link="https://signin.lcfc.com/Dashboard/LinkAccount"
          />
        </div>
        <div class="logout-button">
          <ButtonParticle
            {..._.merge({}, leftSideInactiveButtonTemplate, {
              text: { text: `${$t("Registration Help")}` },
            })}
            link="https://signin.lcfc.com/Static/Faqs"
          />
        </div>
        <div class="logout-button">
          <ButtonParticle
            on:click={handleLogout}
            {..._.merge({}, leftSideInactiveButtonTemplate, {
              text: { text: `${$t("Sign Out")}` },
            })}
          />
        </div>
      </div>
      <div class="right-side">
        <div
          class="form-section"
          class:form-section-active={selectedSection === "my-profile"}
        >
          <div class="form-block no-border-block">
            <div class="header-block">
              <div class="profile-image">
                <ImageParticle
                  _block={true}
                  {..._.merge({}, profileImageTemplate, {
                    source:
                      profileData?.["image_url"] ||
                      "https://resources-au.yinzcam.com/apl/shared/headshots/silhouette.png",
                  })}
                />
              </div>
              <div class="profile-header">
                <div class="profile-header-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formBlockTitleTextTemplate, {
                      text:
                        `${profileData?.["first_name"] || ""} ${profileData?.["last_name"] || ""}`.trim() ||
                        "Filbert Fox",
                    })}
                  />
                </div>
                <div class="form-field">
                  <div class="form-field-title">
                    <TextParticle
                      _block={true}
                      {..._.merge({}, formFieldTitleTextTemplate, {
                        text: mapSupporterLevelNames(profileData),
                      })}
                    />
                  </div>
                  {#if !isNaN(parseInt(profileData?.["supporter_ticketing_member_id"]))}
                    <div class="form-field-description">
                      <RichTextParticle
                        _block={true}
                        {..._.merge({}, formFieldDescriptionTextTemplate, {
                          html:
                            profileData?.["supporter_ticketing_member_id"] &&
                            _.is
                              ? `Supporter Number: ${profileData?.["supporter_ticketing_member_id"]}`
                              : "",
                        })}
                      />
                    </div>
                  {/if}
                  {#if !isNaN(parseFloat(profileData?.["supporter_account_balance"]))}
                    <div class="form-field-description">
                      <RichTextParticle
                        _block={true}
                        {..._.merge({}, formFieldDescriptionTextTemplate, {
                          html: `Foxes Rewards: £${parseFloat(profileData?.["supporter_account_balance"]).toFixed(2)}`,
                        })}
                      />
                    </div>
                  {/if}
                </div>
              </div>
            </div>
          </div>
          <div class="form-block">
            <div class="form-block-title">
              <TextParticle
                _block={true}
                {..._.merge({}, formBlockTitleTextTemplate, {
                  text: "Personal Information",
                })}
              />
            </div>
            <div class="field-grid">
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Title",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <div class="form-field-value">
                    <select
                      value={profileData?.["title"] || ""}
                      on:input={(e) => onSelectChange("title", e)}
                    >
                      <option
                        selected={profileData?.["title"] === "Mr"}
                        value="Mr">Mr</option
                      >
                      <option
                        selected={profileData?.["title"] === "Mrs"}
                        value="Mrs">Mrs</option
                      >
                      <option
                        selected={profileData?.["title"] === "Ms"}
                        value="Ms">Ms</option
                      >
                      <option
                        selected={profileData?.["title"] === "Miss"}
                        value="Miss">Miss</option
                      >
                      <option
                        selected={profileData?.["title"] === "Master"}
                        value="Master">Master</option
                      >
                      <option
                        selected={profileData?.["title"] === "Other"}
                        value="Other">Other</option
                      >
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Forename",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <TextParticle
                    _block={true}
                    _editable={profileEditable &&
                      !profileFieldNotEditable["first_name"]}
                    {..._.merge({}, formFieldValueTextTemplate, {
                      text: profileData?.["first_name"] || "",
                    })}
                    on:input={(e) => onTextChange("first_name", e)}
                    on:keydown={(e) => onInputKeypress("first_name", e)}
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Middle Names (or Initials)",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <TextParticle
                    _block={true}
                    _editable={profileEditable &&
                      !profileFieldNotEditable["middle_name"]}
                    {..._.merge({}, formFieldValueTextTemplate, {
                      text: profileData?.["middle_name"] || "",
                    })}
                    on:input={(e) => onTextChange("middle_name", e)}
                    on:keydown={(e) => onInputKeypress("middle_name", e)}
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Surname",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <TextParticle
                    _block={true}
                    _editable={profileEditable &&
                      !profileFieldNotEditable["last_name"]}
                    {..._.merge({}, formFieldValueTextTemplate, {
                      text: profileData?.["last_name"] || "",
                    })}
                    on:input={(e) => onTextChange("last_name", e)}
                    on:keydown={(e) => onInputKeypress("last_name", e)}
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Date of Birth",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <input
                    type="date"
                    class="date-of-birth-style"
                    value={profileData?.["birth_date"] || ""}
                    on:input={(e) => onInputChange("birth_date", e)}
                    on:keydown={(e) => onInputKeypress("birth_date", e)}
                    disabled={!(
                      profileEditable && !profileFieldNotEditable["birth_date"]
                    )}
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Gender",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <select
                    value={profileData?.["gender"] || ""}
                    on:input={(e) => onSelectChange("gender", e)}
                    on:keydown={(e) => onInputKeypress("first_name", e)}
                    disabled={!(
                      profileEditable && !profileFieldNotEditable["gender"]
                    )}
                    class="date-of-birth-style"
                  >
                    <option
                      selected={profileData?.["gender"] === "Prefer not to Say"}
                      value="Prefer not to Say">Prefer not to Say</option
                    >
                    <option
                      selected={profileData?.["gender"] === "Male"}
                      value="Male">Male</option
                    >
                    <option
                      selected={profileData?.["gender"] === "Female"}
                      value="Female">Female</option
                    >
                  </select>
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Email",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <TextParticle
                    _block={true}
                    _editable={false}
                    {..._.merge({}, formFieldValueTextTemplate, {
                      text: profileData?.["email"] || "",
                    })}
                  />
                </div>
                <div class="form-field-link">
                  <a
                    class="external"
                    href="https://signin.lcfc.com/Dashboard/ChangeEmail"
                    >{$t("Change email address")}</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="form-block field-grid-block">
            <div class="form-block-title">
              <TextParticle
                _block={true}
                {..._.merge({}, formBlockTitleTextTemplate, {
                  text: "Contact Information",
                })}
              />
            </div>
            <div class="field-grid">
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Address",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <TextParticle
                    _block={true}
                    _editable={profileEditable &&
                      !profileFieldNotEditable["address_street_1"]}
                    {..._.merge({}, formFieldValueTextTemplate, {
                      text: profileData?.["address_street_1"] || "",
                    })}
                    on:input={(e) => onTextChange("address_street_1", e)}
                    on:keydown={(e) => onInputKeypress("address_street_1", e)}
                  />
                </div>
                <div class="form-field-value">
                  <TextParticle
                    _block={true}
                    _editable={profileEditable &&
                      !profileFieldNotEditable["address_street_2"]}
                    {..._.merge({}, formFieldValueTextTemplate, {
                      text: profileData?.["address_street_2"] || "",
                    })}
                    on:input={(e) => onTextChange("address_street_2", e)}
                    on:keydown={(e) => onInputKeypress("address_street_2", e)}
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Town/City",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <TextParticle
                    _block={true}
                    _editable={profileEditable &&
                      !profileFieldNotEditable["address_city"]}
                    {..._.merge({}, formFieldValueTextTemplate, {
                      text: profileData?.["address_city"] || "",
                    })}
                    on:input={(e) => onTextChange("address_city", e)}
                    on:keydown={(e) => onInputKeypress("address_city", e)}
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "County",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <TextParticle
                    _block={true}
                    _editable={profileEditable &&
                      !profileFieldNotEditable["address_division_1"]}
                    {..._.merge({}, formFieldValueTextTemplate, {
                      text: profileData?.["address_division_1"] || "",
                    })}
                    on:input={(e) => onTextChange("address_division_1", e)}
                    on:keydown={(e) => onInputKeypress("address_division_1", e)}
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Postcode",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <TextParticle
                    _block={true}
                    _editable={profileEditable &&
                      !profileFieldNotEditable["address_postal"]}
                    {..._.merge({}, formFieldValueTextTemplate, {
                      text: profileData?.["address_postal"] || "",
                    })}
                    on:input={(e) => onTextChange("address_postal", e)}
                    on:keydown={(e) => onInputKeypress("address_postal", e)}
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Country",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <select
                    value={profileData?.["address_country"] || ""}
                    on:input={(e) => onSelectChange("address_country", e)}
                    disabled={!(
                      profileEditable &&
                      !profileFieldNotEditable["address_country"]
                    )}
                  >
                    <option
                      selected={profileData?.["address_country"] === "GB"}
                      value="GB">United Kingdom</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "AF"}
                      value="AF">Afghanistan</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "AL"}
                      value="AL">Albania</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "DZ"}
                      value="DZ">Algeria</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "AS"}
                      value="AS">American Samoa</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "AD"}
                      value="AD">Andorra</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "AO"}
                      value="AO">Angola</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "AI"}
                      value="AI">Anguilla</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "AQ"}
                      value="AQ">Antarctica</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "AG"}
                      value="AG">Antigua and Barbuda</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "AR"}
                      value="AR">Argentina</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "AM"}
                      value="AM">Armenia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "AW"}
                      value="AW">Aruba</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "AU"}
                      value="AU">Australia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "AT"}
                      value="AT">Austria</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "AZ"}
                      value="AZ">Azerbaijan</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "BS"}
                      value="BS">Bahamas</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "BH"}
                      value="BH">Bahrain</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "BD"}
                      value="BD">Bangladesh</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "BB"}
                      value="BB">Barbados</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "BY"}
                      value="BY">Belarus</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "BE"}
                      value="BE">Belgium</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "BZ"}
                      value="BZ">Belize</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "BJ"}
                      value="BJ">Benin</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "BM"}
                      value="BM">Bermuda</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "BT"}
                      value="BT">Bhutan</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "BO"}
                      value="BO">Bolivia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "BQ"}
                      value="BQ">Bonaire, Sint Eustatius and Saba</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "BA"}
                      value="BA">Bosnia and Herzegovina</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "BW"}
                      value="BW">Botswana</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "BV"}
                      value="BV">Bouvet Island</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "BR"}
                      value="BR">Brazil</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "IO"}
                      value="IO">British Indian Ocean Territory</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "BN"}
                      value="BN">Brunei Darussalam</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "BG"}
                      value="BG">Bulgaria</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "BF"}
                      value="BF">Burkina Faso</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "BI"}
                      value="BI">Burundi</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "CV"}
                      value="CV">Cabo Verde</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "KH"}
                      value="KH">Cambodia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "CM"}
                      value="CM">Cameroon</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "CA"}
                      value="CA">Canada</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "KY"}
                      value="KY">Cayman Islands</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "CF"}
                      value="CF">Central African Republic</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "TD"}
                      value="TD">Chad</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "CL"}
                      value="CL">Chile</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "CN"}
                      value="CN">China</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "CX"}
                      value="CX">Christmas Island</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "CC"}
                      value="CC">Cocos (Keeling) Islands</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "CO"}
                      value="CO">Colombia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "KM"}
                      value="KM">Comoros</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "CG"}
                      value="CG">Congo</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "CD"}
                      value="CD">Congo, Democratic Republic of the</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "CK"}
                      value="CK">Cook Islands</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "CR"}
                      value="CR">Costa Rica</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "CI"}
                      value="CI">Côte d'Ivoire</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "HR"}
                      value="HR">Croatia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "CU"}
                      value="CU">Cuba</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "CW"}
                      value="CW">Curaçao</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "CY"}
                      value="CY">Cyprus</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "CZ"}
                      value="CZ">Czechia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "DK"}
                      value="DK">Denmark</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "DJ"}
                      value="DJ">Djibouti</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "DM"}
                      value="DM">Dominica</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "DO"}
                      value="DO">Dominican Republic</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "EC"}
                      value="EC">Ecuador</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "EG"}
                      value="EG">Egypt</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "SV"}
                      value="SV">El Salvador</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "GQ"}
                      value="GQ">Equatorial Guinea</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "ER"}
                      value="ER">Eritrea</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "EE"}
                      value="EE">Estonia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "SZ"}
                      value="SZ">Eswatini</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "ET"}
                      value="ET">Ethiopia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "FK"}
                      value="FK">Falkland Islands (Malvinas)</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "FO"}
                      value="FO">Faroe Islands</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "FJ"}
                      value="FJ">Fiji</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "FI"}
                      value="FI">Finland</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "FR"}
                      value="FR">France</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "GF"}
                      value="GF">French Guiana</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "PF"}
                      value="PF">French Polynesia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "TF"}
                      value="TF">French Southern Territories</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "GA"}
                      value="GA">Gabon</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "GM"}
                      value="GM">Gambia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "GE"}
                      value="GE">Georgia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "DE"}
                      value="DE">Germany</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "GH"}
                      value="GH">Ghana</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "GI"}
                      value="GI">Gibraltar</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "GR"}
                      value="GR">Greece</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "GL"}
                      value="GL">Greenland</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "GD"}
                      value="GD">Grenada</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "GP"}
                      value="GP">Guadeloupe</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "GU"}
                      value="GU">Guam</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "GT"}
                      value="GT">Guatemala</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "GG"}
                      value="GG">Guernsey</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "GN"}
                      value="GN">Guinea</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "GW"}
                      value="GW">Guinea-Bissau</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "GY"}
                      value="GY">Guyana</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "HT"}
                      value="HT">Haiti</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "HM"}
                      value="HM">Heard Island and McDonald Islands</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "VA"}
                      value="VA">Holy See</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "HN"}
                      value="HN">Honduras</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "HK"}
                      value="HK">Hong Kong</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "HU"}
                      value="HU">Hungary</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "IS"}
                      value="IS">Iceland</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "IN"}
                      value="IN">India</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "ID"}
                      value="ID">Indonesia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "IR"}
                      value="IR">Iran (Islamic Republic of)</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "IQ"}
                      value="IQ">Iraq</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "IE"}
                      value="IE">Ireland</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "IM"}
                      value="IM">Isle of Man</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "IL"}
                      value="IL">Israel</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "IT"}
                      value="IT">Italy</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "JM"}
                      value="JM">Jamaica</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "JP"}
                      value="JP">Japan</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "JE"}
                      value="JE">Jersey</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "JO"}
                      value="JO">Jordan</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "KZ"}
                      value="KZ">Kazakhstan</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "KE"}
                      value="KE">Kenya</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "KI"}
                      value="KI">Kiribati</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "KP"}
                      value="KP">Korea (Democratic People's Republic of)</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "KR"}
                      value="KR">Korea, Republic of</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "KW"}
                      value="KW">Kuwait</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "KG"}
                      value="KG">Kyrgyzstan</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "LA"}
                      value="LA">Lao People's Democratic Republic</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "LV"}
                      value="LV">Latvia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "LB"}
                      value="LB">Lebanon</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "LS"}
                      value="LS">Lesotho</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "LR"}
                      value="LR">Liberia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "LY"}
                      value="LY">Libya</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "LI"}
                      value="LI">Liechtenstein</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "LT"}
                      value="LT">Lithuania</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "LU"}
                      value="LU">Luxembourg</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "MO"}
                      value="MO">Macao</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "MG"}
                      value="MG">Madagascar</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "MW"}
                      value="MW">Malawi</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "MY"}
                      value="MY">Malaysia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "MV"}
                      value="MV">Maldives</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "ML"}
                      value="ML">Mali</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "MT"}
                      value="MT">Malta</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "MH"}
                      value="MH">Marshall Islands</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "MQ"}
                      value="MQ">Martinique</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "MR"}
                      value="MR">Mauritania</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "MU"}
                      value="MU">Mauritius</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "YT"}
                      value="YT">Mayotte</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "MX"}
                      value="MX">Mexico</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "FM"}
                      value="FM">Micronesia (Federated States of)</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "MD"}
                      value="MD">Moldova (Republic of)</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "MC"}
                      value="MC">Monaco</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "MN"}
                      value="MN">Mongolia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "ME"}
                      value="ME">Montenegro</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "MS"}
                      value="MS">Montserrat</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "MA"}
                      value="MA">Morocco</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "MZ"}
                      value="MZ">Mozambique</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "MM"}
                      value="MM">Myanmar</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "NA"}
                      value="NA">Namibia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "NR"}
                      value="NR">Nauru</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "NP"}
                      value="NP">Nepal</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "NL"}
                      value="NL">Netherlands</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "NC"}
                      value="NC">New Caledonia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "NZ"}
                      value="NZ">New Zealand</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "NI"}
                      value="NI">Nicaragua</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "NE"}
                      value="NE">Niger</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "NG"}
                      value="NG">Nigeria</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "NU"}
                      value="NU">Niue</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "NF"}
                      value="NF">Norfolk Island</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "MK"}
                      value="MK">North Macedonia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "MP"}
                      value="MP">Northern Mariana Islands</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "NO"}
                      value="NO">Norway</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "OM"}
                      value="OM">Oman</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "PK"}
                      value="PK">Pakistan</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "PW"}
                      value="PW">Palau</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "PS"}
                      value="PS">Palestine, State of</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "PA"}
                      value="PA">Panama</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "PG"}
                      value="PG">Papua New Guinea</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "PY"}
                      value="PY">Paraguay</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "PE"}
                      value="PE">Peru</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "PH"}
                      value="PH">Philippines</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "PN"}
                      value="PN">Pitcairn</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "PL"}
                      value="PL">Poland</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "PT"}
                      value="PT">Portugal</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "PR"}
                      value="PR">Puerto Rico</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "QA"}
                      value="QA">Qatar</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "RE"}
                      value="RE">Réunion</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "RO"}
                      value="RO">Romania</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "RU"}
                      value="RU">Russian Federation</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "RW"}
                      value="RW">Rwanda</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "BL"}
                      value="BL">Saint Barthélemy</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "SH"}
                      value="SH"
                      >Saint Helena, Ascension and Tristan da Cunha</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "KN"}
                      value="KN">Saint Kitts and Nevis</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "LC"}
                      value="LC">Saint Lucia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "MF"}
                      value="MF">Saint Martin (French part)</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "PM"}
                      value="PM">Saint Pierre and Miquelon</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "VC"}
                      value="VC">Saint Vincent and the Grenadines</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "WS"}
                      value="WS">Samoa</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "SM"}
                      value="SM">San Marino</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "ST"}
                      value="ST">Sao Tome and Principe</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "SA"}
                      value="SA">Saudi Arabia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "SN"}
                      value="SN">Senegal</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "RS"}
                      value="RS">Serbia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "SC"}
                      value="SC">Seychelles</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "SL"}
                      value="SL">Sierra Leone</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "SG"}
                      value="SG">Singapore</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "SX"}
                      value="SX">Sint Maarten (Dutch part)</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "SK"}
                      value="SK">Slovakia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "SI"}
                      value="SI">Slovenia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "SB"}
                      value="SB">Solomon Islands</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "SO"}
                      value="SO">Somalia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "ZA"}
                      value="ZA">South Africa</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "GS"}
                      value="GS"
                      >South Georgia and the South Sandwich Islands</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "SS"}
                      value="SS">South Sudan</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "ES"}
                      value="ES">Spain</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "LK"}
                      value="LK">Sri Lanka</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "SD"}
                      value="SD">Sudan</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "SR"}
                      value="SR">Suriname</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "SJ"}
                      value="SJ">Svalbard and Jan Mayen</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "SE"}
                      value="SE">Sweden</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "CH"}
                      value="CH">Switzerland</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "SY"}
                      value="SY">Syrian Arab Republic</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "TW"}
                      value="TW">Taiwan, Province of China</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "TJ"}
                      value="TJ">Tajikistan</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "TZ"}
                      value="TZ">Tanzania, United Republic of</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "TH"}
                      value="TH">Thailand</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "TL"}
                      value="TL">Timor-Leste</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "TG"}
                      value="TG">Togo</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "TK"}
                      value="TK">Tokelau</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "TO"}
                      value="TO">Tonga</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "TT"}
                      value="TT">Trinidad and Tobago</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "TN"}
                      value="TN">Tunisia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "TR"}
                      value="TR">Turkey</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "TM"}
                      value="TM">Turkmenistan</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "TC"}
                      value="TC">Turks and Caicos Islands</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "TV"}
                      value="TV">Tuvalu</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "UG"}
                      value="UG">Uganda</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "UA"}
                      value="UA">Ukraine</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "AE"}
                      value="AE">United Arab Emirates</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "US"}
                      value="US">United States of America</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "UM"}
                      value="UM">United States Minor Outlying Islands</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "UY"}
                      value="UY">Uruguay</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "UZ"}
                      value="UZ">Uzbekistan</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "VU"}
                      value="VU">Vanuatu</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "VE"}
                      value="VE">Venezuela (Bolivarian Republic of)</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "VN"}
                      value="VN">Viet Nam</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "VG"}
                      value="VG">Virgin Islands (British)</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "VI"}
                      value="VI">Virgin Islands (U.S.)</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "WF"}
                      value="WF">Wallis and Futuna</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "EH"}
                      value="EH">Western Sahara</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "YE"}
                      value="YE">Yemen</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "ZM"}
                      value="ZM">Zambia</option
                    >
                    <option
                      selected={profileData?.["address_country"] === "ZW"}
                      value="ZW">Zimbabwe</option
                    >
                  </select>
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Mobile",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <TextParticle
                    _block={true}
                    _editable={profileEditable &&
                      !profileFieldNotEditable["phone_mobile"]}
                    {..._.merge({}, formFieldValueTextTemplate, {
                      text: profileData?.["phone_mobile"] || "",
                    })}
                    on:input={(e) => onTextChange("phone_mobile", e)}
                    on:keydown={(e) => onInputKeypress("phone_mobile", e)}
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Landline",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <TextParticle
                    _block={true}
                    _editable={profileEditable &&
                      !profileFieldNotEditable["phone"]}
                    {..._.merge({}, formFieldValueTextTemplate, {
                      text: profileData?.["phone"] || "",
                    })}
                    on:input={(e) => onTextChange("phone", e)}
                    on:keydown={(e) => onInputKeypress("phone", e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-block no-border-block">
            <div class="profile-save-button" class:button-disabled={!saveEnabled}>
              <ButtonParticle
                on:click={() => saveProfile(profileData)}
                {...saveButtonTemplate}
              />
            </div>
          </div>
        </div>
        <div
          class="form-section"
          class:form-section-active={selectedSection === "settings"}
        >
          <div class="form-block no-border-block">
            <div class="header-block">
              <div class="profile-image">
                <ImageParticle
                  _block={true}
                  {..._.merge({}, profileImageTemplate, {
                    source:
                      profileData?.["image_url"] ||
                      "https://resources-au.yinzcam.com/apl/shared/headshots/silhouette.png",
                  })}
                />
              </div>
              <div class="profile-header">
                <div class="profile-header-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formBlockTitleTextTemplate, {
                      text:
                        `${profileData?.["first_name"] || ""} ${profileData?.["last_name"] || ""}`.trim() ||
                        "Filbert Fox",
                    })}
                  />
                </div>
                <div class="form-field">
                  <div class="form-field-title">
                    <TextParticle
                      _block={true}
                      {..._.merge({}, formFieldTitleTextTemplate, {
                        text: mapSupporterLevelNames(profileData),
                      })}
                    />
                  </div>
                  {#if !isNaN(parseInt(profileData?.["supporter_ticketing_member_id"]))}
                  <div class="form-field-description">
                    <RichTextParticle
                      _block={true}
                      {..._.merge({}, formFieldDescriptionTextTemplate, {
                        html: profileData?.["supporter_ticketing_member_id"] && _.is
                          ? `Supporter Number: ${profileData?.["supporter_ticketing_member_id"]}`
                          : "",
                      })}
                    />
                  </div>
                  {/if}
                  {#if !isNaN(parseFloat(profileData?.["supporter_account_balance"]))}
                  <div class="form-field-description">
                    <RichTextParticle
                      _block={true}
                      {..._.merge({}, formFieldDescriptionTextTemplate, {
                        html: `Foxes Rewards: £${parseFloat(profileData?.["supporter_account_balance"]).toFixed(2)}`
                      })}
                    />
                  </div>
                  {/if}
                </div>
              </div>
            </div>
          </div>
          <div class="form-block">
            <div class="form-block-title">
              <TextParticle
                _block={true}
                {..._.merge({}, formBlockTitleTextTemplate, {
                  text: "Club Communication",
                })}
              />
            </div>
            <div class="field-grid">
              <div class="form-field" style="grid-column: span 2">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Opt-in to receiving communication from the Club",
                    })}
                  />
                </div>
                <div class="form-field-description">
                  <RichTextParticle
                    _block={true}
                    {..._.merge({}, formFieldDescriptionTextTemplate, {
                      html: 'I wish to receive news, promotions, discounts and targeted communications from Leicester City Football Club based on my provided data, and accept the use of my personal data for marketing, profiling and analysis as outlined in the <a href="privacy-policy">Privacy Policy</a>. Clicking this box will select ALL club marketing. To change your preferences on what we send you or how you receive it you can select from the further options below.',
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <Toggle
                    checked={profileData?.["optinallclub"] === "true"}
                    onToggleChange={(val) =>
                      onToggleChange("optinallclub", val)}
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Club Mail",
                    })}
                  />
                </div>
                <div class="form-field-description">
                  <RichTextParticle
                    _block={true}
                    {..._.merge({}, formFieldDescriptionTextTemplate, {
                      html: "If providing my address, I am happy to receive club messages via post.",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <Toggle
                    checked={profileData?.["optinmailclub"] === "true"}
                    onToggleChange={(val) =>
                      onToggleChange("optinmailclub", val)}
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Club SMS",
                    })}
                  />
                </div>
                <div class="form-field-description">
                  <RichTextParticle
                    _block={true}
                    {..._.merge({}, formFieldDescriptionTextTemplate, {
                      html: "If providing my mobile number, I am happy to receive club messages via SMS.",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <Toggle
                    checked={profileData?.["optinsmsclub"] === "true"}
                    onToggleChange={(val) =>
                      onToggleChange("optinsmsclub", val)}
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Club Phone",
                    })}
                  />
                </div>
                <div class="form-field-description">
                  <RichTextParticle
                    _block={true}
                    {..._.merge({}, formFieldDescriptionTextTemplate, {
                      html: "If providing a mobile or landline number, I am happy to receive club messages via phone.",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <Toggle
                    checked={profileData?.["optintelclub"] === "true"}
                    onToggleChange={(val) =>
                      onToggleChange("optintelclub", val)}
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Club Email",
                    })}
                  />
                </div>
                <div class="form-field-description">
                  <RichTextParticle
                    _block={true}
                    {..._.merge({}, formFieldDescriptionTextTemplate, {
                      html: "Clicking this box will select ALL club email. To change your preferences for club email, you can select from the further actions that will appear below.",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <Toggle
                    checked={profileData?.["optinemailclub"] === "true"}
                    onToggleChange={(val) =>
                      onToggleChange("optinemailclub", val)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-block">
            <div class="form-block-title">
              <TextParticle
                _block={true}
                {..._.merge({}, formBlockTitleTextTemplate, {
                  text: "Club Email Communication",
                })}
              />
            </div>
            <div class="field-grid">
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Club News",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <Toggle
                    checked={profileData?.["optinemailclubnews"] === "true"}
                    onToggleChange={(val) =>
                      onToggleChange("optinemailclubnews", val)}
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Corporate and Hospitality",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <Toggle
                    checked={profileData?.[
                      "optinemailcorporateandhospitality"
                    ] === "true"}
                    onToggleChange={(val) =>
                      onToggleChange("optinemailcorporateandhospitality", val)}
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Merchandise",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <Toggle
                    checked={profileData?.["optinemailmerchandise"] === "true"}
                    onToggleChange={(val) =>
                      onToggleChange("optinemailmerchandise", val)}
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Ticket Information",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <Toggle
                    checked={profileData?.["optinemailticketinformation"] ===
                      "true"}
                    onToggleChange={(val) =>
                      onToggleChange("optinemailticketinformation", val)}
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "LCFC Women",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <Toggle
                    checked={profileData?.["optinemailwomens"] === "true"}
                    onToggleChange={(val) =>
                      onToggleChange("optinemailwomens", val)}
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Other Products and Services",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <Toggle
                    checked={profileData?.[
                      "optinemailotherproductsandservices"
                    ] === "true"}
                    onToggleChange={(val) =>
                      onToggleChange("optinemailotherproductsandservices", val)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-block">
            <div class="form-block-title">
              <TextParticle
                _block={true}
                {..._.merge({}, formBlockTitleTextTemplate, {
                  text: "Club Partner Communication",
                })}
              />
            </div>
            <div class="field-grid">
              <div class="form-field" style="grid-column: span 2">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Opt-in to receiving communication from Club Partners",
                    })}
                  />
                </div>
                <div class="form-field-description">
                  <RichTextParticle
                    _block={true}
                    {..._.merge({}, formFieldDescriptionTextTemplate, {
                      html: 'I wish to receive news, promotions, discounts and targeted communications from Leicester City Football Club Third Party Partners based on my provided data, and accept the use of my personal data for marketing, profiling and analysis as outlined in the <a href="privacy-policy">Privacy Policy</a>. Clicking this box will select ALL club marketing. To change your preferences on what we send you or how you receive it you can select from the further options below. <a href="partnerships-club">Click here.</a>',
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <Toggle
                    checked={profileData?.["optinpartner"] === "true"}
                    onToggleChange={(val) =>
                      onToggleChange("optinpartner", val)}
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Club Partner Mail",
                    })}
                  />
                </div>
                <div class="form-field-description">
                  <RichTextParticle
                    _block={true}
                    {..._.merge({}, formFieldDescriptionTextTemplate, {
                      html: "If providing my address, I am happy to receive 3rd Party messages via post.",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <Toggle
                    checked={profileData?.["optinmailpartner"] === "true"}
                    onToggleChange={(val) =>
                      onToggleChange("optinmailpartner", val)}
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Club Partner SMS",
                    })}
                  />
                </div>
                <div class="form-field-description">
                  <RichTextParticle
                    _block={true}
                    {..._.merge({}, formFieldDescriptionTextTemplate, {
                      html: "If providing my mobile number, I am happy to receive 3rd Party messages via SMS.",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <Toggle
                    checked={profileData?.["optinsmspartner"] === "true"}
                    onToggleChange={(val) =>
                      onToggleChange("optinsmspartner", val)}
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Club Partner Phone",
                    })}
                  />
                </div>
                <div class="form-field-description">
                  <RichTextParticle
                    _block={true}
                    {..._.merge({}, formFieldDescriptionTextTemplate, {
                      html: "If providing a mobile or landline number, I am happy to receive 3rd Party messages via phone.",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <Toggle
                    checked={profileData?.["optintelpartner"] === "true"}
                    onToggleChange={(val) =>
                      onToggleChange("optintelpartner", val)}
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">
                  <TextParticle
                    _block={true}
                    {..._.merge({}, formFieldTitleTextTemplate, {
                      text: "Club Party Email: Third Party (Partners and Sponsors)",
                    })}
                  />
                </div>
                <div class="form-field-description">
                  <RichTextParticle
                    _block={true}
                    {..._.merge({}, formFieldDescriptionTextTemplate, {
                      html: "If providing an email address, I am happy to receive 3rd Party messages via email.",
                    })}
                  />
                </div>
                <div class="form-field-value">
                  <Toggle
                    checked={profileData?.["optinemailthirdparty"] === "true"}
                    onToggleChange={(val) =>
                      onToggleChange("optinemailthirdparty", val)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-block no-border-block">
            <div class="profile-save-button" class:button-disabled={!saveEnabled}>
              <ButtonParticle
                on:click={() => saveProfile(profileData)}
                {...saveButtonTemplate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</BaseAtom>

<style>
    .outer-box.mobile-layout .inner-box{
      display: flex;
      flex-direction: column;
    }
  .outer-box.mobile-layout .inner-box .left-side{
        flex-flow: row wrap;
        border: none;
      }
  .outer-box.mobile-layout .inner-box .right-side .form-section{
          /* display: flex; */
          justify-content: space-between;
        }
  .outer-box.mobile-layout .inner-box .right-side .form-block{
          width: 100%;
          align-items: center;
        }
  .outer-box.mobile-layout .inner-box .right-side .header-block{
          flex-direction: column;
          align-items: center;
        }
  .outer-box.mobile-layout .inner-box .right-side .form-block-title{
          width: 100%;
        }
  .outer-box.mobile-layout .inner-box .right-side .field-grid{
          display: flex;
          flex-direction: column;
          width: 100%;
        }
  .outer-box.mobile-layout .inner-box .right-side .form-field{
          width: 100%;
          gap: 1rem;
        }
  .outer-box.mobile-layout .inner-box .right-side .form-field-value{
          width: 100%;
          /* > :global(*) {
            width: 80%;
          } */
        }
  .outer-box {
    padding: var(--profile-details-outer-box-padding, unset);
    background-color: var(--profile-details-outer-box-background-color, unset);
    border-radius: var(--profile-details-outer-box-border-radius, unset);
    width: 100%;
  }

  .title-row {
    padding: var(--profile-details-title-row-padding, unset);
    display: flex;
    flex-flow: row nowrap;
    justify-content: start;
    width: 100%;
  }

  .inner-box {
    padding: var(--profile-details-inner-box-padding, unset);
    background-color: var(--profile-details-inner-box-background-color, unset);
    border-radius: var(--profile-details-inner-box-border-radius, unset);

    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: var(--profile-details-left-right-gap, unset);
    gap: var(--profile-details-left-right-gap, unset);
    width: 100%;
  }

  .left-side {
    border-right: 2px solid
      var(--profile-details-inner-box-divider-color, unset);
    display: flex;
    flex-flow: column nowrap;
    align-items: start;
    justify-content: start;
  }

  .left-side > .left-side-spacer {
    flex: 1 0 auto;
  }

  .right-side {
    display: block;

    --f7-toggle-width: 3.25rem;
    --f7-toggle-height: 1.5rem;
    --f7-toggle-border-color: #e5e5e5;
    --f7-toggle-inactive-color: #989898;
    --f7-toggle-active-color: var(--theme-mode-accent-color, black);
  }

  .right-side
    :global(.toggle input[type="checkbox"]:checked + .toggle-icon:after) {
    background-color: var(--theme-mode-background-color);
  }

  .form-section {
    display: none;
    flex-flow: column nowrap;
    justify-content: start;
    align-items: start;
    gap: var(--profile-details-form-block-gap, unset);
  }

  .form-section.form-section-active {
    display: flex;
  }

  .form-block {
    display: flex;
    flex-flow: column nowrap;
    justify-content: start;
    align-items: start;
    gap: var(--profile-details-form-title-gap, unset);
    width: 100%;
    border-top: 2px solid var(--profile-details-inner-box-divider-color, unset);
  }

  .no-border-block {
    border: none !important;
  }

  .header-block {
    display: flex;
    flex-flow: row nowrap;
    justify-content: start;
    align-items: start;
    gap: 1.5rem;
    width: 100%;
  }

  .field-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    grid-row-gap: var(--profile-details-form-grid-row-gap, unset);
    row-gap: var(--profile-details-form-grid-row-gap, unset);
    grid-column-gap: var(--profile-details-form-grid-row-gap, unset);
    -webkit-column-gap: var(--profile-details-form-grid-row-gap, unset);
       -moz-column-gap: var(--profile-details-form-grid-row-gap, unset);
            column-gap: var(--profile-details-form-grid-row-gap, unset);
  }

  .profile-header {
    display: flex;
    flex-flow: column nowrap;
    justify-content: start;
    align-items: start;
    gap: 1.12rem;
  }

  .form-block-title {
    padding: var(--profile-details-form-block-title-padding, unset);
    /* border-top: 2px solid var(--profile-details-inner-box-divider-color, unset); */
  }

  .form-field {
    display: flex;
    flex-flow: column nowrap;
    justify-content: start;
    align-items: start;
    gap: 0.5rem;
  }

  select,
  input {
    border-bottom: 1px solid #eaeaea;
    width: var(--profile-details-form-value-container-width, unset);
  }
  .date-of-birth-style {
    color: #636466;
    font-size: 1.125rem;
    font-weight: 700;
  }

  :global(.toast.profile-details-atom-happy-toast) {
    --f7-toast-text-color: #ffffff;
    --f7-toast-bg-color: #00a106;
    text-align: center;
  }
  :global(.toast.profile-details-atom-sad-toast) {
    --f7-toast-text-color: #ffffff;
    --f7-toast-bg-color: #dd1f30;    
  }

  :global(.toast.profile-details-atom-happy-toast) > :global(.toast-content) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    font-family: "Fira Sans";
  }

  :global(.toast.profile-details-atom-sad-toast) > :global(.toast-content) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    font-family: "Fira Sans";
  }

  :global(.toast.profile-details-atom-happy-toast) > :global(.toast-content) > :global(.toast-icon) {
    position: absolute;
    left: var(--f7-toast-padding-horizontal);
  }

  :global(.toast.profile-details-atom-sad-toast) > :global(.toast-content) > :global(.toast-icon) {
    position: absolute;
    left: var(--f7-toast-padding-horizontal);
  }

  .profile-save-button.button-disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }</style>
