<Boundary> 
  <CardsContainerWrapper style="
    display: flex;
    flex-direction: {(layout.direction)? layout.direction : 'row'};
    justify-content: {(layout.justifyContent)? layout.justifyContent : 'normal'};
    align-items: {(layout.alignItems)? layout.alignItems : 'normal'};
    gap: {(!_.isNil(layout.gap))? layout.gap : (((_.isNumber(layout.gapPixels))? layout.gapPixels : 10) + 'px')};
    width: {layout.width || '100%'};
    height: {layout.height || 'auto'};
    overflow: {layout.overflow || 'revert'};
    flex-wrap: {(!_.isNil(layout.wrap))? layout.wrap : 'nowrap'};
  " {editorOptions}>
  {#each layout.elements as element, i (element.id)}
    <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, i)} {element} height='100%' bind:contentAvailable={childContentAvailable[element.id]} updater={elementUpdater.bind(null, i)} on:contentLoaded />
  {/each}
  </CardsContainerWrapper>
</Boundary>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }</style>

<script lang="ts">import "yinzcam-cards";
import { generateSequenceId } from "../utilities";
import CardsElement from "./CardsElement.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import "./CardsEditorInterfaces";
import CardsContainerWrapper from "./CardsContainerWrapper.svelte";
import _ from "lodash";
export let parentSequenceId = "";
export let layout;
export let contentAvailable = true;
export let updater;
let childContentAvailable = {};
$: {
    contentAvailable = Object.values(childContentAvailable).some((item) => item);
}
//$: console.log("DND LAYOUT UPDATED", layout);
function elementUpdater(i, element) {
    layout.elements[i] = element;
    updater(layout.elements);
}
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    items: layout.elements,
    updater
};
</script>
