import { readable, derived } from 'svelte/store';

export function ycOnboardingTriggerLocation() {
  if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ycOnboardingTriggerLocation) {
    console.log('CALLING: window.webkit.messageHandlers.ycOnboardingTriggerLocation.postMessage({})');
    window.webkit.messageHandlers.ycOnboardingTriggerLocation.postMessage({});
  } else if (window.YinzNativeApplication && window.YinzNativeApplication.ycOnboardingTriggerLocation) {
    console.log('CALLING: window.YinzNativeApplication.ycOnboardingTriggerLocation()');
    window.YinzNativeApplication.ycOnboardingTriggerLocation();
  } else {
    console.log('Unable to call ycOnboardingTriggerLocation: no message handler found.')
  }
}

export function ycOnboardingTriggerNotification(tags) {
  if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ycOnboardingTriggerNotification) {
    console.log('CALLING: window.webkit.messageHandlers.ycOnboardingTriggerNotification.postMessage({tags: tags})');
    window.webkit.messageHandlers.ycOnboardingTriggerNotification.postMessage({tags: tags});
  } else if (window.YinzNativeApplication && window.YinzNativeApplication.ycOnboardingTriggerNotification) {
    console.log('window.YinzNativeApplication.ycOnboardingTriggerNotification(tags)');
    window.YinzNativeApplication.ycOnboardingTriggerNotification(tags);
  } else {
    console.log('Unable to call ycOnboardingTriggerNotification: no message handler found.')
  }
}

export function ycOnboardingTriggerMicrophone() {
  if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ycOnboardingTriggerMicrophone) {
    console.log('CALLING: window.webkit.messageHandlers.ycOnboardingTriggerMicrophone.postMessage({})');
    window.webkit.messageHandlers.ycOnboardingTriggerMicrophone.postMessage({});
  } else if (window.YinzNativeApplication && window.YinzNativeApplication.ycOnboardingTriggerMicrophone) {
    console.log('CALLING: window.YinzNativeApplication.ycOnboardingTriggerMicrophone()');
    window.YinzNativeApplication.ycOnboardingTriggerMicrophone();
  } else {
    console.log('Unable to call ycOnboardingTriggerMicrophone: no message handler found.')
  }
}

export function ycSSOThirdParty(type) {
  if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ycSSOThirdParty) {
    window.webkit.messageHandlers.ycSSOThirdParty.postMessage({type: type});
  } else if (window.YinzNativeApplication && window.YinzNativeApplication.ycSSOThirdParty) {
    window.YinzNativeApplication.ycSSOThirdParty(type);
  } else {
    console.log('Unable to call ycSSOThirdParty: no message handler found.')
  }
}

export function ycSSOStoreTicket(yinzID, accountCreated, yticket, yvalidSpan) {
  if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ycSSOStoreTicket) {
    window.webkit.messageHandlers.ycSSOStoreTicket.postMessage({yinzID: yinzID, accountCreated: accountCreated, yticket: yticket, yvalidSpan: yvalidSpan, lvalidSpanString: null, ltoken: null});
  } else if (window.YinzNativeApplication && window.YinzNativeApplication.ycSSOStoreTicket) {
    window.YinzNativeApplication.ycSSOStoreTicket(yinzID, accountCreated, yticket, yvalidSpan, null, null);
  } else {
    console.log('Unable to call ycSSOStoreTicket: no message handler found.')
  }
}

export function ycSSOAuthFinished(success) {
  if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ycSSOAuthFinished) {
    window.webkit.messageHandlers.ycSSOAuthFinished.postMessage({success: success});
  } else if (window.YinzNativeApplication && window.YinzNativeApplication.ycSSOStoreTicket) {
    window.YinzNativeApplication.ycSSOAuthFinished(success);
  } else {
    console.log('Unable to call ycSSOAuthFinished: no message handler found.')
  }
}

// onboarding callbacks
/*
window.ycOnboardingPermissionUpdate = function(type, value) {
  window.localStorage.setItem('yc-onboarding-permission-' + type, value);
  document.dispatchEvent(new Event('yc-onboarding-permission-' + type));
};
*/

// TODO, move this elsewhere?
window.ycSSOThirdPartyCallback = function(type, json) {
  //console.log(json);
  ycSSOAuthFinished(true);
};

export const ycTicket = readable({ticket: null, validSpan: null}, function start(set) {
  window.setHubValidTicket = function(ticket, validSpan) {
    console.log('Set ticket callback from app');
    if (typeof validSpan === 'string') {
      validSpan = parseInt(validSpan)
    }
    set({ticket, validSpan});
  };
  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.getAppValidTicket) {
    console.log('Set ticket for iOS');
    window.webkit.messageHandlers.getAppValidTicket.postMessage({});
  } else if (window.YinzNativeApplication && window.YinzNativeApplication.getAppValidTicket) {
    console.log('Set ticket for ANDROID');
    window.YinzNativeApplication.getAppValidTicket();
  } else {
    // for testing
    //console.log('Set ticket for WEB (testing)');
    //window.setHubValidTicket('c99BiajpSzuDW232xhCMrAAAAXo+7ENN43EH9z5MIwdQFLoI8QkqEtFrqS70M9QWwksDwmcNCP/dXS/J5K+8Q5NiEMfxE85chXo6XQwrADLGCLwxcRXb4bfKGsm/8vboVURJpn69/+Mx4rSRJ4FtvU3HSyzAJJPN', 31536000000);
    //window.setHubValidTicket('+MLugCn+ShGcOmBfyTplOAAAAXwoZQK631BL9+dLxpYsUeixnWdjYTi04kRxbqWYAK9KNZOJAMdtmb+Hl9FlIbyd4jnLeX46iEfOVVynYwaAZ4Y5qqqH9S2aePD+InfEYtxBfVqXBKQqh9ektxNP170pxqEguhW4', 31536000000);
  }
  function stopHandler() {
    window.setHubValidTicket = () => {};
  }
  return stopHandler;
});