import { generateRootObjectSchema, generateArraySchema, generateObjectSchema, generateStringSchema, generateColorSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ImageParticleSpec, TextParticleSpec, SegmentedButtonParticleSpec } from "components/cards/particles/YinzCam";


export const specification = new BaseAtomSpec("MC-LineupTable2", generateRootObjectSchema({
    container: generateAtomContainerSchema(),
    overlayPadding: generateStringSchema("Container Padding", "Container Padding"),
    headerTabs: SegmentedButtonParticleSpec.generateSchema("Header Tabs", "Header Tabs"),
    imgTeamLogo: ImageParticleSpec.generateSchema("Team Logo", "Team Logo", {
        defaultValues: { container: { width: "4rem", height: "4rem" }, sizing: "cover" }
    }),
    txtTeamName: TextParticleSpec.generateSchema("Team Name", "Team Name", {
        defaultValues: { size: "2rem", weight: "600" }
    }),
    txtLineUp: TextParticleSpec.generateSchema("Starters Heading", "Starters Heading", {
        defaultValues: { size: "1rem", weight: "400", align: "center" }
    }),
    txtSubHeading: TextParticleSpec.generateSchema("Substitutes Heading", "Substitutes Heading", {
        defaultValues: { size: "2rem", weight: "700", align: "center", text: "Substitutes" }
    }),
    playerImage: ImageParticleSpec.generateSchema("Player Image", "Player Image", {
        defaultValues: { container: { height: "2.5rem" }, sizing: "cover", fitVerticalAlign: 'top' }
    }),
    playerStarterStats: generateArraySchema("Starter Player Stats", "Starter Player Stats", generateObjectSchema("Enter Player Stats", "Enter Player Stats", {
        position: TextParticleSpec.generateSchema("Player Position", "Player Position", {
            defaultValues: { size: "3rem", weight: "700", align: "right" }
        }),
        playerName: TextParticleSpec.generateSchema("Player name", "Player name", {
            defaultValues: { size: "1.25rem", weight: "400" }
        }),
        playingPosition: TextParticleSpec.generateSchema("Playing Position", "Playing Position", {
            defaultValues: { size: "1rem", weight: "400" }
        }),
        playerImageUrl: generateStringSchema("Image Url", "Image Url"),
    })),
    playerSubStats: generateArraySchema("Substitute Player Stats", "Substitute Player Stats", generateObjectSchema("Enter Player Stats", "Enter Player Stats", {
        position: TextParticleSpec.generateSchema("Player Position", "Player Position", {
            defaultValues: { size: "3rem", weight: "700", align: "right" }
        }),
        playerName: TextParticleSpec.generateSchema("Player name", "Player name", {
            defaultValues: { size: "1.25rem", weight: "400" }
        }),
        playingPosition: TextParticleSpec.generateSchema("Playing Position", "Playing Position", {
            defaultValues: { size: "1rem", weight: "400" }
        }),
        playerImageUrl: generateStringSchema("Image Url", "Image Url"),
    })),
    teamTricode: generateStringSchema("Tricode", "Tricode"),
    numberColor: generateColorSchema("Player Number Color", "Player Number Color"),
    homeTeamPlayerImages: generateArraySchema("Home Player Images", "Home Player Images",
        generateStringSchema("Image URLs", "Image URLs")
    ),
    awayTeamPlayerImages: generateArraySchema("Away Player Images", "Away Player Images",
        generateStringSchema("Image URLs", "Image URLs")
    ),
}));