import { Readable, derived, readable } from "svelte/store";
import { ContentfulCDN } from "./ContentfulCDN";
import { CardsDynamicConfiguration } from "components/cards/common/CardsDynamicConfiguration";
import { stripContentfulMetadata } from "./ContentfulUtilities";
import { ContentfulClientFactory } from "./ContentfulClient";
import { getConfigObjectData } from "./config-objects";

export class ContentfulCardsDynamicConfiguration implements CardsDynamicConfiguration {
  private readonly objectCache: Record<string, Record<string, object>> = {};

  public constructor(private readonly cdn: ContentfulCDN, private readonly cf: ContentfulClientFactory) {}

  private convertResponse(rsp: any) {
    let ret;
    if (rsp?.items?.length > 0) {
      ret = { ...(stripContentfulMetadata(rsp.items[0]) as object), _success: true };
    } else if (rsp?.items?.length === 0) {
      ret = { _success: true };
    } else {
      ret = { _success: false };
    }    
    return ret;
  }

  async getObject(type: string, slug: string): Promise<object> {
    let typeCache = this.objectCache[type];
    if (!typeCache) {
      typeCache = this.objectCache[type] = {};
    }
    /*
    if (typeCache[slug]) {
      return typeCache[slug];
    }
    */
    const query = {
        'limit': 1,
        'fields.slug': slug,
        'content_type': type,
        'include': 10,
    };
    const rsp = getConfigObjectData(type, slug) || await this.cf.getDefaultContentfulClient().getEntries(query);
    //console.log('CONTENTFUL EXEC QUERY FOR LANG', type, slug, rsp);
    return this.convertResponse(rsp);
  }

  public getObjectStore(type: string, slug: string): Readable<object> {
    const bakedInData = getConfigObjectData(type, slug);
    if (bakedInData) {
      return readable(this.convertResponse(bakedInData));
    }

    // TODO: Does this need language support?
    const req = {
      path: type,
      params: {
        'fields.slug': slug
      }
    };
    //console.log('GOT REQ', req);

    let typeCache = this.objectCache[type];
    if (!typeCache) {
      typeCache = this.objectCache[type] = {};
    }
    return derived([this.cdn.getRequest(req).store], ([rsp]) => {
      //console.log('CONTENTFUL EXEC QUERY FOR LANG', type, slug, rsp);
      //console.log('GOT RSP', rsp);
      const ret = this.convertResponse(rsp);
      typeCache[slug] = ret;
      return ret;
    }, typeCache[slug]);
  }
}
