import { Token, injectToken } from "inversify-token";
import { ContentfulAPIConfigToken } from "./ContentfulAPIConfig";
import type { ContentfulAPIConfig } from "./ContentfulAPIConfig";
import { injectable } from "inversify";
import type { Asset, AssetCollection, AssetFields, AssetKey, AssetsQueries, ChainModifiers, ContentfulClientApi, ContentType, ContentTypeCollection, EntriesQueries, Entry, EntryCollection, EntryQueries, EntrySkeletonType, LocaleCode, LocaleCollection, LocaleOption, Space, SyncCollection, SyncOptions, SyncQuery, Tag, TagCollection, TagQueries } from 'contentful';
import { buildYinzCamStandardHostname } from "lib/yinzcam-api/utilities";
import { NetworkCacheManager, NetworkCacheManagerToken } from "lib/network-cache";

const DEFAULT_TTL: number = 300*1000;

export const ContentfulClientFactoryToken = new Token<ContentfulClientFactory>(Symbol.for("ContentfulClientFactory"));

export const CONTENT_TYPE_CONTENT_TYPE = 'contentType';

export const TTL_SYM = Symbol.for("Janus__ContentfulClient__TTL");

export interface ContentfulClient extends ContentfulClientApi<undefined> {

}

class WebWorkerContentfulClient implements ContentfulClient {
  private readonly config: ContentfulAPIConfig;

  public constructor(config: ContentfulAPIConfig) {
    this.config = config;
  }

  getContentType(id: string): Promise<ContentType> {
    throw new Error("Method not implemented.");
  }

  getContentTypes(query?: { query?: string; }): Promise<ContentTypeCollection> {
    throw new Error("Method not implemented.");
  }

  getSpace(): Promise<Space> {
    throw new Error("Method not implemented.");
  }

  getLocales(): Promise<LocaleCollection> {
    throw new Error("Method not implemented.");
  }

  sync<EntrySkeleton extends EntrySkeletonType = EntrySkeletonType, Modifiers extends ChainModifiers = ChainModifiers, Locales extends LocaleCode = string>(query: SyncQuery, syncOptions?: SyncOptions): Promise<SyncCollection<EntrySkeleton, Modifiers, Locales>> {
    throw new Error("Method not implemented.");
  }

  getTag(id: string): Promise<Tag> {
    throw new Error("Method not implemented.");
  }

  getTags(query?: TagQueries): Promise<TagCollection> {
    throw new Error("Method not implemented.");
  }

  createAssetKey(expiresAt: number): Promise<AssetKey> {
    throw new Error("Method not implemented.");
  }

  getEntry<EntrySkeleton extends EntrySkeletonType = EntrySkeletonType, Locales extends LocaleCode = string>(id: string, query?: EntryQueries<undefined>): Promise<Entry<EntrySkeleton, undefined, Locales>> {
    throw new Error("Method not implemented.");
  }

  getEntries<EntrySkeleton extends EntrySkeletonType = EntrySkeletonType, Locales extends LocaleCode = string>(query?: EntriesQueries<EntrySkeleton, undefined>): Promise<EntryCollection<EntrySkeleton, undefined, Locales>> {
    throw new Error("Method not implemented.");
  }

  parseEntries<EntrySkeleton extends EntrySkeletonType = EntrySkeletonType, Locales extends LocaleCode = string>(data: EntryCollection<EntrySkeleton, "WITHOUT_LINK_RESOLUTION", Locales>): EntryCollection<EntrySkeleton, undefined, Locales> {
    throw new Error("Method not implemented.");
  }

  getAsset<Locales extends LocaleCode = string>(id: string, query?: LocaleOption): Promise<Asset<undefined, Locales>> {
    throw new Error("Method not implemented.");
  }

  getAssets<Locales extends LocaleCode = string>(query?: AssetsQueries<AssetFields, undefined>): Promise<AssetCollection<undefined, Locales>> {
    throw new Error("Method not implemented.");
  }
  
  withAllLocales: ContentfulClientApi<"WITH_ALL_LOCALES">;
  withoutLinkResolution: ContentfulClientApi<"WITHOUT_LINK_RESOLUTION">;
  withoutUnresolvableLinks: ContentfulClientApi<"WITHOUT_UNRESOLVABLE_LINKS">;
  version: string;
}

function createDirectContentfulClient(language?: string): ContentfulClient {
  return contentful.createClient({
    accessToken: '__TOKEN__',
    //environment: contentfulConfig.environment,
    space: '__SPACE__',
    application: `${CONFIG.league.toUpperCase()}_${CONFIG.tricode.toUpperCase()}/${CONFIG.version}`,
    integration: `JANUS/1.0.0`, // TODO: put in proper number here
    host: buildYinzCamStandardHostname('contentful'),
    headers: {
      'X-YinzCam-Environment': 'prod', //CONFIG.environment
      ...((language)? { 'Accept-Language': language } : undefined),
    }
  });
}

@injectable()
export class ContentfulClientFactory {
  private readonly config: ContentfulAPIConfig;
  private readonly ncm: NetworkCacheManager;

  private defaultContentfulClient: ContentfulClient = null;
  private readonly languageContentfulClients: Map<String, ContentfulClient>;

  public constructor(@injectToken(ContentfulAPIConfigToken) config: ContentfulAPIConfig, @injectToken(NetworkCacheManagerToken) ncm: NetworkCacheManager) {
    this.config = config;
    this.ncm = ncm;
    this.languageContentfulClients = new Map();
  }

  private createCachedContentfulClient(language?: string): ContentfulClient {
    const directClient = createDirectContentfulClient(language);
    const baseUrl = new URL(`https://${buildYinzCamStandardHostname('contentful')}/`);
    const self = this;
    return {
      getContentTypes(query) {
        return directClient.getContentTypes(query);
      },
      async getEntries(query) {
        const url = new URL(`/getEntries`, baseUrl);
        for (const k in query) {
          url.searchParams.set(k, query[k]);
        }
        const data = await self.ncm.getOrPut(url, async () => {
          const entries = await directClient.getEntries(query);
          const ttl = query[TTL_SYM] || DEFAULT_TTL;
          return [JSON.stringify(entries), ttl];  
        });
        return JSON.parse(data);
      },
    } as ContentfulClient;
  }

  public getDefaultContentfulClient(language?: string): ContentfulClient {
    let ret: ContentfulClient = (language)? this.languageContentfulClients.get(language) : this.defaultContentfulClient;
    if (ret == null) {
      switch (CONFIG.contentfulClientFlavor || '') {
        case "WebWorker":
          ret = new WebWorkerContentfulClient(this.config);
          break;
        case "Cached":
          ret = this.createCachedContentfulClient(language);
          break;
        default:
          ret = createDirectContentfulClient(language);
          break;
      }
      if (language) {
        this.languageContentfulClients.set(language, ret);
      } else {
        this.defaultContentfulClient = ret;
      }
    }
    return ret;
  }
}
