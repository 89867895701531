<script context="module" lang="ts">var _a;
export { specification } from "./spec";
</script>

<script lang="ts">var _a;
import _ from "lodash";
import { getContext, afterUpdate } from "svelte";
import { BaseParticle } from "components/cards/particles";
import { ButtonParticle } from "../Button";
import { CardsTabContextKey } from "components/cards/common/context";
import "./spec";
import { afterUpdateDebounce } from "js/utilities";
import { getVerticalScrollParent } from "js/utilities";
import { shouldHideBcGame } from "js/routes";
export let container = undefined;
export let opts = undefined;
export let params = undefined;
export let buttonDefaults = undefined;
export let selectedButtonDefaults = undefined;
export let unselectedButtonDefaults = undefined;
export let defaultValues = undefined;
export let orientation = undefined;
export let alignment = undefined;
export let justification = undefined;
export let gap = undefined;
export let scrollToTopOnSelect = false;
let wrapper;
let selectedOption = null;
const tabContext = getContext(CardsTabContextKey);
const mergedParamsStore = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
const TEAMS_TO_EXCLUDE_BCGAME = ['12940', '8879'];
const PAGES_TO_EXCLUDE_BCGAME = ["fixtures-results"];
$: mergedParams = $mergedParamsStore;
function selectOption(selectedItem) {
    var _a, _b;
    selectedOption = selectedItem;
    for (let i = 0; i < (params === null || params === void 0 ? void 0 : params.length); i++) {
        tabContext.setParam(params[i], ((_a = selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.values) === null || _a === void 0 ? void 0 : _a[i]) || (defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues[i]) || 'undefined');
    }
    if (scrollToTopOnSelect && wrapper) {
        (_b = getVerticalScrollParent(wrapper)) === null || _b === void 0 ? void 0 : _b.scrollTop = 0;
    }
}
afterUpdate(afterUpdateDebounce(() => {
    var _a, _b;
    if (!(params === null || params === void 0 ? void 0 : params[0]) || !opts || !mergedParams) {
        return;
    }
    const currentValue = mergedParams[params[0]];
    if (!currentValue) {
        return;
    }
    // find option matching current param value
    const matchingOption = opts.find((opt) => { var _a; return ((_a = opt.values) === null || _a === void 0 ? void 0 : _a[0]) === currentValue; });
    if (!matchingOption) {
        selectedOption = opts[0];
    }
    else if (((_a = matchingOption.values) === null || _a === void 0 ? void 0 : _a[0]) != ((_b = selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.values) === null || _b === void 0 ? void 0 : _b[0])) {
        selectedOption = matchingOption;
    }
    shouldHideBcGame.set(TEAMS_TO_EXCLUDE_BCGAME.includes(mergedParams === null || mergedParams === void 0 ? void 0 : mergedParams.teamID) && PAGES_TO_EXCLUDE_BCGAME.includes(mergedParams === null || mergedParams === void 0 ? void 0 : mergedParams.path0));
}));
</script>

<BaseParticle
    _name="RadioButton"
    _cssVariablePrefix="radio-button"
    {...container}
    {orientation} {alignment} {justification} {gap}
>
    <div bind:this={wrapper} class="radio-button-content">
    {#each opts || [] as option}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div class="radio-button">
            <ButtonParticle
                {..._.merge({},
                    buttonDefaults, 
                    ((option.values?.[0] === selectedOption?.values?.[0])? selectedButtonDefaults : unselectedButtonDefaults),
                    option.buttonDefaults,
                    ((option.values?.[0] === selectedOption?.values?.[0])? option.selectedButton : option.unselectedButton)
                )}
                on:click={() => selectOption(option)}
            />
        </div>
    {/each}
    </div>
</BaseParticle>

<style>
    .radio-button-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: var(--radio-button-orientation);
        align-items: var(--radio-button-alignment, unset);
        justify-content: var(--radio-button-justification, unset);
        gap: var(--radio-button-gap, unset)
    }

    .radio-button {
        flex: 0 1 max-content;
    }</style>
