import _ from "lodash";
import { AbstractReactiveMicrocomponent } from "../AbstractReactiveMicrocomponent";
import { ReactiveMicrocomponent } from "../ReactiveMicrocomponent";
import { ReactiveMicrocomponentConfig } from "../ReactiveMicrocomponentConfig";

export class OutputMapper<O, I> extends AbstractReactiveMicrocomponent<O, [I]> {
  private readonly inputComponent: ReactiveMicrocomponent<I>;
  private readonly mapper: (inputValue: I) => O;

  public constructor(arg: string | ReactiveMicrocomponentConfig<O>, inputComponent: ReactiveMicrocomponent<I>, mapper: (inputValue: I) => O, initialOutputValue?: O) {
    super((typeof arg === 'string')? 
      { name: arg, initialOutputValue } : 
      { ...arg, initialOutputValue }, inputComponent);
    this.inputComponent = inputComponent;
    this.mapper = mapper;
  }

  public refreshSupported(): boolean {
    return this.inputComponent.refreshSupported();
  }

  public refresh(): void {
    this.inputComponent.refresh();
  }

  protected async update($control: unknown, $inputValue: I): Promise<O> {
    if (_.isUndefined($inputValue)) {
      return undefined;
    }
    return this.mapper($inputValue);
  }
}
