import { generateRootObjectSchema, generateArraySchema, generateObjectSchema, generateColorSchema, generateStringSchema, generateBooleanSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ButtonParticleSpec, ImageParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";
import { matchCenterStatList } from "components/cards/utilities/constants";

export const specification = new BaseAtomSpec("MC-Shooting Details", generateRootObjectSchema({
    container: generateAtomContainerSchema(),
    overlayPadding: generateStringSchema("Overlay padding", "Specify overlay padding"),
    txtHeading: TextParticleSpec.generateSchema("Heading text", "Enter a heading text", {
        defaultValues: { size: "2rem", weight: "900", transform: "uppercase" }
    }),
    imgHomeLogo: ImageParticleSpec.generateSchema("Home Logo", "Upload a home team logo", {
        defaultValues: { container: { width: "10rem", height: "10rem" }, sizing: "cover" }
    }),
    imgAwayLogo: ImageParticleSpec.generateSchema("Away Logo", "Upload an away team logo", {
        defaultValues: { container: { width: "10rem", height: "10rem" }, sizing: "cover" }
    }),
    statsList: generateArraySchema("Stats List", "Stats List",
        generateStringSchema("Select Stat", "Select stat from list", {
            choices: matchCenterStatList,
            choiceTitles: matchCenterStatList,
            defaultValue: matchCenterStatList[0],
        })),
    txtStatName: TextParticleSpec.generateSchema("Stat Name", "Enter a stat name", {
        defaultValues: { size: "1.25rem", weight: "400" }
    }),
    txtHomeValue: TextParticleSpec.generateSchema("Home Team Value", "Enter a value for home team", {
        defaultValues: { size: "1.5rem", weight: "700" }
    }),
    txtAwayValue: TextParticleSpec.generateSchema("Away Team Value", "Enter a value for away team", {
        defaultValues: { size: "1.5rem", weight: "700" }
    }),
    matchStats: generateArraySchema("Shot Stats", "Select shot stats to compare Home vs Away",
        generateObjectSchema("Shot Stat", "Shot Stat", {
            statName: generateStringSchema("Stat Name", "Stat Name"),
            homeValue: generateStringSchema("Home Value", "Home Value"),
            awayValue: generateStringSchema("Away Value", "Away Value"),
            maxValue: generateStringSchema('Max Value', "Max Value")
        })),
    objCircleGraphStats: generateObjectSchema("Circle Graph Stats", "Circle Graph Stats", {
        statName: generateStringSchema("Select Stat", "Select stat from list", {
            choices: matchCenterStatList,
            choiceTitles: matchCenterStatList,
        }),
        progressColor: generateColorSchema("Progress Color", "Pick a progress color"),
        fillColor: generateColorSchema("Fill Color", "Pick a fill color"),
    }),
    isCircleGraphRequired: generateBooleanSchema("Is Circle Graph Required?", "Is Circle Graph Required?", {
        defaultValue: false
    }),
    myTeamColor: generateColorSchema("My Team Color", "Pick a color for home team"),
    opponentTeamColor: generateColorSchema("Opponent Team Color", "Pick a color for away team"),
    homeTricode: generateStringSchema("Home Tricode", "Home Tricode"),
    awayTricode: generateStringSchema("Home Tricode", "Home Tricode"),
}));