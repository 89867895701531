import { generateArraySchema, generateBooleanSchema, generateMediaUrlSchema, generateNumberSchema, generateObjectSchema, generateRootObjectSchema, generateStringSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { AudioParticleSpec, ImageParticleSpec, RichTextParticleSpec, TextParticleSpec, VideoParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Standings Card", generateRootObjectSchema({
  container: generateAtomContainerSchema({
    defaultValues: { }
  }),
  imgLeagueLogo: ImageParticleSpec.generateSchema("Competition Logo", "Upload/specify URL for competition logo", {
    defaultValues: { }
  }),
  layout: generateStringSchema("Table Layout", "Whether the table should be full or abbreviated.", {
    choices: [ 'full', 'abbr' ],
    choiceTitles: [ 'Full', 'Abbreviated' ],
    defaultValue: 'full'
  }),
  maxRows: generateNumberSchema("Maximum Rows", "The maximum number of rows to display in the table."),
  centerOnTeam: generateBooleanSchema("Center on Team", "Whether the table should centered on the current team if the rows are limited."),
  highlightTeam: generateBooleanSchema("Highlight Team", "Whether the current team should be highlighted in the table."),
  tableColumns: generateArraySchema("Table Headers", "The list of additional table headers. Note that these are in addition to the baked-in columns specified for each data row.", 
    generateStringSchema("Table Header", "The header text for a single (additional) column.")
  ),
  tableData: generateArraySchema("Table Data", "The list of table data rows.", 
    generateObjectSchema("Table Data Row", "A single table data row. Additional properties should be specified to match the columns.", {
      position: generateStringSchema("Position", "The position number for this row."),
      teamLogo: generateMediaUrlSchema("Team Logo", "The URL of the team logo for this row."),
      teamTriCode: generateStringSchema("Team Abbreviation", "The abbreviation (tricode) of the team represented by this row."),
      teamName: generateStringSchema("Team Full Name", "The full name of the team represented by this row."),
      upDown: generateStringSchema("Position Up/Down", "Whether this position should be annotated with an increase or decrease in position from the previous week.", {
        choices: [ 'NONE', 'UP', 'DOWN' ],
        choiceTitles: [ 'None', 'Up', 'Down' ],
        defaultValue: 'NONE'
      }),
      recordType: generateStringSchema("Championship Flag", "Whether this position should be flagged with a color to indicate championship status.", {
        choices: [ 'NONE', 'GREEN', 'YELLOW', 'RED' ],
        choiceTitles: [ 'None', 'Green', 'Yellow', 'Red' ],
        defaultValue: 'NONE'
      }),
    }, { additionalProperties: true })
  ),
}));
