import { generateRootObjectSchema, generateArraySchema, generateObjectSchema, generateColorSchema, generateStringSchema, generateBooleanSchema, generateNumberSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ButtonParticle, ButtonParticleSpec, ImageParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("League Table Two - Stats", generateRootObjectSchema({
    container: generateAtomContainerSchema(),
    containerPadding: generateStringSchema("Container Padding", "Padding for the container"),
    imgLeagueLogo: ImageParticleSpec.generateSchema("League Logo", "League Logo", {
        defaultValues: { container: { height: "2rem" }, sizing: "contain" }
    }),
    txtHeading: TextParticleSpec.generateSchema("Title", "Enter title to commentary", {
        defaultValues: {
            size: "1.25rem", weight: "800", text: "Standings", transform: "uppercase"
        }
    }),
    txtHeaderData: TextParticleSpec.generateSchema("Table Header", "Table Header", {
        defaultValues: { size: "1.25rem", weight: "700" }
    }),
    txtRowData: TextParticleSpec.generateSchema("Table Row Data", "Table Row Data", {
        defaultValues: { size: "1.25rem", weight: "700" }
    }),
    imgTeamLogo: ImageParticleSpec.generateSchema("Team Logo", "Add a team logo", {
        defaultValues: { container: { width: "4rem", height: "4rem", fillHeight: true }, sizing: "cover" }
    }),
    btnFullTable: ButtonParticleSpec.generateSchema("Full Table", "Show full table button", {
        defaultValues: { text: { size: "1.25rem", weight: "800", transform: "uppercase" }, container: { height: "2rem" } }
    }),
    maxRows: generateNumberSchema("Maximum Rows", "The maximum number of rows to display in the table."),
    centerOnTeam: generateBooleanSchema("Center on Team", "Whether the table should centered on the current team if the rows are limited."),
    tableData: generateArraySchema("Table Data", "The list of table data rows.",
        generateObjectSchema("Table Data Row", "A single table data row. Additional properties should be specified to match the columns.", {
            position: generateStringSchema("Position", "The position number for this row."),
            teamLogo: generateStringSchema("Team Logo", "Upload a logo for the team"),
            teamTriCode: generateStringSchema("Team Abbreviation", "The abbreviation (tricode) of the team represented by this row."),
            teamName: generateStringSchema("Team Full Name", "The full name of the team represented by this row."),
        }, { additionalProperties: true }),
    ),
    tableColumns: generateArraySchema("Table Headers", "The list of additional table headers. Note that these are in addition to the baked-in columns specified for each data row.",
        generateStringSchema("Table Header", "The header text for a single (additional) column.")
    ),
    colorHeadingBackground: generateColorSchema("Heading Background", "Pick a color for heading background"),
    tableHeaderColor: generateColorSchema("Table Header Color", "Pick a color for table header"),
    darkBackgroundFontColor: generateColorSchema("Dark BG Font Color", "Dark BG Font Color"),
    variantType: generateStringSchema("Variant of Table", "Select a variant to table", {
        choices: ["with-league-logo", "without-league-logo"],
        choiceTitles: ["Include League Logo", "Exclude League Logo"],
        defaultValue: "without-league-logo",
    }),
    colorFooterBackground: generateColorSchema("Footer Color", "Footer Color"),
    homeTeamFontColor: generateColorSchema("Home Team Font Color", "Home Team Font Color"),
    regularFontColor: generateColorSchema("Regular Font Color", "Regular Font Color"),
    colorAlternateRows: generateColorSchema("Alternate Row Color", "Alternate Row Color"),
    homeTeamBorderRadius: generateBooleanSchema("Rounded Border for Home Team", "Rounded Border for Home Team", {
        defaultValue: false
    }),
    shouldDisplayBorder: generateBooleanSchema("Should Display Border?", "Should Display Border?", {
        defaultValue: false
    }),
    boxBorderColor: generateColorSchema("Box Border Color", "Box Border Color"),
    tableBorderRadius: generateStringSchema("Table Border Radius", "Table Border Radius"),
    liveIconPosition: generateStringSchema("Live Icon Position", "Live Icon Position", {
        choices: ['top', 'team'],
        choiceTitles: ["Top of the table", "Next to team"],
        defaultValue: 'team'
    }),
    hasGroupData: generateBooleanSchema("Has Group Data?", "Has Group Data?", { defaultValue: false }),
    groupTableData: generateArraySchema("Table Data", "The list of table data rows.",
        generateObjectSchema("Table Data Row", "A single table data row. Additional properties should be specified to match the columns.", {
            position: generateStringSchema("Position", "The position number for this row."),
            teamLogo: generateStringSchema("Team Logo", "Upload a logo for the team"),
            teamTriCode: generateStringSchema("Team Abbreviation", "The abbreviation (tricode) of the team represented by this row."),
            teamName: generateStringSchema("Team Full Name", "The full name of the team represented by this row."),
            groupName: generateStringSchema("Group name", "Group name"),
            groupRank: generateStringSchema("Group Rank", "Group Rank")
        }, { additionalProperties: true }),
    ),
    txtGroupName: TextParticleSpec.generateSchema("Group Name", "Group Name", { defaultValues: { size: "1.5rem", color: "#FFF", weight: "700", transform: "uppercase" } }),
    groupNames: generateArraySchema("Group Name Array", "Group Name Array", generateStringSchema("Group Names", "Group Names")),
    groupHeaderBGColor: generateColorSchema("Group Header Color", "Group Header Color")
}));
