<Boundary>
  <div bind:this={wrapper}>
    <CardsComponentWrapper {messageHandler} sticky={tab.sticky} style="
      {buildCssPadding(padding)}
      {buildCssMargin(margin)}
      {(!(background && background.attachment === 'FIXED' && Device.ios))? buildCssBackground(background) : ''}
      {buildCssFont(tab.font)}
      {buildCssBorder(tab.border)}
      {(hidden)? 'display: none;' : ''}
      {(zoom)? `zoom: ${zoom};` : ''}
      {(createStackingContext)? 'isolation: isolate;' : ''}
      " {editorOptions}>
      <div class="yinzcam-cards-tab-ios-fixed-bg" style="{
        (background && background.attachment === 'FIXED' && Device.ios)?
        `${buildCssBackground({...background, attachment: null})}
        width: 100%; height: 100%; position: fixed; top: 0; z-index: -5;
        ` :
        "display: none;"
      }"></div>
      {#if contentStore && $contentStore}
        <CardsSections parentSequenceId={sequenceId} sections={$contentStore.sections} updater={(sections) => tab.sections = sections} on:contentLoaded={handleContentLoaded} />      
      {/if}
    </CardsComponentWrapper>
  </div>
</Boundary>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }</style>

<script lang="ts">var _a, _b, _c, _d;
import { YinzCamCardsServiceToken } from "yinzcam-cards";
import CardsSections from "./CardsSections.svelte";
import { buildCssPadding, buildCssBackground, buildCssMargin, buildCssFont, buildCssBorder, rewriteExternalCDNURL, getImageTransformationSlug, applyImageTransformation } from '../utilities/index';
import { createEventDispatcher, getContext, onMount, setContext } from "svelte";
import { CardsPageContextKey, CardsTabContextKey } from "./context";
import { CardsDataSourceManager } from "./CardsDataSourceManager";
import ROOT from "../../../inversify.config";
import { writable } from "svelte/store";
import { getToken } from "inversify-token";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { JanusModeContextManagerToken } from "../../../js/mode";
import { MergedYinzCamAPIRequestParameterComponent } from "yinzcam-api";
import { AbstractReactiveMicrocomponent, ManualPassthrough } from "yinzcam-rma";
import _ from "lodash";
import CardsComponentWrapper from './CardsComponentWrapper.svelte';
import "./CardsEditorInterfaces";
import { CARDS_TAB_SCHEMA, generateTabDataSourcesSchema } from "./CardsEditorComponentSchemata";
import { windowMetrics as wm } from '../../../js/stores';
import "./actions";
import { Device } from "framework7";
export let parentSequenceId = null;
export let sequenceId = "";
export let tab;
export let updater;
const cardsService = getToken(ROOT, YinzCamCardsServiceToken);
const pageContext = getContext(CardsPageContextKey);
const defaultParamsComp = pageContext.defaultParamsComp;
const overrideParamsComp = pageContext.overrideParamsComp;
const allViewsParamsComp = pageContext.allViewsParamsComp;
const modeParamsComp = getToken(ROOT, JanusModeContextManagerToken).getMergedRequestParameterComponent();
const pageParamsComp = new ManualPassthrough({ name: `${pageContext.id}_CardsTab_${tab.id}_PageParams`, saveToLocalStorage: false }, { params: pageContext.query });
const internalParamsComp = new ManualPassthrough({ name: `${pageContext.id}_CardsTab_${tab.id}_InternalParams`, saveToLocalStorage: false }, { params: {} });
const mergedApiParamsComp = new MergedYinzCamAPIRequestParameterComponent({ name: `${pageContext.id}_CardsTab_${tab.id}_MergedApiParams`, saveToLocalStorage: false }, defaultParamsComp, modeParamsComp, pageParamsComp, overrideParamsComp, internalParamsComp);
const pathParamsMappingComp = pageContext.pathParamsMappingComp;
const mergedParamsComp = new class extends AbstractReactiveMicrocomponent {
    constructor() {
        super(...arguments);
        this.lastParams = undefined;
    }
    async update($control, $apiParams, $pathParamsMap) {
        const newParams = $apiParams === null || $apiParams === void 0 ? void 0 : $apiParams.params;
        for (let [i, param] of $pathParamsMap.entries()) {
            if (!param)
                continue;
            const subst = newParams[`path${i}`];
            if (!subst)
                continue;
            newParams[param] = subst;
        }
        if (this.lastParams === newParams || _.isEqual(this.lastParams, newParams)) {
            throw new Error("params have not changed");
        }
        this.lastParams = newParams;
        //console.log('TAB API PARAMS', newParams);
        return newParams;
    }
}({ name: `${pageContext.id}_CardsTab_${tab.id}_MergedParams`, saveToLocalStorage: false, expectThrowFromUpdate: true }, mergedApiParamsComp, pathParamsMappingComp);
const mergedParamsStore = mergedParamsComp.store;
const setParam = function (key, value) {
    if (!key) {
        return;
    }
    let apiParams = internalParamsComp.getValue();
    if (!(apiParams === null || apiParams === void 0 ? void 0 : apiParams.params)) {
        apiParams = { params: {} };
    }
    let allViewsParams = allViewsParamsComp.getValue();
    if (!(allViewsParams === null || allViewsParams === void 0 ? void 0 : allViewsParams.params)) {
        allViewsParams = { params: {} };
    }
    if (!value) {
        delete apiParams.params[key];
        delete allViewsParams.params[key];
    }
    else {
        apiParams.params[key] = value;
        allViewsParams.params[key] = value;
    }
    internalParamsComp.setValue(apiParams);
    allViewsParamsComp.setValue(allViewsParams);
};
const dataSourceManager = new CardsDataSourceManager(ROOT, setParam);
const scrollContainer = writable(document.body);
const dispatch = createEventDispatcher();
setContext(CardsTabContextKey, { dataSourceManager, mergedParamsComp, setParam, scrollContainer });
let contentStore;
let wrapper;
let loadedFromFragment = false;
let readyToRender = false;
let childrenLoaded = {};
let contentLoaded = false;
function handleContentLoaded(e) {
    //console.log('CONTENT LOADED', e);
    childrenLoaded[e.detail.id] = true;
    childrenLoaded = childrenLoaded;
}
$: contentLoaded = tab && (!((_a = tab.sections) === null || _a === void 0 ? void 0 : _a.length) || tab.sections.every((sec) => childrenLoaded[sec.id]));
$: if (readyToRender && contentLoaded) {
    dispatch('contentLoaded', tab);
}
//$: console.log('tab content loaded status:', tab.id, contentLoaded, tab?.sections?.map((el) => [ el.id, childrenLoaded[el.id] ]));
//$: fragmentId = tab?.fragmentId;
//$: sections = tab?.sections;
let actionOverrides = {};
function messageHandler(action) {
    var _a, _b, _c;
    switch (action.effect) {
        case 'set-variable':
            setParam((_a = action.variable) === null || _a === void 0 ? void 0 : _a.name, (_b = action.variable) === null || _b === void 0 ? void 0 : _b.value);
            break;
        case 'clear-variable':
            setParam((_c = action.variable) === null || _c === void 0 ? void 0 : _c.name);
            break;
        case 'hide':
            actionOverrides.hidden = true;
            break;
        case 'show':
            actionOverrides.hidden = false;
            break;
        case 'set-background':
            actionOverrides.background = action.layerBackground;
            break;
        case 'set-border':
            actionOverrides.border = action.layerBorder;
            break;
        default:
            console.log("CardsTab(View) - Ignoring message, no suitable message handler found.");
            break;
    }
}
$: if (tab && (tab.sections || tab.fragmentUri)) {
    let setContent = false;
    if (tab.fragmentUri && sequenceId.endsWith('0000')) {
        try {
            // TODO: Determine if a page for the requested language exists and if so load that instead of the default one.
            const language = CONFIG.defaultLanguage;
            contentStore = cardsService.getCardsFragment(tab.fragmentUri, language).store;
            setContent = true;
            loadedFromFragment = true;
        }
        catch (e) {
            console.error(`unable to load tab from fragment '${tab.fragmentUri}, falling back to tab content`, e);
        }
    }
    if (!setContent && tab.sections) {
        contentStore = writable(tab);
        setContent = true;
        loadedFromFragment = false;
    }
    if (!setContent) {
        contentStore = writable({ sections: [], sources: [] });
        loadedFromFragment = false;
    }
    //console.log('loaded from fragment', tab?.id, loadedFromFragment, tab);
}
let hidden, zoom, padding, margin, background, createStackingContext;
$: if (contentStore && $contentStore) {
    let clone = _.cloneDeep($contentStore);
    // Apply responsive properties
    //console.log('CHECKING RESPONSIVE CONFIGURATION', currentLayout?.responsiveness);
    const responsiveConfiguration = (_d = (_c = (_b = clone.responsiveness) === null || _b === void 0 ? void 0 : _b.filter((i) => i.maxWidth >= 0)) === null || _c === void 0 ? void 0 : _c.sort((a, b) => a.maxWidth - b.maxWidth)) === null || _d === void 0 ? void 0 : _d.find((i) => i.maxWidth >= $wm.width);
    if (responsiveConfiguration) {
        //console.log('COLUMN FOUND RESPONSIVE CONFIGURATION', responsiveConfiguration);
        for (const k in responsiveConfiguration) {
            if (k === 'maxWidth') {
                continue;
            }
            const val = responsiveConfiguration[k];
            if (!_.isNil(val)) {
                clone[k] = val;
            }
        }
    }
    // Apply action overrides
    clone = _.merge(clone, actionOverrides);
    // Apply background image optimization
    let modifiedBackground = clone.background;
    if (modifiedBackground) {
        try {
            modifiedBackground.url = rewriteExternalCDNURL(modifiedBackground.url);
            /*
            if (_clientWidthPx && _clientHeightPx) {
            }
            */
            const xfSlug = getImageTransformationSlug('background', 1, 1);
            if (xfSlug) {
                modifiedBackground.url = applyImageTransformation(modifiedBackground.url, xfSlug);
            }
        }
        catch (e) {
            console.warn('error modifying background image URL', e);
        }
    }
    hidden = !_.isNil(clone.hidden) && clone.hidden;
    zoom = clone.zoom;
    padding = clone.padding;
    margin = clone.margin;
    background = clone.background;
    createStackingContext = clone.createStackingContext;
}
else {
    hidden = true, zoom = undefined, padding = undefined, margin = undefined, createStackingContext = false;
}
/*
$: if (sections) {
  try {
    if (fragmentId) {
      console.error('cannot define both sections and fragment ID for a tab');
    } else {
      
    }
  } finally {}
}

$: if (fragmentId) {
  try {
    if (sections) {
      console.error('cannot define both sections and fragment ID for a tab');
    } else {
      contentStore = cardsService.getCardsFragment(fragmentId).store;
    }
  } finally {}
}
*/
$: if (contentStore && !_.isNil($contentStore) && mergedParamsStore && !_.isNil($mergedParamsStore)) {
    try {
        dataSourceManager.setDataSources($contentStore.sources, $mergedParamsStore);
    }
    finally { }
}
async function generateSchema(component) {
    //console.log('GENERATE SCHEMA', JSON.stringify(component, null, 2));
    const schema = _.cloneDeep(CARDS_TAB_SCHEMA);
    schema.properties.sources = await generateTabDataSourcesSchema(component.sources);
    //await new Promise(resolve => setTimeout(resolve, 1000));
    //console.log('RESULT SCHEMA', schema); 
    return schema;
}
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    sequenceId,
    componentId: tab.id,
    component: Object.assign(Object.assign({}, tab), (($contentStore === null || $contentStore === void 0 ? void 0 : $contentStore.displayName) ? { displayName: $contentStore === null || $contentStore === void 0 ? void 0 : $contentStore.displayName } : undefined)),
    componentTypeName: 'View',
    childTypeNames: ['Section'],
    primaryColor: '#F3BD1C',
    reverseTextColor: 'black',
    unselectedBackgroundColor: '#e6e6e6',
    unselectedTextColor: '#000000',
    selectedBackgroundColor: 'rgb(0, 122, 255)',
    selectedTextColor: '#FFFFFF',
    childrenKey: 'sections',
    /*configSpec: CARDS_TAB_SCHEMA,*/
    configSpecGenerator: generateSchema,
    allowChildren: true,
    allowDeletion: false,
    allowDuplication: false,
    allowMovement: false,
    cloned: loadedFromFragment,
    allowEditsToSelfIfCloned: true,
    updater
};
function findScrollContainer() {
    let el = wrapper;
    while (el = el === null || el === void 0 ? void 0 : el.parentElement) {
        let style = getComputedStyle(el);
        if (String(style === null || style === void 0 ? void 0 : style.overflow).toLowerCase() === 'auto') {
            scrollContainer.set(el);
            break;
        }
    }
}
onMount(() => {
    findScrollContainer();
    readyToRender = true;
});
</script>
