import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";
import { tdate } from "../../../../js/i18n"
import _ from "lodash";
import { JSONSchema4 } from "json-schema";

@injectable()
export class YinzCamAppServerGameList extends CardsDataSourceBase {

  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  protected processResponse(path: string, response: YinzCamAPIResponse, specData?: { [key: string]: any }): object | any[] {
    const data = response?.data as any;
    let gameData;
    if (specData?.grouping == "month") {
      gameData = this.getGamesByMonth(data);
    } else {
      gameData = this.getGamesByIndividual(data);
    }
    return gameData;
  }

  /*
public getDisplayName(): string {
  return 'YinzCam Sports Data';
}


public async getDataSourcePaths(specData?: { [key: string]: any }): Promise<{ name: string; path: string; }[]> {
  return [
    {
      name: 'Game List',
      path: '/Game/List'
    }
  ];
}

public async getDataSourceConfigSpec(): Promise<JSONSchema4> {
  return null;
}
*/

  getGamesByMonth(data: any): any {
    let games = [].concat(data?.GameList?.GameSection?.Game).filter(item => item?._attributes?.Id);
    let gameMonths = _.uniq(games.map(game => new Date(game?.Date?._attributes?.Timestamp).getMonth()));
    let months = this._getMonthArray(gameMonths);
    let gamesByMonths = _.groupBy(games, game => new Date(game?.Date?._attributes?.Timestamp).getMonth());
    return months.map(month => {
      return {
        month: month,
        data: gamesByMonths[month],
        year: this._getYear(games ? games[0]: '', month),
      };
    });
  }

  getGamesByIndividual(data: any): any {
    let games = [].concat(data?.GameList?.GameSection?.Game).filter(item => item?._attributes?.Id);
    let simpleGameData = games.map((game: any) => {
      const IsHome = game?._attributes?.Home ? JSON.parse(game?._attributes?.Home) : false
      const Team: any = {
        FullName: game?.Us?._attributes?.FullName,
        Id: game?.Us?._attributes?.Id,
        Name: game?.Us?._attributes?.Name,
        ShortName: game?.Us?._attributes?.ShortName,
        TriCode: game?.Us?._attributes?.TriCode,
        ImageUrl: game?.Us?.ImageUrl?._text,
        ImageUrlDark: game?.Us?.ImageUrlDark?._text,
        ImageUrlMono: game?.Us?.ImageUrlMono?._text,
      }
      const Opponent: any = {
        FullName: game?.Opponent?._attributes?.FullName,
        Id: game?.Opponent?._attributes?.Id,
        Name: game?.Opponent?._attributes?.Name,
        ShortName: game?.Opponent?._attributes?.ShortName,
        TriCode: game?.Opponent?._attributes?.TriCode,
        ImageUrl: game?.Opponent?.ImageUrl?._text,
        ImageUrlDark: game?.Opponent?.ImageUrlDark?._text,
        ImageUrlMono: game?.Opponent?.ImageUrlMono?._text,
      }
      let State = game?._attributes?.State
      let GameState = game?._attributes?.GameState
      let Result = game?._attributes?.Result
      let HomeScore: number = 0
      let AwayScore: number = 0
      /*
      if (Result != "") {
        let ScoringPlays = [].concat(game?.ScoringPlays?.ScoringPlay).filter(ScoringPlay => ScoringPlay?.Description);
        if (ScoringPlays.length > 0) {
          ScoringPlays.forEach(
            ScoringPlay => {
              let HomeScore = ScoringPlay?._attributes?.IsHome ? JSON.parse(ScoringPlay?._attributes?.IsHome) : false
              if (HomeScore) {
                HomeScore += 1
              } else {
                AwayScore += 1
              }
            }
          )
        } else {
          HomeScore = game?._attributes?.HomeScore;
          AwayScore = game?._attributes?.AwayScore;
        }
      } else {
        HomeScore = -1
        AwayScore = -1
      }
      */
     if (Result != "") {
      HomeScore = parseInt(game?._attributes?.HomeScore);
      AwayScore = parseInt(game?._attributes?.AwayScore);
     } else {
      HomeScore = -1
      AwayScore = -1
     }

      //console.log('broadcasterUrls', game?.BroadcasterUrls);
      return {
        Round: game?._attributes?.Round,
        Venue: game?._attributes?.Venue,
        Timestamp: game?.Date?._attributes?.Timestamp,
        HomeTeam: IsHome ? Team : Opponent,
        AwayTeam: IsHome ? Opponent : Team,
        HomeScore,
        AwayScore,
        Clock: game?._attributes?.Clock,
        State,
        GameState,
        Result,
        IsHome,
        Competition: {
          Name: game?.Competition?._attributes?.Name,
          LogoUrl: game?.Competition?.LogoUrl?._text,
          LogoUrlDark: game?.Competition?.LogoUrlDark?._text,
          LogoUrlSquare: game?.Competition?.LogoUrlSquare?._text,
          LogoUrlSquareDark: game?.Competition?.LogoUrlSquareDark?._text,
          LogoUrlWide: game?.Competition?.LogoUrlWide?._text,
          LogoUrlWideDark: game?.Competition?.LogoUrlWideDark?._text,
          LogoUrlTall: game?.Competition?.LogoUrlTall?._text,
          LogoUrlTallDark: game?.Competition?.LogoUrlTallDark?._text,
        },
        BroadcasterUrls: [].concat(game?.BroadcasterUrls?.Url || []).map((b) => {
          return {
            ImageUrl: b?._text,
            LinkUrl: b?._attributes?.ClickthroughUrl
          };
        }),
        Buttons: [].concat(game?.Buttons?.Button || []).map((b) => {
          return {
            Title: b?.Title?._text,
            ImageUrl: b?.ImageURL?._text,
            LinkUrl: b?.URL?._text,
            WebActionUrl: b?.WebActionURL?._text,
          };
        }),
      }
    })

    return simpleGameData;
  }

  _getMonthArray(gameMonths: any[]): any {
    // let firstMonth = gameMonths && gameMonths[0] || 8; // if there is no game data, we always start from september
    let firstMonth = 8 // we always start from september
    let months = [];
    for (let i = 0; i <= 11; i += 1) {
      let currentMonth = (firstMonth + i) % 12;
      months.push(currentMonth);
    }
    return months;
  }

  _getYear(firstGame, currentMonth) {
    if (!firstGame) return new Date().getFullYear();
    let firstMonth = new Date(firstGame?.Date?._attributes?.Timestamp).getMonth();
    let firstYear = new Date(firstGame?.Date?._attributes?.Timestamp).getFullYear();
    return currentMonth >= firstMonth ? firstYear : firstYear + 1;
  }
}
