<Boundary>
  {#if element?.data?.display === 'fullPage'}
  <div class="yinzcam-full-page-ad-slot" class:ad-visible={loadedAd && !dismissedAd} use:portal={"body"}>
    {#if loadedAd}
    <div class="ad-container">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="close-button" on:click={handleCloseButtonClick}>
        <svg viewBox="0 0 32 32">
          <use xlink:href={`#yc_icon_close`}></use>
        </svg>
      </div>
      <ButtonParticle {...adButtonProps} on:click={handleClick} />
    </div>
    {/if}
  </div>
  {:else}
  <div class="yinzcam-ad-slot" class:display-none={!loadedAd}>
    {#if loadedAd}
    <ButtonParticle {...adButtonProps} on:click={handleClick} />
    {/if}
  </div>
  {/if}
</Boundary>

<style>
  *, *::after, *::before {
    box-sizing: border-box;
  }
  .yinzcam-ad-slot {
    width: 100%;
    text-align: center;
  }

  .yinzcam-ad-slot :global(a) {
    display: inline;
  }

  .yinzcam-full-page-ad-slot {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.9);

    z-index: -100;
    opacity: 0;
    transition: z-index 0s 0.5s, opacity 0.5s linear;
  }

  .yinzcam-full-page-ad-slot.ad-visible {
    opacity: 1;
    z-index: 10000;
    transition: z-index 0s, opacity 1s linear;
  }

  .yinzcam-full-page-ad-slot .ad-container {
    position: relative;
  }

  .yinzcam-full-page-ad-slot .close-button {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 32px;
    height: 32px;
    border: 1px solid var(--theme-primary-accent-color, black);
    border-radius: 50%;
    background-color: var(--theme-primary-background-color, white);
    z-index: 10001;
    cursor: pointer;
  }</style>

<script context="module" lang="ts">var _a, _b, _c, _d;
const adSelectionTracker = {};
function selectAd(slotId, ff, ads) {
    var _a;
    //return _.sample(ads);
    let slotRec = adSelectionTracker[slotId];
    if (!slotRec) {
        slotRec = adSelectionTracker[slotId] = {};
    }
    let tracker = slotRec[ff];
    if (!tracker) {
        tracker = slotRec[ff] = {};
    }
    const currentAds = {};
    for (let ad of ads) {
        if (!(ad === null || ad === void 0 ? void 0 : ad.id) || currentAds[ad.id]) {
            continue;
        }
        if (!(ad.id in tracker)) {
            tracker[ad.id] = false;
        }
        currentAds[ad.id] = ad;
    }
    for (let adId of Object.keys(tracker)) {
        if (!(adId in currentAds)) {
            delete tracker[adId];
        }
    }
    let statuses = Object.entries(tracker);
    if (statuses.length === 0) {
        return null;
    }
    let adId = (_a = statuses.find(([_, v]) => !v)) === null || _a === void 0 ? void 0 : _a[0];
    if (!adId) {
        statuses.forEach(([k, _]) => tracker[k] = false);
        adId = statuses[0][0];
    }
    else {
        tracker[adId] = true;
    }
    // console.log('SELECT AD', slotId, ff, adId, tracker, currentAds);
    return currentAds[adId];
}
</script>

<script lang="ts">var _a, _b, _c, _d;
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import "yinzcam-cards";
import { writable } from "svelte/store";
import ROOT from '../../../../../../inversify.config';
import { getToken } from 'inversify-token';
import { YinzCamAdsServerToken } from 'yinzcam-ads';
import 'yinzcam-api';
import _ from 'lodash';
import { CardsPageContextKey } from 'components/cards/common/context';
import { getContext } from 'svelte';
import { ButtonParticle, ButtonParticleSpec } from 'components/cards/particles/YinzCam';
import { portal } from "svelte-portal/src/Portal.svelte";
import { windowMetrics as wm } from 'js/stores.js';
import { DateTime } from 'luxon';
export let element;
//export let sources: Readable<CardsDataSourceRegistration[]>;
//export const defaultSourceClasses = [ 'YinzCamAdsServerBannerAds' ]
//export let sourceStore: Readable<any>
const pageContext = getContext(CardsPageContextKey);
$: computedFormFactor = (((_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.formFactor) === 'viewport') ? ((((_b = $wm.breakpoint) === null || _b === void 0 ? void 0 : _b.index) === 0) ? 'mobile' : 'tablet') : (_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.formFactor;
$: adsResponseStore =
    (((_d = element === null || element === void 0 ? void 0 : element.data) === null || _d === void 0 ? void 0 : _d.slotId) && computedFormFactor) ?
        getToken(ROOT, YinzCamAdsServerToken).getAds(element.data.slotId, null, computedFormFactor || null).store :
        writable(null);
let loadedAd = false;
let dismissedAd = false;
let adId = null;
let adButtonProps = ButtonParticleSpec.propsWithDefaults({ showText: false, image: { role: 'general', _nolazy: true } });
function loadAds(rsp, ff) {
    var _a, _b, _c, _d;
    const elData = element.data;
    if (rsp.isStatusNot2xx) {
        console.error(`YinzCamAdSlot: unable to load ads data for major=${elData.slotId} ff=${ff} -- status code ${rsp.status}`);
        return;
    }
    const rspData = rsp === null || rsp === void 0 ? void 0 : rsp.data;
    const ads = (((_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.display) === 'fullPage') ? (_b = rspData === null || rspData === void 0 ? void 0 : rspData.fullPageAds) === null || _b === void 0 ? void 0 : _b.ads : rspData === null || rspData === void 0 ? void 0 : rspData.ads;
    if (!_.isArray(ads)) {
        console.error(`YinzCamAdSlot: unable to load ads data for major=${elData.slotId} ff=${ff} -- ads array not found`);
        return;
    }
    // if we're showing a full page ad, check the interval to make sure we are not showing it too often
    if (((_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.display) === 'fullPage') {
        const intervalEndStr = window.localStorage.getItem(`YinzCamAdSlot_FullPage_${elData.slotId}_IntervalEnd`);
        if (intervalEndStr) {
            const intervalEnd = DateTime.fromISO(intervalEndStr);
            if ((intervalEnd === null || intervalEnd === void 0 ? void 0 : intervalEnd.diffNow().toMillis()) > 0) {
                // cannot show yet so just return
                return;
            }
        }
        const interval = (_d = rspData === null || rspData === void 0 ? void 0 : rspData.fullPageAds) === null || _d === void 0 ? void 0 : _d.interval;
        if (interval > 0) {
            const intervalEnd = DateTime.utc().plus(interval);
            window.localStorage.setItem(`YinzCamAdSlot_FullPage_${elData.slotId}_IntervalDurationMs`, interval);
            window.localStorage.setItem(`YinzCamAdSlot_FullPage_${elData.slotId}_IntervalEnd`, intervalEnd.toISO());
        }
    }
    // select the next ad to show
    const ad = selectAd(element.data.slotId, ff, ads);
    if (!ad) {
        console.error(`YinzCamAdSlot: unable to load ads data for major=${elData.slotId} ff=${ff} -- ad not found`);
        return;
    }
    adButtonProps.image.source = ad.image_url;
    adButtonProps.link = ad.clickthrough_url;
    loadedAd = true;
    adId = ad.id;
    pageContext.getAnalytics().sendInteractionEvent('adImpression', adId);
}
$: if ($adsResponseStore && !loadedAd) {
    //console.log('ADS DATA!!!', $adsData);
    loadAds($adsResponseStore, computedFormFactor);
}
function handleClick() {
    dismissedAd = true;
    pageContext.getAnalytics().sendInteractionEvent('adClickthrough', adId);
}
// TODO: Support data sources
//$: { sourceStore = getFirstSourceStore($sources) }
//$: data = sourceStore ? $sourceStore : {}
// $: console.log('YinzCamAdSlot Data', data);
function handleCloseButtonClick() {
    dismissedAd = true;
    pageContext.getAnalytics().sendInteractionEvent('adDismiss', adId);
}
</script>
