import { injectable, Container } from "inversify";
import { injectToken, getToken, Token, TokenContainerModule } from "inversify-token";
import { YinzCamAPIRequestParameters, MergedYinzCamAPIRequestParameterComponent, YinzCamAPIRequestParameterComponentToken } from "yinzcam-api";
import { ManualPassthrough, ReactiveMicrocomponent } from "yinzcam-rma";
import { YinzCamInjectModule } from 'yinzcam-inject';
import { getDeviceId } from './stores';
import _ from "lodash";
import { Readable, Writable, writable } from "svelte/store";
import { f7, f7ready } from 'framework7-svelte';


export const JanusSEOManagerToken = new Token<JanusSEOManager>(Symbol.for("JanusSEOManager"));

export let JanusSEOManagerModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    bindToken(JanusSEOManagerToken).to(JanusSEOManager).inSingletonScope();
  }));
});

export enum JanusSEOPriority {
  Card = 10,
  View = 20,
  Page = 30,
  Default = 40,
}

export interface JanusSEOConfiguration {
  key: string;
  priority: JanusSEOPriority;
  isPopup: boolean;
  title: string;
  description?: string;
  keywords?: string;
  imageUrl?: string;
  linkUrl?: string;
  deleted?: boolean;
}

const DEFAULT_CONFIGURATION: JanusSEOConfiguration = {
  key: 'DEFAULT',
  priority: JanusSEOPriority.Default,
  isPopup: false,
  title: CONFIG.defaultPageTitle,
  description: CONFIG.defaultPageDescription,
  keywords: CONFIG.defaultPageKeywords,
};

@injectable()
export class JanusSEOManager {
  private readonly configStore: Writable<JanusSEOConfiguration>;
  private readonly configRegistry: {
    [k: string]: JanusSEOConfiguration
  };

  public constructor() {
    this.configStore = writable(DEFAULT_CONFIGURATION);
    this.configRegistry = {
      [DEFAULT_CONFIGURATION.key]: DEFAULT_CONFIGURATION,
    };

    f7ready(() => {
      f7.view.main.router.on('routeChange', (newRoute, prevRoute) => {
        // console.log('CLEAR SEO ON ROUTE CHANGE');
        for (const val of Object.values(this.configRegistry)) {
          if (val.key !== DEFAULT_CONFIGURATION.key) {
            val.deleted = true;
          }
        }
        this.determineNewSEOConfiguration();
      });
    });
  }

  public getSEOConfigurationStore(): Readable<JanusSEOConfiguration> {
    return this.configStore;
  }

  public registerSEOConfiguration(configuration: JanusSEOConfiguration) {
    if (configuration.key === DEFAULT_CONFIGURATION.key) {
      throw new Error('SEO: cannot update the default configuration');
    }
    //console.log("REGISTER FOR SEO", configuration.key, configuration);
    if (this.configRegistry[configuration.key]?.deleted) {
      return;
    }
    this.configRegistry[configuration.key] = configuration;
    this.determineNewSEOConfiguration();
  }

  public unregisterSEOConfiguration(key: string) {
    if (key in this.configRegistry) {
      //console.log("UNREGISTER FOR SEO", key);
      delete this.configRegistry[key];
      this.determineNewSEOConfiguration();
    }
  }

  private determineNewSEOConfiguration() {
    let isPopup = false;
    let highestPriorityConfig = DEFAULT_CONFIGURATION;
    for (const config of Object.values(this.configRegistry)) {
      if (config.deleted) {
        continue;
      }
      if (isPopup) {
        if (!config.isPopup) {
          continue;
        }
      } else if (config.isPopup) {
        isPopup = true;
        highestPriorityConfig = config;
        continue;
      }
      if (config.priority <= highestPriorityConfig.priority) {
        highestPriorityConfig = config;
      }
    }
    // console.log('determineNewSEOConfiguration', highestPriorityConfig, this.configRegistry);
    this.configStore.set(highestPriorityConfig);
  }
}
