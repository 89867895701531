<YCPage query={pageQuery} bind:clearPreviousPage={clearPreviousPage}>
  <CardsPage {listName} {pathParams} {pageQuery} {isModal} minHeightWhileContentLoading="100vh" on:firstContentLoaded={() => { clearPreviousPage(); shouldHideBcGame.set(false); }} on:contentLoaded={() => { initialPageContentLoaded.set(true); clearPreviousPage(); }} useSequentialDisplay={true} />
</YCPage>

<style></style>

<script lang='ts'>import { initialPageContentLoaded, shouldHideBcGame } from 'js/routes';
import YCPage from '../components/YCPage.svelte';
import CardsPage from '../components/cards/common/CardsPage.svelte';
export let f7route;
export let isModal = false;
let clearPreviousPage;
const listName = f7route.params.listName;
const pathParams = (f7route.params.path1) ? [f7route.params.path1] : undefined;
const pageQuery = f7route.query;
</script>
