import { generateRootObjectSchema, generateStringSchema, generateArraySchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { FixturesCardCParticle, FixturesCardCParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Swiper Fixtures C", generateRootObjectSchema({
    container: generateAtomContainerSchema({
        defaultValues: { background: { visible: true } },
    }),
    overlayPadding: generateStringSchema("Overlay Padding", "Specify a padding for overlay content"),
    fixturesCardData: generateArraySchema("Fixtures Cards Array", "Fixtures Cards Array",
        FixturesCardCParticleSpec.generateSchema("Fixtures Card", "Fixtures Card")
    ),
    cardsPerColumn: generateStringSchema("Cards Per Column", "Cards Per Column")
}));
