<script lang="ts">import { BaseParticle } from "components/cards/particles";
import { TextParticle } from "../../Core/Text";
import { ImageParticle } from "../../Core/Image";
// props
export let container = undefined;
export let captionBarColor = undefined;
export let captionText = undefined;
export let captionImage = undefined;
// inputs
export let _fillHeight = true;
export let _fillWidth = true;
const ARTICLE_BODY_CAPTION_ICON = CONFIG === null || CONFIG === void 0 ? void 0 : CONFIG.articleBodyCaptionIcon;
// outputs
</script>

<style>
  .captioned-content-container {
    max-width: 100%;
    max-height: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .captioned-content-container.with-fill-height {
    height: 100%;
  }

  .captioned-content-container.with-fill-width {
    width: 100%;
  }

  .caption-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
  }

  .caption-bar {
    width: 0.125rem;
    align-self: stretch;
    background: var(--captioned-particle-caption-bar-color, var(--theme-mode-foreground-color, black));
  }

  .caption-text {
    flex: 1 0 0;
  }

  .caption-icon{
    align-self: center;
  }

  .captioned-content {
    max-width: 100%;
    max-height: 100%;
  }

  .captioned-content.with-fill-height {
    height: 100%;
  }

  .captioned-content.with-fill-width {
    width: 100%;
  }</style>

<BaseParticle _name="CaptionedParticle" _cssVariablePrefix="captioned-particle" {...container} {captionBarColor}>
  <div class="captioned-content-container" class:with-fill-height={_fillHeight} class:with-fill-width={_fillWidth}>
    <div class="captioned-content" class:with-fill-height={_fillHeight} class:with-fill-width={_fillWidth}>
      <slot></slot>
    </div>
    {#if captionText?.text}
    <div class="caption-container">
      {#if ARTICLE_BODY_CAPTION_ICON === "picture"}
        <div class="caption-icon">
          <ImageParticle {...captionImage} _block={true}/>
        </div>      
      {:else}
      <div class="caption-bar"></div>
      {/if}
      <div class="caption-text">
        <TextParticle {...captionText} />
      </div>
    </div>
    {/if}
  </div>
</BaseParticle>
