<script lang="ts">import { afterUpdate } from "svelte";
import { BaseParticle } from "components/cards/particles";
import { autoFitTextAction, getTranslator } from "components/cards/utilities";
import "./spec";
import { getI18nManager } from "components/cards/utilities/context";
// props
export let container = undefined;
export let text = "";
export let translations = [];
export let family = undefined;
export let size = undefined;
export let color = undefined;
export let highlightColor = undefined;
export let weight = undefined;
export let style = undefined;
export let decoration = undefined;
export let subSuper = undefined;
export let transform = undefined;
export let wrap = undefined;
export let showHighlight = false;
export let useAccentColor = false;
export let lineClamp = undefined;
export let lineHeight = undefined;
export let align = undefined;
export let autoFit = false;
export let minWidth = undefined;
export let minHeight = undefined;
// inputs
export let _block = false;
export let _editable = false;
export let _lsmOverride = undefined;
// outputs
export let _computedFamily = undefined;
export let _computedColor = undefined;
export let _computedHighlightColor = undefined;
let span;
$: text = text === "undefined" || !text ? "" : text;
$: xlator = getTranslator(translations, { text });
const lsmStore = getI18nManager().getLineSpacingMultiplierStore();
$: lsm = _lsmOverride || $lsmStore;
afterUpdate(() => {
    requestAnimationFrame(() => {
        if (!span) {
            return;
        }
        const styleDecl = getComputedStyle(span);
        if (_computedFamily !== styleDecl.fontFamily) {
            _computedFamily = styleDecl.fontFamily;
        }
        if (_computedColor !== styleDecl.color) {
            _computedColor = styleDecl.color;
        }
        if (_computedHighlightColor !== styleDecl.backgroundColor) {
            _computedHighlightColor = styleDecl.backgroundColor;
        }
    });
});
</script>

<style>
  span {
    font-family: var(--text-particle-family, var(--theme-font-family, inherit));
    color: var(--text-particle-color, var(--theme-mode-foreground-color, inherit));
    font-weight: var(--text-particle-weight, unset);
    font-style: var(--text-particle-style, unset);
    -webkit-text-decoration: var(--text-particle-decoration, unset);
            text-decoration: var(--text-particle-decoration, unset);
    text-transform: var(--text-particle-transform, unset);
    white-space: var(--text-particle-wrap, unset);
    -moz-font-feature-settings: var(--text-particle-sub-super, unset);
         font-feature-settings: var(--text-particle-sub-super, unset);
    font-variant-position: var(--text-particle-sub-super, unset);
    line-height: calc(var(--text-particle-lsm, 1.0) * var(--text-particle-line-height, unset));
    text-align: var(--text-particle-align, unset);
    min-width: var(--text-particle-min-width, unset);
    min-height: var(--text-particle-min-height, unset);
  }

  span.with-font-size {
    font-size: var(--text-particle-size, unset);
  }

  span.show-highlight {
    background-color: var(--text-particle-highlight-color, var(--theme-mode-highlight-color, gray));
  }

  span.accent-color {
    color: var(--text-particle-color, var(--theme-mode-accent-color, inherit));
  }

  span.use-line-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: var(--text-particle-line-clamp, unset);
    line-clamp: var(--text-particle-line-clamp, unset);;
    -webkit-box-orient: vertical;
  }

  /* https://github.com/rikschennink/fitty?tab=readme-ov-file#performance */
  span.with-auto-fit {
    display: inline-block;
    white-space: nowrap;
    width: 100%;
  }</style>

<BaseParticle _name="TextParticle" _inline={!_block} _cssVariablePrefix="text-particle" {...container} {family} {size} {color} {highlightColor} {weight} {style} {decoration} {transform} {wrap} {lineClamp} {lineHeight} {subSuper} {align} {minWidth} {minHeight} {lsm}>
  <span
    bind:this={span}
    use:autoFitTextAction={autoFit}
    class:with-auto-fit={autoFit}
    class:with-font-size={size && !autoFit}
    class:show-highlight={showHighlight}
    class:accent-color={useAccentColor}
    class:use-line-clamp={lineClamp > 0 && !autoFit}
    class:with-content-editable={_editable}
    contenteditable={_editable}
    on:input
    on:keydown
  >{xlator({ text })}</span>
</BaseParticle>
