import { generateBooleanSchema, generateRootObjectSchema, generateColorSchema, generateStringSchema, generateUrlSchema, generateArraySchema, generateObjectSchema, generateNumberSchema } from "components/cards/common/schema";
import { BaseParticleSpec, GetParticlePropsType, GetParticleSchemaType, generateParticleContainerSchema } from "components/cards/particles/BaseParticleSpec";
import { ImageParticleSpec } from "../../Core/Image/spec";
import { TextParticleSpec } from "../../Core/Text/spec";
import { ButtonParticleSpec } from "../../Core";

export const RadioButtonParticleSpec = new BaseParticleSpec("Radio Button", generateRootObjectSchema({
    container: generateParticleContainerSchema(),
    orientation: generateStringSchema("Label Orientation", "The layout (horizontal or vertical) of the selector buttons.", {
        choices: [ 'row', 'column' ],
        choiceTitles: [ 'Horizontal', 'Vertical' ],
        defaultValue: 'row'
    }),
    alignment: generateStringSchema("Label Alignment", "The alignment of the buttons along the cross-axis of the orientation.", {
        choices: [ 'start', 'center', 'end' ],
        choiceTitles: [ 'Top (H) / Left (V)', 'Center', 'Bottom (H) / Right (V)' ],
        defaultValue: 'center'
    }),
    justification: generateStringSchema("Label Justification", "The alignment of the buttons along the main-axis of the orientation.", {
        choices: [ 'start', 'center', 'end' ],
        choiceTitles: [ 'Left (H) / Top (V)', 'Center', 'Right (H) / Bottom (V)' ],
        defaultValue: 'center'
    }),
    gap: generateStringSchema("Label Gap", "The gap between buttons in the series."),
    params: generateArraySchema("Variables", "The variables to set when a button is clicked.",
        generateStringSchema("Variable", "The variable to set to the value of the option when an option is selected.")
    ),
    buttonDefaults: ButtonParticleSpec.generateSchema("Button Defaults", "The button to be displayed when this option is selected."),
    selectedButtonDefaults: ButtonParticleSpec.generateSchema("Selected Button Defaults", "The button to be displayed when this option is selected."),
    unselectedButtonDefaults: ButtonParticleSpec.generateSchema("Unselected Button Defaults", "The button to be displayed when this option is not selected."),
    defaultValues: generateArraySchema("Default Values", "The default values to set when the value is nil.",
        generateStringSchema("Default Value", "The default value to set.")
    ),
    opts: generateArraySchema("Buttons Options", "Enter the radio buttons options",
        generateObjectSchema("Buttons Option", "Button and Value", {
            buttonDefaults: ButtonParticleSpec.generateSchema("Button Defaults", "The button to be displayed when this option is selected."),
            selectedButton: ButtonParticleSpec.generateSchema("Selected Button", "The button to be displayed when this option is selected."),
            unselectedButton: ButtonParticleSpec.generateSchema("Unselected Button", "The button to be displayed when this option is not selected."),
            values: generateArraySchema("Values", "The values to set when this button is clicked.",
                generateStringSchema("Value", "The value to be set when this option is selected.")
            ),
        })
    ),
    scrollToTopOnSelect: generateBooleanSchema("Scroll to Top on Select", "Scroll the containing view to the top when an option is selected.", { defaultValue: false }),
}));

export const specification = RadioButtonParticleSpec;

export type RadioButtonParticleSpec = GetParticleSchemaType<typeof RadioButtonParticleSpec>;

export type RadioButtonParticleProps = GetParticlePropsType<typeof RadioButtonParticleSpec>;
