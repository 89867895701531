import { Container } from "inversify";
import { TokenContainerModule } from "inversify-token";
import { YinzCamInjectModule } from "yinzcam-inject";
import { NetworkCacheManager, NetworkCacheManagerToken } from "./NetworkCacheManager";

export let NetworkCacheModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    bindToken(NetworkCacheManagerToken).to(NetworkCacheManager).inSingletonScope();
  }));
});
