import { AbstractTemporalCachedReactiveMicrocomponent, ReactiveMicrocomponent } from 'yinzcam-rma';
import { ContentfulCDNResponse } from './ContentfulCDNResponse';
import { ContentfulCDNRequest } from './ContentfulCDNRequest';
import { ContentfulAPIConfig } from './ContentfulAPIConfig';
import { ContentfulClient, ContentfulClientFactory, TTL_SYM } from './ContentfulClient';
import { ContentTypeMetadata, ContentfulCDNMetadata } from './ContentfulCDNMetadata';
import _ from 'lodash';
import { getContentTypeFromDataSourcePath } from './ContentfulUtilities';

export class ContentfulCDNQueryComponent extends AbstractTemporalCachedReactiveMicrocomponent<ContentfulCDNResponse, [ ContentfulCDNMetadata, string, ContentfulCDNRequest ]> {
    private readonly apiConfig: ContentfulAPIConfig;
    private readonly contentfulFactory: ContentfulClientFactory;

    public constructor(name: string, apiConfig: ContentfulAPIConfig, contentfulFactory: ContentfulClientFactory, metadataInput: ReactiveMicrocomponent<ContentfulCDNMetadata>, languageInput: ReactiveMicrocomponent<string>, requestInput: ReactiveMicrocomponent<ContentfulCDNRequest>) {
        super(name, apiConfig, metadataInput, languageInput, requestInput);
        this.apiConfig = apiConfig;
        this.contentfulFactory = contentfulFactory;
    }

    private getContentTypeMetadata(meta: ContentfulCDNMetadata, contentType: string): Partial<ContentTypeMetadata> & { id: string } {
        const ctMeta = meta.contentTypeMetadata[contentType];
        return { ...ctMeta, id: contentType }
    }

    private async queryContentTypeRecursive(meta: ContentfulCDNMetadata, lang: string, req: ContentfulCDNRequest, contentType: string = undefined) {
        if (!contentType) {
            contentType = getContentTypeFromDataSourcePath(req.path);
        }
        const ctMeta = this.getContentTypeMetadata(meta, contentType);
        if (ctMeta.mixed) {
            //console.log('MIXED CONTENT TYPE', ctMeta);
            const queryResults = await Promise.all((ctMeta.mixedContentTypes || []).map((ct) => this.queryContentTypeRecursive(meta, lang, req, ct.fields.slug)));
            //console.log('MIXED CONTENT TYPE DATAS', ...queryResults);
            const mergedResults = _.mergeWith({}, ...queryResults, (objValue, srcValue, key) => {
                if (_.isArray(objValue)) {
                    return objValue.concat(srcValue);
                }
            });
            if (mergedResults.items?.length > 0) {
                mergedResults.items = _.uniqBy(mergedResults.items, (i: any) => i?.sys?.id);
            }
            if (mergedResults.includes?.Asset?.length > 0) {
                mergedResults.includes.Asset = _.uniqBy(mergedResults.includes.Asset, (i: any) => i?.sys?.id);
            }
            if (mergedResults.includes?.Entry?.length > 0) {
                mergedResults.includes.Entry = _.uniqBy(mergedResults.includes.Entry, (i: any) => i?.sys?.id);
            }
            if (mergedResults.items?.length > 0 && req?.params?.order) {
                let field = req.params.order.trim();
                let direction = 1;
                if (field.startsWith('-')) {
                    direction = -1;
                    field = field.substring(1);
                }
                if (field.startsWith('fields.')) {
                    field = field.substring('fields.'.length)
                    const separatorIndex = field.indexOf('&');
                    if (separatorIndex >= 0) {
                        console.warn('Contentful CDN mixed content type ordering only supports one single sort field at the moment.');
                        field = field.substring(0, separatorIndex);
                    }
                    mergedResults.items.sort((a, b) => {
                        const aVal = _.get(a?.fields, field);
                        const bVal = _.get(b?.fields, field);
                        if (aVal === bVal) {
                            return 0;
                        } else if (aVal < bVal) {
                            return -direction;
                        } else {
                            return direction;
                        }
                    });
                } else {
                    console.warn('Contentful CDN mixed content type ordering only supports sorting on a field at the moment.');
                }
            }
            if (mergedResults.items?.length > 0 && mergedResults.limit >= 0 && mergedResults.limit < mergedResults.items.length) {
                mergedResults.items = mergedResults.items.slice(0, mergedResults.limit);
            }
            mergedResults.total = mergedResults.items?.length || 0;
            return mergedResults;
        } else {
            const params = { ...(req.params || {}) };
            if (ctMeta.filterByLanguageTag && lang && lang !== CONFIG.defaultLanguage) {
                const tags = (params['metadata.tags.sys.id[all]'] || '').split(',').filter((tag) => tag);
                tags.push(`YC_LANGUAGE_${lang}`);
                params['metadata.tags.sys.id[all]'] = tags.join(',');
            }
            const query = {
                'limit': 50,
                ...((lang && lang !== CONFIG.defaultLanguage)? { locale: lang } : undefined),
                ...params,
                'content_type': ctMeta.id,
                'include': (_.isNil(ctMeta.depth))? 1 : _.clamp(ctMeta.depth, 0, 10),
            };
            query[TTL_SYM] = ctMeta.timeToLive;
            //console.log('CONTENTFUL EXEC QUERY FOR LANG', lang, query);
            const entries = await this.contentfulFactory.getDefaultContentfulClient(lang).getEntries(query);
            //console.log('CONTENTFUL GOT ENTRIES', entries);
            // Old post-result language filter, not used.
            /*
            if (ctMeta.filterByLanguageTag && lang && lang !== CONFIG.defaultLanguage) {
                const languageTagId = `YC_LANGUAGE_${lang}`;
                entries.items = (entries?.items || []).filter((item) => 
                    !!(item?.metadata?.tags || []).find((tag) => 
                        tag?.sys?.id === languageTagId));
            }
            */
            return entries;
        }
    }

    protected previousLang: string = null;
    protected override async preUpdate(now: number, previousOutput: ContentfulCDNResponse, $meta: ContentfulCDNMetadata, $lang: string, $req: ContentfulCDNRequest): Promise<boolean> {
        const ready = !_.isNil($meta) && !_.isNil($req);
        // if ready, attempt to update TTLs from the metadata
        if (ready) {
            const ctMeta = this.getContentTypeMetadata($meta, getContentTypeFromDataSourcePath($req.path));
            if (ctMeta) {
                //console.log("ContentfulCDNQueryComponent setting TTL", $req.path, ctMeta.timeToLive);
                this.state.ttl = ctMeta.timeToLive;
            }
            if (this.previousLang && this.previousLang !== $lang) {
                this.forceUpdateFromPreUpdate();
            }
            this.previousLang = $lang;
        }
        return ready;
    }

    protected override async execUpdate(now: number, previousOutput: ContentfulCDNResponse, $meta: ContentfulCDNMetadata, $lang: string, $req: ContentfulCDNRequest): Promise<ContentfulCDNResponse> {
        //console.log('CONTENTFUL EXECUPDATE', $lang, $req);
        const ret = this.queryContentTypeRecursive($meta, $lang, $req);
        // console.log('CONTENTFUL EXECUPDATE RET', ret);
        return ret;
    }
}
