<script context="module" lang="ts">import 'json-schema';
export function getDisplayName() {
    return "Ad Slot";
}
export function getElementConfigSpec() {
    return {
        type: "object",
        properties: {
            type: {
                type: "string",
                title: "Ad Source",
                description: "The source of ad data for this ad slot.",
                enum: [
                    "YC",
                    "GPT",
                ],
                options: {
                    enum_titles: [
                        "YinzCam Ads",
                        "Google Ad Manager",
                    ]
                }
            },
            display: {
                type: "string",
                title: "Display Type",
                description: "How this ad should be displayed on screen.",
                enum: [
                    "inline",
                    "fullPage",
                ],
                default: "inline",
                options: {
                    enum_titles: [
                        "Inline/Banner",
                        "Full Page",
                    ]
                }
            },
            slotId: {
                type: "string",
                title: "Slot ID",
                description: "(YC Ads) The slot ID of the ad, configured under Pages in the CMS."
            },
            formFactor: {
                type: "string",
                title: "Form Factor",
                description: "(YC Ads) The form factor of the ad configured in the CMS.",
                enum: [
                    "desktop",
                    "tablet",
                    "mobile"
                ],
                options: {
                    enum_titles: [
                        "Desktop",
                        "Tablet",
                        "Mobile"
                    ]
                }
            },
            adUnitId: {
                type: "string",
                title: "Ad Unit ID",
                description: "(Google Ads) The ad unit ID, can be found in the ad tag (GPT)."
            },
            size: {
                type: "array",
                title: "Ad Slot Size",
                description: "(Google Ads) The width and height of the ad slot.",
                minItems: 2,
                maxItems: 2,
                items: [
                    { type: "number", minimum: 0 },
                    { type: "number", minimum: 0 },
                ]
            }
        },
        required: [
            "type"
        ]
    };
}
</script>

<CardBase {element}>
  <svelte:component this={comp} {...subProps} {sourceStore} />
</CardBase>

<script lang="ts">import "svelte";
import "yinzcam-cards";
import CardBase from "../../../../common/CardBase.svelte";
import BlankAdSlot from "./BlankAdSlot.svelte";
import GooglePublisherTagAdSlot from "./GooglePublisherTagAdSlot.svelte";
import YinzCamAdSlot from "./YinzCamAdSlot.svelte";
import "svelte/store";
import "../../../../common/CardsDataSourceRegistration";
export let element;
export let sources;
export let sourceStore;
let comp = BlankAdSlot;
let subProps = {};
$: data = element === null || element === void 0 ? void 0 : element.data;
$: type = data === null || data === void 0 ? void 0 : data.type;
$: switch (type) {
    case 'GPT':
        comp = GooglePublisherTagAdSlot;
        subProps = { adUnitId: data === null || data === void 0 ? void 0 : data.adUnitId, size: data === null || data === void 0 ? void 0 : data.size };
        break;
    case 'YC':
        comp = YinzCamAdSlot;
        subProps = { element, sources };
        break;
    default:
        comp = BlankAdSlot;
        subProps = {};
}
</script>
