import { generateArraySchema, generateObjectSchema, generateRootObjectSchema, generateStringSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { AudioParticleSpec, CaptionedParticleSpec, ImageParticleSpec, RichTextParticleSpec, TextParticleSpec, VideoParticleSpec, WebEmbedParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Article (Media Item) Body", generateRootObjectSchema({
  container: generateAtomContainerSchema({
    defaultValues: { themeMode: 'primary' }
  }),
  justifyContent: generateStringSchema("Justify Content", "Justify the content of this article body left center or right.", {
    choices: [ 'Left', 'Center', 'Right' ],
    choiceTitles: [ 'Left', 'Center', 'Right' ],
    defaultValue: 'Left'
  }),
  textDefaults: TextParticleSpec.generateSchema("Text Defaults", "Default styling for text sections of articles.", {
    defaultValues: { container: { centerContentHorizontally: false } }
  }),
  imageDefaults: ImageParticleSpec.generateSchema("Image Defaults", "Default styling for image blocks of articles.", {
    defaultValues: { container: { fillWidth: true, centerContentHorizontally: true, fillHeight: false } }
  }),
  videoDefaults: VideoParticleSpec.generateSchema("Video Defaults", "Default styling for video blocks of articles.", {
    defaultValues: { container: { fillWidth: true, centerContentHorizontally: true, fillHeight: false }, controls: true }
  }),
  audioDefaults: AudioParticleSpec.generateSchema("Audio Defaults", "Default styling for audio blocks of articles."),
  captionDefaults: CaptionedParticleSpec.generateSchema("Caption Defaults", "Default styling for captions on blocks within articles."),
  webEmbedDefaults: WebEmbedParticleSpec.generateSchema("Web Embed Defaults", "Default styling for web embed blocks of articles."),
  body: generateArraySchema("Body", "The article body as a series of component props.", 
    generateObjectSchema("Body Item", "An item that is placed in the body of the article.", {}, { additionalProperties: true })
  ),
}));
